import React from 'react';
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography';

import { MISSING } from '../../utils/const.utils';
import { defaultComment } from '../../localized-strings/class-chat';

import { useClassCommentDisplayStyles, authorImageStyleL, authorNameStyle } from './class-comment-display.styles';

import { useClassCommentDisplayRStyles, authorImageStyleR } from './class-comment-display-r.styles';


const DefaultClassComment = ({classComment, attId, author, locale}) => {

    // console.log('Class Dispaly props', classComment, attId, author);

    const classesL = useClassCommentDisplayStyles();
    const classesR = useClassCommentDisplayRStyles();
    const classes = locale === 'he'? classesR : classesL;

    const authorImageStyle = locale === 'he'? authorImageStyleR : authorImageStyleL;

    const currentUser = useSelector(state => {
        // console.log(state)
        return state.user && state.user.currentUser
    })
    // console.log(currentUser);

    const full = `${currentUser.firstName || ''} ${currentUser.lastName || ''}`.trim();
    const userName = (full || currentUser.name || '').trim();

    const message = defaultComment(locale, userName);


    return (
        <div className={classes.classComment}>

            <div>
                <img src={MISSING} alt='user' style={authorImageStyle}/>
            </div>
            
            <div className={classes.content}>

                <div className={classes.authorDate}>
                    <Typography dir='auto' style={authorNameStyle}>UniMastery</Typography>
                </div>

                <div className={classes.body}>
                    <Typography dir='auto' className={classes.text}>{message}</Typography>
                </div>

            </div>
            
        </div>
    );
}

export default DefaultClassComment;
