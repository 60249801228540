import { COMMON } from './common';

export const CLASS_CHAT = {

    en: {
        selectMessage: COMMON['en'].selectClassMessage,
        noMessage: 'No comments ...',
    },

    he: {
        selectMessage: COMMON['he'].selectClassMessage,
        noMessage: 'עדיין אין תגובות ...',
    },

};

export const defaultComment = (locale, userName) => {

    const message = {
        en: `Hi ${userName} ! This is where you can chat about anything related to this workshop.`,
        he: `היי ${userName} ! כאן אתם יכולים לנהל צ'אט על כל נושא שקשור לסדנה.`,
    }

    return message[locale];
};
