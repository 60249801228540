import React from 'react';

// import { df, object } from '../../../utils/object.utils';
import { df } from '../../../utils/object.utils';

import { targetFiles } from '../../../utils/event.utils';



export const FilePick = (

    props => {

        const {content} = props;

        const accept = df('*', props.accept);
        // const icon = df('other', props.icon);
        // const style = object(props.style);

        const onPick = df(() => console.error('FilePick', 'onPick() is missing'), props.onPick);
        const id = df(`photo-upload-${Math.random()}`, props.id);


        return (
            <>
                <input
                    accept={accept}
                    id={id}
                    type="file"
                    multiple={false}
                    style={{display: 'none'}}
                    onChange={ev => {
                        onPick(targetFiles(ev));
                        ev.target.value = '';
                    }}
                />
                {/* <label htmlFor={id} style={{display: 'contents'}}> */}
                <label htmlFor={id} >
                    {/* <Button component="span">
                        name 
                    </Button> */}
                    {content()}
                </label>
                

            </>
        );
    }

);
