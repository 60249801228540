import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFirebase } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

import NewCommentAttachDisplay from './new-comment-attach-display.component';
import AnonymousNewCommentAttachDisplay from './anonymous-new-comment-attach-display.component';

import { getCurrentUserId } from '../../firebase/user.utils';

import { NEW_COMMENT } from '../../localized-strings/new-comment';

import { 
    toggleNewCommentAtttachPageHidden, 
    toggleCommentAnonymous, 
    commentMessage,
    onCommentAttachment
} from '../../redux/new-comment/new-comment.actions';
import { selectCommentAnonymouseState, selectCommentMessage, selectCommentAttachment } from '../../redux/new-comment/new-comment.selector';

import { onCreateComment } from './on-create-new-comment.handler';


const NewCommmentAttach = ({ toggleNewCommentAtttachPageHidden, match, toggleCommentAnonymous,
    commentAnonymouseState, commentMessage, onCommentMessage, commentAttachment, onCommentAttachment, locale }) => {

    const firebase = useFirebase();

    const [comment, setComment] = useState(commentMessage);
    const [file, setFile] = useState(commentAttachment);

    const strings = NEW_COMMENT[locale];

    useEffect(() => {
        onCommentAttachment(file)
    }, [onCommentAttachment, file]);

    const onChange = ev => {
        const { value } = ev.target;
        if(value[0] === ' '){
            setComment('');
        }else{
            setComment(value);
            onCommentMessage(value);
        }
    };

    const onFile = files => {
        const file = files && files[0];
        // console.log(file);
        setFile(file);
    };

    const onReset = () => {
        setFile(null);
    };

    const onSend = () => {

        // console.log('FILE', file);

        const currentUserId = getCurrentUserId(firebase);
        // console.log('Curren User Id', currentUserId);

        const data = {
            file,
            text: comment,
            author: currentUserId,
            class: match.params.classId,
            post: match.params.postId,
            createdTime: firebase.database.ServerValue.TIMESTAMP,
            lastUpdatedUTime: firebase.database.ServerValue.TIMESTAMP,
            anonymous: commentAnonymouseState,
        };

        onCreateComment(firebase, data);

        setComment('');
        setFile(null);
        onCommentMessage('');

        toggleNewCommentAtttachPageHidden();
    };

    const onToggle = () => {
        toggleNewCommentAtttachPageHidden();
        // onCommentMessage('');
    };


    return (

        <div>

            {
                commentAnonymouseState? (
                    <AnonymousNewCommentAttachDisplay
                        onToggle={onToggle}
                        toggleCommentAnonymous={toggleCommentAnonymous}
                        onChange={onChange}
                        comment={comment}
                        file={commentAttachment} // {file}
                        onReset={onReset}
                        onFile={onFile}
                        onSend={onSend}
                        strings={strings}
                        locale={locale}
                    />
                ):(
                    <NewCommentAttachDisplay 
                        onToggle={onToggle}
                        toggleCommentAnonymous={toggleCommentAnonymous}
                        onChange={onChange}
                        comment={comment}
                        file={commentAttachment} // {file}
                        onReset={onReset}
                        onFile={onFile}
                        onSend={onSend}
                        strings={strings}
                        locale={locale}
                    />
                )
            }

        </div>
        
    )
};

const mapStateToProps = createStructuredSelector({
    commentAnonymouseState: selectCommentAnonymouseState,
    commentMessage: selectCommentMessage,
    commentAttachment: selectCommentAttachment,
})

const mapDispatchToProps = dispatch => ({
    toggleNewCommentAtttachPageHidden: () => dispatch(toggleNewCommentAtttachPageHidden()),
    toggleCommentAnonymous: () => dispatch(toggleCommentAnonymous()),
    onCommentMessage: message => dispatch(commentMessage(message)),
    onCommentAttachment: file => dispatch(onCommentAttachment(file)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewCommmentAttach));

