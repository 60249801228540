import { createSelector } from 'reselect';

const selectLeaveClassroomPage = state => state.leaveClassroomPage;

export const selectVisible = createSelector(
    [selectLeaveClassroomPage],
    leaveClassroomPage => leaveClassroomPage.visible
);

export const selectOnClass = createSelector(
    [selectLeaveClassroomPage],
    leaveClassroomPage => leaveClassroomPage.onClass
);
