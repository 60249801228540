import { uploadCommentFile } from '../../crud/upload-comment-file';
import { createCommentAttachment } from '../../crud/create-comment-attachmet';
import { createComment } from '../../crud/create-new-comment-attach';
import { createMessage } from '../../crud/create-new-comment-message';
// import { createPostFirestore } from '../../crud/create-new-question-firestore';
import { trackCoool } from '../../utils/cooladata.utils';
import { trackFirebase } from '../../utils/firebase-analytics.utils';


export const onCreateComment = async(firebase, data) => {

    console.log('onCreateComment() > ()', data);

    const anonymous = (data.anonymous === false)? null : true;

    try {

        if(!data.file) {
            console.log('No file for the comment! Skkipping upload and creating message...');

            const comment = await createMessage(firebase, {
                text: data.text,
                author: data.author,
                post: data.post,
                createdTime: data.createdTime,
                lastUpdatedUTime: data.lastUpdatedUTime,
                anonymous: anonymous,
            });
            
            console.log('onCreateComment() > try{}', 'Comment ctreated: ', comment);

            const eventParametars = {
                user_id: comment.author,
                class_Id: data.class,
                post_id: comment.post,
                comment_id: comment.idKey,
                is_anonymous: data.anonymous,
            };
    
            trackCoool('comment_created', eventParametars);
            trackFirebase('comment_created', eventParametars);

            // const comment = await createPost(firebase, {
            //     // idKey: attachment.post,
            //     author: data.user,
            //     body: data.question,
            //     classId: data.classId,
            //     createdUTime: firebase.database.ServerValue.TIMESTAMP,
            //     lastUpdatedUTime: firebase.database.ServerValue.TIMESTAMP,
            // });
            
            // console.log('onCreateQuestion() > try{}', 'Question ctreated: ', post);

            // const postFirestore = await createPostFirestore({
            //     idKey: post.idKey,
            //     author: data.user,
            //     body: data.question,
            //     classId: data.classId,
            // }); 

            // console.log('onCreateQuestion() Firestore > try{}', 'Question ctreated: ', postFirestore);

            return void 0;
        }

        const updata = await uploadCommentFile(firebase, data.file);
        console.log('onCreateComment() > try{}', 'Comment file uploaded: ', updata);

        // const now = firebase.database.ServerValue.TIMESTAMP;

        const dataForAttachment = {author: data.author, post: data.post, ...updata}
        console.log('Data for creating attachment: ', dataForAttachment);

        const attachment = await createCommentAttachment(firebase, dataForAttachment);
        console.log('onCreateComment() > try{}', 'Comment attachment created: ', attachment);


        const comment = await createComment(firebase, {
            idKey: updata.folder,
            text: data.text,
            author: data.author,
            // class: match.params.classId,
            post: data.post,
            createdTime: firebase.database.ServerValue.TIMESTAMP,
            lastUpdatedUTime: firebase.database.ServerValue.TIMESTAMP,
            attachments: {
                [attachment.idKey]: attachment.fileType,
            },
            significantAttachmentId: attachment.idKey,
            anonymous: anonymous,
        });
        
        console.log('onCreateComment() > try{}', 'Comment ctreated: ', comment);


        const eventParametars = {
            user_id: comment.author,
            class_Id: data.class,
            post_id: comment.post,
            comment_id: comment.idKey,
            attachment_type: attachment.fileType,
            is_anonymous: data.anonymous,
        };

        trackCoool('comment_created', eventParametars);
        trackFirebase('comment_created', eventParametars);

    //     const postFirestore = await createPostFirestore({
    //         idKey: attachment.post,
    //         author: data.user,
    //         body: data.question,
    //         classId: data.classId,
    //         significantAttachmentId: attachment.idKey,
    //     })

        // console.log('onCreateQuestion() Firestore > try{}', 'Question ctreated: ', comment);

    } catch (e) {

        console.error('onCreateComment() > catch{}', e);

        trackCoool('comment_created', {error: e});
        trackFirebase('comment_created', {error: e});

    }

};
