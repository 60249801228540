import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';

import CircularProgress from '@material-ui/core/CircularProgress';

import Lightbox from 'react-lightbox-component';
import 'react-lightbox-component/build/css/index.css';

import { object } from '../../../utils/object.utils'
import { MISSING } from '../../../utils/const.utils';


const PostAttachment = ({attachment, postImageStyles, withLitebox}) => {

    // console.log('PostAttachment props', attachment, postImageStyles);

    // const lightBoxstyle = { ...postImageStyles, marginLeft: 'unset'}
    const lightBoxstyle = postImageStyles

    if (!isLoaded(attachment)) {
        return <div style={{...postImageStyles, display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress/></div>;
    }

    if (isEmpty(attachment)) {
        // return <img src='/image-placeholder.png' alt="missing" style={postImageStyles}/>
        return <img src={MISSING} alt="missing" style={postImageStyles}/>
    }

    if(attachment.fileType === 'IMAGE' && withLitebox){
        const images = object(attachment.images);
        // const image1 = images.originalUrl || images.thumbnailUrl || null;
        // const image = image1? image1 : MISSING;

        const imageOriginal = images.originalUrl || images.thumbnailUrl || MISSING;
        const imageThumb = images.thumbnailUrl || images.originalUrl || MISSING;

        const imageForLitebox = [
            {
                src: imageOriginal,
                // title: 'image title',
                // description: 'image description',
                thumb: imageThumb,
            }
        ]
        
        return (
            <Lightbox
                images={imageForLitebox}
                renderImageFunc={(idx, imageForLitebox, toggleLightbox) => (
                    <img
                        key={idx}
                        src={imageForLitebox.thumb}
                        className={'lightbox-img-thumbnail'}
                        style={lightBoxstyle}
                        onClick={toggleLightbox.bind(null, idx)}
                        alt=''
                    />
                
                )}
                renderDescriptionFunc={() => {
                    return (
                    //   <div style={{color: 'red'}}>
                    //     { image.description + ' (' + image.src + ')' }
                    //   </div>
                        null
                    )
                }}
            />
        )
    }

    if(attachment.fileType === 'IMAGE'){
        const images = object(attachment.images);
        const image1 = images.thumbnailUrl || images.thumbnailUrl || null;
        const image = image1? image1 : MISSING;
        return(
            <img src={image} alt={attachment.idKey} style={postImageStyles}/>
        )
    }

    
    
};

const mapStateToProps = (state, ownProps) => {

    // console.log('Attachment state', state);
    
    const attachments = state.firebase.data.attachments;
    const attachment = attachments? attachments[ownProps.attId] : null;
    
    return {
        attachment: attachment,
    }
};

export default compose(
    connect(mapStateToProps),
    firebaseConnect(ownProps => [
        `attachments/${ownProps.attId}`
    ]),       
)(PostAttachment);
