import {ContentType, FileType} from '../models/domain-core';

export const string = (
    value => null === value || void 0 === value ? '' : String(value)
);

export const empty = (value => null === value || void 0 === value ? true : '' === string(value).trim());

export const attachmentType = (

    // eslint-disable-next-line complexity
    type => {
        // contentType.startsWith(ContentType.Image)
        //     ? FileType.Image
        //     : FileType.Other

        if (type.startsWith(ContentType.Image)){
            return FileType.Image;
        }

        if (type.startsWith(ContentType.Audio)){
            return FileType.Audio;
        }

        if (type.startsWith(ContentType.Video)){
            return FileType.Video;
        }

        if ('application/pdf' === type){
            return FileType.Pdf;
        }

        if ('application/vnd.ms-excel' === type){
            return FileType.Xls;
        }

        if ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' === type){
            return FileType.Xls;
        }

        if ('application/vnd.openxmlformats-officedocument.spreadsheetml.template' === type){
            return FileType.Xls;
        }

        if ('application/vnd.ms-excel.sheet.macroEnabled.12' === type){
            return FileType.Xls;
        }

        if ('application/vnd.ms-excel.template.macroEnabled.12' === type){
            return FileType.Xls;
        }

        if ('application/vnd.ms-excel.addin.macroEnabled.12' === type){
            return FileType.Xls;
        }

        if ('application/vnd.ms-excel.sheet.binary.macroEnabled.12' === type){
            return FileType.Xls;
        }

        if ('application/vnd.ms-powerpoint' === type){
            return FileType.Ppt;
        }

        if ('application/vnd.openxmlformats-officedocument.presentationml.presentation' === type){
            return FileType.Ppt;
        }

        if ('application/vnd.openxmlformats-officedocument.presentationml.template' === type){
            return FileType.Ppt;
        }

        if ('application/vnd.openxmlformats-officedocument.presentationml.slideshow' === type){
            return FileType.Ppt;
        }

        if ('application/vnd.ms-powerpoint.addin.macroEnabled.12' === type){
            return FileType.Ppt;
        }

        if ('application/vnd.ms-powerpoint.presentation.macroEnabled.12' === type){
            return FileType.Ppt;
        }

        if ('application/vnd.ms-powerpoint.template.macroEnabled.12' === type){
            return FileType.Ppt;
        }

        if ('application/vnd.ms-powerpoint.slideshow.macroEnabled.12' === type){
            return FileType.Ppt;
        }

        if ('application/msword' === type){
            return FileType.Doc;
        }

        if ('application/vnd.openxmlformats-officedocument.wordprocessingml.document' === type){
            return FileType.Doc;
        }

        if ('application/vnd.openxmlformats-officedocument.wordprocessingml.template' === type){
            return FileType.Doc;
        }

        if ('application/vnd.ms-word.document.macroEnabled.12' === type){
            return FileType.Doc;
        }

        if ('application/vnd.ms-word.template.macroEnabled.12' === type){
            return FileType.Doc;
        }

        return FileType.General;
    }

);