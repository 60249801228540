import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase' // v3
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './redux/store';
import firebase from './firebase/firebase.utils';


// import firebase from 'firebase/app';
// import 'firebase/auth';
// import "firebase/database";
import './index.css';
import App from './app';
// import * as serviceWorker from './serviceWorker';
// import {createStore, applyMiddleware} from 'redux';
// import rootReducer from './store/reducers/root.reducer';

// import thunk from 'redux-thunk';
// import firebase from 'firebase'
// import {reactReduxFirebase, getFirebase} from 'react-redux-firebase'; v2

// import keys from './config/keys';

// v2

// const firebaseReduxConfig = {
//     userProfile: 'users', // root for user profiles where profiles are stored in database
//     // profileFactory: (userData, profileData) => { // how profiles are stored in database
//     //     const { user } = userData
//     //     return {
//     //       email: user.email
//     //     }
//     // }
//     attachAuthIsReady: true, // attaches auth is ready promise to store
//     // firebaseStateName: 'firebase' // should match the reducer name ('firebase' is default)
//   }

// // const initialState = {};

// v3
// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  // profileParamsToPopulate: [
  //   { child: 'displayName', root: 'users' } // object notation
  // ],
  // profileFactory: (userData, profileData, firebase) => { // how profiles are stored in database
  //   const { user } = userData
  //   console.log('USERRRRR', user, profileData)
  //   return {
  //     // email: user.email,
  //     // phoneNumber: user.phoneNumber,
  //     firstName: user.firstName,
  //   }
  // },
  // autoPopulateProfile: true,
  // setProfilePopulateResults: true,
  // attachAuthIsReady: true,
  firebaseStateName: 'firebase'
  // useFirestoreForProfile: false // Firestore for Profile instead of Realtime DB
  // enableClaims: true // Get custom claims along with the profile
}

// Initialize firebase instance
// firebase.initializeApp(keys)

// Initialize other services on firebase instance
// firebase.firestore() // <- needed if using firestore
// firebase.functions() // <- needed if using httpsCallable


// Create store with reducers and initial state
// const initialState = {}
// const store = createStore(rootReducer, initialState)



// const store = createStore(
//     rootReducer,
//     initialState,
//     // compose(
//         // reactReduxFirebase(keys), // , rrfConfig),
//         applyMiddleware(...[thunk.withExtraArgument(getFirebase)]),
//     // )
// );

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    // createFirestoreInstance // <- needed if using firestore
  }

// store.firebaseAuthIsReady.then(() => {
//     console.log('Auth has loaded')
//     ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
//     serviceWorker.unregister();
// });

// ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          
            <App />
          
        </ReactReduxFirebaseProvider>
        </PersistGate>
    </Provider>, document.getElementById('root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
