import { updateFields } from '../../firebase/firebase.utils'; 
import { Post, PostByClass } from '../../models/domain/post';
import { findUrls } from '../../utils/url.utils';
import { FIREBASESTORAGE } from '../../utils/const.utils';

export const onUpdateQuestion = async(firebase, data, classId, postId) => {


    console.log('onUpdateQuestionBody() > ()', data, postId);

    const substrings = data.body.split('\n') || null;
    // console.log('Substrings', substrings);
    // var firebaseUrl = substrings.find(a => a.includes('https://firebasestorage.googleapis.com/v0/b/classme-integ.appspot.com/o/videos'));
    var firebaseUrl = substrings.find(a => a.includes(FIREBASESTORAGE));
    // console.log('Firebase Url', firebaseUrl);

    const urls = findUrls(data.body);
    // console.log('Urls', urls);
    const otherUrl = urls && urls[0];
    // console.log('Other Url', otherUrl);

    const url = firebaseUrl ? firebaseUrl : otherUrl;
    // console.log('Url', url);

    // const type = isURL(data.question)? 'WEB' : data.type;
    const type = url? 'WEB' : 'QUESTION';  //data.type;
    // console.log('TYPE', type);

    try {

        if(!classId || !postId){
            console.warn('No Ids! Aborting updating ...');
            return void 0;
        }

        if(!data) {
            console.warn('No data! Skkipping updating ...');
            return void 0;
        }

        const now = firebase.database.ServerValue.TIMESTAMP;
        const path = `${postId}`;

        const upData = {
            anonymous: data.anonymous,
            body: data.body,
            dueDateUTime: data.dueDateUTime,
            type: type,
            lastUpdatedUTime: now,
            url: url,
        }

        await updateFields(firebase, Post.path(path), upData);

        await updateFields(firebase, PostByClass.path(classId, postId), upData);



    } catch (e) {

        console.error('onUpdateQuestionBody() > catch{}', e);

    };

};
