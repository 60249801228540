import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


export const StyledMenu = withStyles({
    paper: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #E3E3E352',
        border: '0.5px solid #011E23',
        borderRadius: '12px',
        opacity: 1,
    },
})(props => (
    <Menu
        {...props}
    />
));

export const StyledMenuItem = withStyles({
    root: {
        fontFamily: 'Varela Round, sans-serif, Regular',
        fontSize: '17px',
        letterSpacing: 0,
        color: '#011E23',
        opacity: 1,
        '&:hover': {
            backgroundColor: '#EEFBFC',
        },
        minHeight: 'unset',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '24px',
        paddingRight: '24px',
        lineHeight: 'unset',
    },
})(MenuItem);
