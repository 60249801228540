import React from 'react';

// import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Smileyicon from '../../assets/smileyAnonymous.svg';
import AnonymousIcon from '../../assets/anonymousBlack.svg';
import Sendcon from '../../assets/send.svg';

import NewCommentAttachPage from '../../pages/new-comment-page/new-comment-attach-page.component';
import EmojiPoper from '../common/emoji/emoji-poper.component'

import { useAnonymousNewCommentDisplayStyles } from './anonymous-new-comment-display.styles';
import { useAnonymousNewCommentDisplayStylesR } from './anonymous-new-comment-display-r.styles';


const AnonymousNewCommentDisplay = ({ onChange, comment, onKeyPress, onAnonymous, onSend, onEmojiClick, strings, locale }) => {

    const classesL = useAnonymousNewCommentDisplayStyles();
    const classesR = useAnonymousNewCommentDisplayStylesR();

    const classes = locale === 'he'? classesR : classesL;
    const direction = locale === 'he'? 'rtl' : 'auto';

    return (

        <div className={classes.newComment}>

            <div className={classes.newCommentInput}>

                <NewCommentAttachPage />

                <div className={classes.inputHolder}>

                    <Input
                        id='comment-input'
                        className={classes.input}
                        multiline
                        fullWidth
                        disableUnderline
                        placeholder={strings.inputPlaceholder}
                        inputProps={{
                            'aria-label': 'description',
                            // dir: 'auto',
                            dir: {direction},
                        }}
                        onChange={onChange}
                        value={comment}
                        onKeyPress={onKeyPress}
                    />

                    <div className={classes.options}>
                        <EmojiPoper icon={Smileyicon} onEmojiClick={onEmojiClick} />

                        <div>
                            <IconButton className={classes.iconButtons} id='anonymous' onClick={onAnonymous}>
                                <img className={classes.icon} src={AnonymousIcon} alt='anonymousbutton' />
                            </IconButton>
                        </div>
                    </div>

                </div>

            </div>

            {/* <Button id='send' className={classes.button} onClick={onSend}> */}
            <div id='send' className={classes.button} onClick={onSend}>
                <img className={classes.sendIcon} src={Sendcon} alt='sendbutton' />
            </div>

        </div>

    );
};

export default AnonymousNewCommentDisplay;
