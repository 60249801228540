const DBINST = 'classme-150316';
const STORAGE = 'classme-150316';

export const firebaseConfig = {
    apiKey:            'AIzaSyDPNRbqd-907h5gYxRtyDH7UGuC4bHYLf4',
    authDomain:        `${DBINST}.firebaseapp.com`,
    databaseURL:       `https://${DBINST}.firebaseio.com/`,
    projectId:         DBINST,
    storageBucket:     `${STORAGE}.appspot.com`,
    messagingSenderId: '781694586244',
    appId: "1:781694586244:web:eb1fd33819aec9f49a21d1",
    measurementId: "G-JK1E3Y0BBP",
};
