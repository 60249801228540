import { Comment } from '../models/domain/comment';
import { updateDomain, newIdKey } from '../firebase/firebase.utils';


export const createMessage = (

    async (firebase, data) => {

        // if (!data.class) {
        //     console.error('createMessage()', 'No classId in', data, 'Aborting creation process');
        //     return void 0;
        // };

        if (!data.post) {
            console.warn('createMessage()', 'No postId in', data, 'Aborting creation process');
            return void 0;
        };

        if (!data.text) {
            console.error('createMessage()', 'No Messge ...', data, 'Aborting creation process');
            return void 0;
        };

        if (!data.idKey) {
            console.warn('createMessage()', 'No commentID', data, 'Creating new ...');
        };

        const commentId = data.idKey || newIdKey(firebase);
        const comment = Comment.fromNew({ ...data, idKey: commentId });

        await updateDomain(firebase, Comment, comment);

        return comment;

    }

);