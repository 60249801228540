import { UserActionTypes } from './user.types';

const INITIAL_STATE = {
    currentUser: null,
    currentUserId: '',
    // isSignedIn: false,
}

const userReducer = (state = INITIAL_STATE, action) => {

    if (action.type === UserActionTypes.SET_CURRENT_USER) {
        return {
            ...state,
            isSignedIn: true,
            currentUser: action.payload,
        }
    }

    if (action.type === UserActionTypes.SET_CURRENT_USER_ID) {
        return {
            ...state,
            // isSignedIn: true,
            currentUserId: action.payload,
        }
    }

    // if (action.type === UserActionTypes.LOG_OUT_CURRENT_USER) {
    //     return {
    //         ...state,
    //         // isSignedIn: false,
    //         currentUserId: '',
    //         currentUser: null,
    //     }
    // }

    return state;
}

export default userReducer;
