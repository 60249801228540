import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';

import cursor from '../../assets/cursor.svg';

const url = `url('${cursor}'), auto`;


export const useSignUpStyles = makeStyles({
    
    signUp: {
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center',
        // width: '640px',
        width: '47.690014903%',
        minWidth: '100px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        borderRadius: '12px',
        opacity: 1,
    },

    title: {
        ...FONT,
        // fontSize: '24px',
        fontSize: '1.25vw',
        // marginBottom: '24px',
        marginBottom: '2.2222222222222223vh',
        // marginTop: '24px',
        marginTop: '2.2222222222222223vh',
    },

    divider: {
        width: '100%',
    },

    inputsHolder: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // width: '552px',
        width: '86.25%',
        // paddingLeft: '44px',
        paddingLeft: '2.2916666666666665vw',
        // paddingRight: '44px',
        paddingRight: '2.2916666666666665vw',
        // paddingBottom: '64px',
        paddingBottom: '5.925925925925926vh',
    },

    imageHolder: {
        // marginBottom: '64px',
        marginBottom: '5.925925925925926vh',
        // marginTop: '72px',
        marginTop: '5.925925925925926vh',
        // width: '176px',
        width: '9.166666666666666vw',
        // height: '172px',
        height: '9.166666666666666vw',
        borderRadius: '12px',
        opacity: 1,
    },

    holder: {
        // marginBottom: '64px',
        marginBottom: '5.925925925925926vh',
        // marginTop: '72px',
        marginTop: '5.925925925925926vh',
        // width: '176px',
        // width: '31.884057971%',
        width: '9.166666666666666vw',
        // height: '172px',
        height: '9.166666666666666vw',
        minWidth: '80px',
        minHeight: '80px',
        background: '#A9AAAB 0% 0% no-repeat padding-box',
        borderRadius: '12px',
        opacity: 1,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },

    iBbutton: {
        position: 'relative',
        // top: '122px',
        top: '6.354166666666666vw',
        // left: '126px',
        left: '6.5625vw',
        // width: '40px',
        width: '2.083333333333333vw',
        minWidth: '18px',
        // height: '40px',
        height: '2.083333333333333vw',
        minHeight: '18px',
    },

    imageButton: {
        // width: '40px',
        width: '2.083333333333333vw',
        // height: '40px',
        height: '2.083333333333333vw',
        background: '#FBFBFB 0% 0% no-repeat padding-box',
        borderRadius: '6px',
        opacity: 1,
        // marginBottom: '16px',
        marginBottom: '1.4814814814814814vh',
        // marginRight: '12px',
        marginRight: '0.7291666666666666vw',
        minWidth: 'unset',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#EEFBFC',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
        },
        cursor: url,
    },

    inputHolder: {
        display: 'flex',
        alignItems: 'center',
        // width: '552px',
        width: '100%',
        // height: '56px',
        height: '5.185185185185185vh',
        background: '#FCFCFC 0% 0% no-repeat padding-box',
        border: '1px solid #011E23',
        // borderRadius: '12px',
        borderRadius: '0.625vw',
        opacity: 1,
        // marginBottom: '24px',
        marginBottom: '2.2222222222222223vh',
        
    },

    input: {
        // ...font,
        ...FONT,
        // fontSize: '17px',
        fontSize: '0.8854166666666666vw',
        // lineHeight: '21px',
        // marginRight: '20px',
        marginRight: '1.0416666666666665vw',
        marginLeft: '1.0416666666666665vw',
    },

    button: {
        // marginTop: '40px',
        marginTop: '3.7037037037037037vh',
        // marginBottom: '40px',
        marginBottom: '3.7037037037037037vh',
        width: '100%',
    },

    signUpButton: {
        // ...font,
        ...FONT,
        // fontSize: '20px',
        fontSize: '1.0416666666666665vw',
        color: '#FFFFFF',
        background: '#26C6DA 0% 0% no-repeat padding-box',
        // borderRadius: '12px',
        borderRadius: '0.625vw',
        opacity: 1,
        textTransform: 'unset',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#26C6DA',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
        },
        // width: '552px',
        width: '100%',
        // height: '60px',
        height: '5.555555555555555vh',
    },
    
});


export const imageStyle = {
    objectFit: 'cover',
    // width: '176px',
    // width: '100%',
    width: '9.166666666666666vw',
    minWidth: '80px',
    minHeight: '80px',
    // maxWidth: '100%',
    height: '9.166666666666666vw',
    // height: '172px',
    // height: '100%',
    borderRadius: '12px',

    position: 'absolute',
};

export const iconStyle = {
    width: '1.40625vw',
    height: '1.40625vw',
};
