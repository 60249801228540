import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../../utils/font.utils';

export const useAuthorStyles = makeStyles({

    messages: {
        ...FONT,
        fontSize: '0.8333333333333334vw',
    },
    
    author: {
        display: 'flex',
        alignItems: 'center'
    },

});