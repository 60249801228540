import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';

// import logo from '../../assets/uniMastery.png';
// import { normalizeUnits } from 'moment';

// const url = `url('${logo}')`;


export const useDrawerStyles = makeStyles({

    root: {
        '& .MuiDrawer-paper': {
            width: '56%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },

    iframeContainer: {
        position: 'relative',
        // width: '100%',
        width: '92%',
        paddingBottom: '56.25%',
        height: 0,
    },

    iframe: {
        // width: '80%',
        // // height: '100%',
        // height: 'auto',
        // overflowY: 'auto',

        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
        position: 'absolute',

        // pointerEvents: 'none',
    },

    
    bannerOverlay: {
        position: 'absolute',
        display: 'flex',
        // width: '560px',
        width: '100%',
        // height: '58px',
        // height: '4.6875vw',
        // height: '3.0208333333333335vw',
        // backgroundImage: 'linear-gradient(to right, rgb(0,0,0,0), rgb(0,0,0,0.7) 50%, rgb(0,0,0,1))',
        // backgroundImage: url,
        // backgroundRepeat
        // background: url,
        // backgroundSize: '100%',
        backgroundColor: 'white',
        top: '0px',
        left: 0
    },

    over: {
        position: 'absolute',
        display: 'flex',
        // width: '560px',
        width: '100%',
        height: 'calc(100% - 48px)',
        // backgroundColor: 'red',
        background: 'transparent',
        top: '0px',
        left: 0
    },

    logo: {
        width: '100%',
        border: '2px solid white',
    },

    messages: {
        ...FONT,
        fontSize: '1.6666666666666667vw',
        textAlign: 'center',
    },
    
});
