import { LeaveClassromActionTypes } from './leave-classroom.types';

export const openLeaveClassroomPage = onClass => ({
    type: LeaveClassromActionTypes.OPEN_LEAVE_CLASSROOM_PAGE,
    payload: onClass,
});

export const closeLeaveClassroomPage = () => ({
    type: LeaveClassromActionTypes.CLOSE_LEAVE_CLASSROOM_PAGE,
});
