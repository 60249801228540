import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFirebase } from 'react-redux-firebase';

import NewClassCommentInputDisplay from './new-class-comment-input-display.component';

import { getCurrentUserId } from '../../firebase/user.utils';

import { NEW_COMMENT } from '../../localized-strings/new-comment';

import { classCommentMessage } from  '../../redux/new-class-comment/new-class-comment.actions';
import { selectClassCommentMessage } from '../../redux/new-class-comment/new-class-comment.selector';

import { onCreateClassComment } from '../new-class-comment/on-new-class-comment.handler';

import { newClassCommentInputDisplayStyles } from './new-class-comment-input-display.styles';


const NewClassCommentInput = ({classId, locale, commentMessage, onCommentMessage}) => {

    // console.log('newClassCommentInput props', classId, locale, commentMessage, onCommentMessage);

    const classes = newClassCommentInputDisplayStyles();

    const firebase = useFirebase();

    const strings = NEW_COMMENT[locale];

    const [comment, setComment] = useState(commentMessage);
    // console.log('Comment', comment);

    useEffect(() => {
        onCommentMessage(comment)
    }, [onCommentMessage, comment]);
 
    const onEmojiClick = (ev, emojiObject) => {

        // console.log('Emoji object', emojiObject, ev);
        setComment(comment => (comment + emojiObject.emoji));
        
    };

    const onChange = ev => {

        const { value } = ev.target;

        if(value[0] === ' '){
            setComment('');
        }else{
            setComment(value);
            onCommentMessage(value);
        }
        
    };

    const onSend = () => {

        const currentUserId = getCurrentUserId(firebase);
        // console.log('Curren User Id', currentUserId);

        const data = {
            text: commentMessage, //comment,
            author: currentUserId,
            class: classId,
        };

        onCreateClassComment(firebase, data)

        setComment('');
        onCommentMessage('')

    };

    const onKeyPress = (ev) => {
        
        if (ev.key === "Enter" && ev.shiftKey) {
            // ev.preventDefault();
            setComment(comment => (comment + '\n'));

        }
        if ('Enter' === ev.key && !ev.shiftKey) {
            ev.preventDefault();
            onSend();
        }
    };
 

    return (

        <div className={classes.newCommentInput} >
            <NewClassCommentInputDisplay 
                onChange={onChange}
                comment={commentMessage}
                onKeyPress={onKeyPress}
                onEmojiClick={onEmojiClick}
                strings={strings}
                locale={locale}
            />
        </div>

    )
};

const mapStateToProps = createStructuredSelector({
    commentMessage: selectClassCommentMessage,
})

const mapDispatchToProps = dispatch => ({
    onCommentMessage: classMessage => dispatch(classCommentMessage(classMessage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewClassCommentInput);
