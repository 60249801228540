import React from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

import camera from '../../assets/camera.svg';

import { FilePick } from '../../components/common/files-input/files.pick.component';

import { PROFILE_DISPLAY } from '../../localized-strings/profile-display';

import { useProfileDisplayStyles, imageStyle } from './profile-display.styles';


const ProfileDisplayComponent = ({ locale, oldImageUrl, file, imagePreviewUrl, onFile,
    onChangeFname, fName, onChangeLname, lName, onClose, disabled, onConfirm }) => {

    const classes = useProfileDisplayStyles();

    const strings = PROFILE_DISPLAY[locale];

    const popUpdir = locale === 'he'? 'rtl' : 'ltr';
    const direction = locale === 'he'? 'rtl' : 'auto';


    return (

        <div dir={popUpdir} className={classes.profileDisplay}>

            <Typography dir='auto' className={classes.title}>{strings.title}</Typography>

            <Divider className={classes.divider} />

            <div className={classes.inputsHolder}>

                <div dir='ltr'>
                    {
                        file ?
                            (
                                <div className={classes.imageHolder}>

                                    <img style={imageStyle} src={imagePreviewUrl} alt="img preview" />

                                    <FilePick
                                        accept="image/*"
                                        onPick={onFile}
                                        content={() =>
                                            (
                                                <div className={classes.iBbutton}>
                                                    <Button className={classes.imageButton} component="span">
                                                        <img src={camera} alt='button' />
                                                    </Button>
                                                </div>
                                            )
                                        }
                                    />

                                </div>
                            ) :
                            (
                                <div>
                                    {
                                        oldImageUrl ?
                                            (
                                                <div className={classes.imageHolder}>

                                                    <img style={imageStyle} src={oldImageUrl} alt="img preview" />

                                                    <FilePick
                                                        accept="image/*"
                                                        onPick={onFile}
                                                        content={() =>
                                                            (
                                                                <div className={classes.iBbutton}>
                                                                    <Button className={classes.imageButton} component="span">
                                                                        <img src={camera} alt='button' />
                                                                    </Button>
                                                                </div>
                                                            )
                                                        }
                                                    />

                                                </div>
                                            ) : (
                                                <div className={classes.holder}>
                                                    <FilePick
                                                        accept="image/*"
                                                        onPick={onFile}
                                                        content={() =>
                                                            (
                                                                <Button className={classes.imageButton} component="span">
                                                                    <img src={camera} alt='button' />
                                                                </Button>
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )
                                    }
                                </div>
                            )
                    }
                </div>

                <div className={classes.inputHolder}>
                    <Input
                        className={classes.input}
                        fullWidth
                        disableUnderline
                        placeholder={strings.firstName}
                        inputProps={{
                            'aria-label': 'first-name',
                            dir: {direction},
                            maxLength: 30,
                        }}
                        onChange={onChangeFname}
                        value={fName}
                        required
                    />
                </div>

                <div className={classes.inputHolder}>
                    <Input
                        label='Last Name'
                        className={classes.input}
                        fullWidth
                        disableUnderline
                        placeholder={strings.lastName}
                        inputProps={{
                            'aria-label': 'last-name',
                            dir: {direction},
                            maxLength: 30,
                        }}
                        onChange={onChangeLname}
                        value={lName}
                        required
                        error
                    />
                </div>

                <div className={classes.buttons}>

                    <div>
                        <Button className={classes.cancelButton} onClick={onClose}>
                            {strings.cancelButton}
                        </Button>
                    </div>

                    <div>
                        <Button disabled={disabled} className={classes.saveButton} onClick={onConfirm}>
                            {strings.saveButton}
                        </Button>
                    </div>

                </div>

            </div>

        </div>
    )
};

export default ProfileDisplayComponent;
