import { createSelector } from 'reselect';

const selectPosts = state => state.firebase.data.posts;

export const selectCurrentQuestion = questionUrlParam => 
createSelector(
    [selectPosts],
    // denormalized data
    posts => posts ? posts[questionUrlParam] : null
);
