import { makeStyles } from '@material-ui/core/styles';

import cursor from '../../assets/cursor.svg';

const url = `url('${cursor}'), auto`

const font = {
    fontFamily: 'Varela Round, sans-serif, Regular',
    color: '#011E23',
    letterSpacing: 0,
    opacity: 1,
};

export const useEditClassroomStyles = makeStyles({
    
    editClassroom: {
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center',
        width: '640px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        borderRadius: '12px',
        opacity: 1,
    },

    title: {
        ...font,
        fontSize: '24px',
        lineHeight: '29px',
        marginBottom: '24px',
        marginTop: '24px',
    },

    divider: {
        width: '100%',
    },

    inputsHolder: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '552px',
        paddingLeft: '44px',
        paddingRight: '44px',
        paddingBottom: '64px',
    },

    imageHolder: {
        marginBottom: '64px',
        marginTop: '72px',
        width: '176px',
        height: '172px',
        borderRadius: '12px',
        opacity: 1,
    },

    holder: {
        marginBottom: '64px',
        marginTop: '72px',
        width: '176px',
        height: '172px',
        background: '#A9AAAB 0% 0% no-repeat padding-box',
        borderRadius: '12px',
        opacity: 1,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },

    iBbutton: {
        position: 'relative',
        top: '122px',
        left: '126px',
        width: '40px',
        height: '40px',
    },

    imageButton: {
        width: '40px',
        height: '40px',
        background: '#FBFBFB 0% 0% no-repeat padding-box',
        borderRadius: '6px',
        opacity: 1,
        marginBottom: '16px',
        marginRight: '12px',
        minWidth: 'unset',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#EEFBFC',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
        },
        cursor: url,
    },

    inputHolder: {
        display: 'flex',
        alignItems: 'center',
        width: '552px',
        height: '56px',
        background: '#FCFCFC 0% 0% no-repeat padding-box',
        border: '1px solid #011E23',
        borderRadius: '12px',
        opacity: 1,
        marginBottom: '8px',
    },

    input: {
        ...font,
        fontSize: '17px',
        lineHeight: '21px',
        marginRight: '20px',
        marginLeft: '20px',
    },

    caractersHolder: {
        display: 'flex',
        alignSelf: 'flex-end',
        marginBottom: '64px',
    },

    caracters: {
        ...font,
        fontSize: '12px',
        color: '#A9AAAB',
    },

    buttons: {
        width: 'inherit',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },

    cancelButton: {
        ...font,
        fontSize: '16px',
        color: '#011E23',
        opacity: 1,
        borderRadius: '12px',
        width: '132px',
        textTransform: 'unset',
    },

    saveButton: {
        ...font,
        fontSize: '16px',
        color: '#FFFFFF',
        background: '#26C6DA 0% 0% no-repeat padding-box',
        borderRadius: '12px',
        opacity: 1,
        textTransform: 'unset',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#26C6DA',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        width: '132px'
    },

});

export const imageStyle = {
    objectFit: 'cover',
    width: '176px',
    height: '172px',
    borderRadius: '12px',
    position: 'absolute',
};
