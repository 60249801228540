import React from 'react';

import Button from '@material-ui/core/Button';

import camera from '../../assets/camera.svg';
import { FilePick } from '../common/files-input/files.pick.component';

import { useImageInputAndDisplayStyles, imageStyle } from './image-input-and-display.styles';

const ImageInputAndDisplay = ({ file, imagePreviewUrl, onFile }) => {

    const classes = useImageInputAndDisplayStyles();

    return (

        <div dir='ltr'>

            {
                file?
                    (
                        <div className={classes.imageHolder}>
                            <img style={imageStyle} src={imagePreviewUrl} alt="img preview" />
                        </div>
                    ) :
                    (
                        <div className={classes.holder}>
                            <FilePick
                                accept="image/*"
                                onPick={onFile}
                                content={() =>
                                    (
                                        <Button className={classes.imageButton} component="span">
                                            <img src={camera} alt='button' />
                                        </Button>
                                    )
                                }
                            />
                        </div>
                    )
            }

        </div>
    )
}

export default ImageInputAndDisplay;
