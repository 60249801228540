import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { auth } from '../../firebase/firebase.utils';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import { LOGOUT } from '../../localized-strings/logout';

import { closeLogoutPage } from '../../redux/logout/logout.actions';

import { useLogoutStyles } from './logout.styles';


const Logout = ({ locale, closeLogoutPage }) => {

    // console.log('Logout props', locale);

    const classes = useLogoutStyles();

    const popUpdir = locale === 'he'? 'rtl' : 'ltr';

    const strings = LOGOUT[locale];

    const onLogout = () => {
        auth.signOut()
            .then(()=> {
                console.log('You are successfully logged out')
                closeLogoutPage();
            }).catch(error => {
                console.log('error', error)
            });
    };


    return (
        
        <div dir={popUpdir} className={classes.logout}>

            <Typography dir='auto' className={classes.title}>{strings.title}</Typography>

            <Divider className={classes.divider} />

            <div className={classes.logoutHolder}>

                <Typography dir='auto' className={classes.logoutMessage}>
                    {strings.logoutMessage}
                </Typography>

                <div className={classes.buttons}>
                    <div>
                        <Button className={classes.cancelButton} onClick={closeLogoutPage}>
                            {strings.cancelButton}
                        </Button>
                    </div>

                    <div>
                        <Button className={classes.logoutButton} onClick={onLogout}>
                            {strings.logoutButton}
                        </Button>
                    </div>
                </div>

            </div>
            
        </div>

    )
};

const mapDispatchToProps = dispatch => ({
    closeLogoutPage: () => dispatch(closeLogoutPage())
});

export default withRouter(connect(null,mapDispatchToProps)(Logout));
