import { COMMON } from './common';
import { NEW_QUESTION } from './new-question';

export const EDIT_QUESTION = {

    en: {
        title: 'Edit Session',
        addFileButton: COMMON['en'].addFileButton,
        selectFile: COMMON['en'].selectFile,
        selectLabel: NEW_QUESTION['en'].selectLabel,
        selectQuestion: NEW_QUESTION['en'].selectQuestion,
        selectHomework: NEW_QUESTION['en'].selectHomework,
        selectExam: NEW_QUESTION['en'].selectExam,
        selectTask: NEW_QUESTION['en'].selectTask,
        dateLabel: NEW_QUESTION['en'].dateLabel,
        inputPlaceholder: NEW_QUESTION['en'].inputPlaceholder,
        writeAnonymously: COMMON['en'].writeAnonymously,
        cancelButton: COMMON['en'].cancel,
        saveButton: COMMON['en'].save,
    },

    he: {
        title: 'עריכת מפגש',
        addFileButton: COMMON['he'].addFileButton,
        selectFile: COMMON['he'].selectFile,
        selectLabel: NEW_QUESTION['he'].selectLabel,
        selectQuestion: NEW_QUESTION['he'].selectQuestion,
        selectHomework: NEW_QUESTION['he'].selectHomework,
        selectExam: NEW_QUESTION['he'].selectExam,
        selectTask: NEW_QUESTION['he'].selectTask,
        dateLabel: NEW_QUESTION['he'].dateLabel,
        inputPlaceholder: NEW_QUESTION['he'].inputPlaceholder,
        writeAnonymously: COMMON['he'].writeAnonymously,
        cancelButton: COMMON['he'].cancel,
        saveButton: COMMON['he'].save,
    },

};
