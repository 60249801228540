import React from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';

import { StyledMenu, StyledMenuItem } from '../common/styled-menu/styled-menu.component';

import NoProfile from  '../../assets/profile.svg';

import LogoutPage from '../../pages/logout-page/logout-page.component';
import ProfilePage from '../../pages/profile-page/profile-page.component';

import { object } from '../../utils/object.utils'

import { LOGGED_IN_LINKS } from '../../localized-strings/logged-in-links';

import { openProfilePage } from '../../redux/profile/profile.actions';
import { openLogoutPage } from '../../redux/logout/logout.actions';

import { useLoggedInLinksStyles } from './logged-in-links.styles';
import { useLoggedInLinksStylesR } from './logged-in-links-r.styles';


const LoggedInLinks = ({ locale, profile, openProfilePage, openLogoutPage }) => {

    // console.log('Navbar Logged In Links', locale, profile, currentUserId);

    const classesL = useLoggedInLinksStyles();
    const classesR = useLoggedInLinksStylesR();

    const classes = locale === 'he'? classesR : classesL; 

    const strings = LOGGED_IN_LINKS[locale];

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const full = `${profile.firstName || ''} ${profile.lastName || ''}`.trim();
    const name = (full || profile.name || '').trim();

    const images = object(profile.images);
    const image1 = images.thumbnailUrl || images.originalUrl || null;
    const image2 = profile.imageUrl || null;
    const combineImages = image1? image1 : image2 || null;
    const image = combineImages? combineImages : NoProfile;

    const onMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const onProfile = () => {
        openProfilePage();
        onClose();
    };

    const onLogout = () => {
        openLogoutPage();
        onClose();
    };


    return (
        <div className={classes.loggedInLinks}>
            <div className={classes.grow} />
            <div className={classes.profile}>
                <Typography dir='auto' className={classes.name}>
                    {name}
                </Typography>

                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={onMenu}
                    color="inherit"
                    disableRipple
                    className={classes.iconButton}
                >
                    <Avatar className={classes.avatar} src={image} />
                </IconButton>

                <StyledMenu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={onClose}
                >
                    <StyledMenuItem onClick={onProfile}>{strings.profile}</StyledMenuItem> 
                    <StyledMenuItem onClick={onLogout}>{strings.logout}</StyledMenuItem>
                </StyledMenu>

            </div>
            {/* must be placed somewhare */}
            <ProfilePage />
            <LogoutPage />
        </div>
    )
};


const mapDispatchToProps = dispatch => ({
    openProfilePage: () => dispatch(openProfilePage()),
    openLogoutPage: () => dispatch(openLogoutPage()),
});

export default connect(null, mapDispatchToProps)(LoggedInLinks);
