import { makeStyles } from '@material-ui/core/styles';


export const useMainPageStyles = makeStyles({

    main: {
        height: 'calc(100vh - 60px)',
        display: 'flex',
        // width: '-webkit-fill-available'
        width: '100vw'
        
    },

    classrooms: {
        background: '#D3D4D6',
        // width: '108px',// '5.625%',
        width: '5.625%',
        display: 'flex',
        justifyContent: 'center',
    },

    tabs: {
        // width: '436px',
        width: '22.708333333%',
        display: 'flex',
    },

    details:{
        // width: '-webkit-fill-available', //'62%',
        // width: '100%', //'62%',
        width: '71.666666667%',
        display: 'flex',
        // flexDirection: 'column',
        // height: 'calc(100vh - 60px)',
    },

});