import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';

// import rectangle from '../../assets/rectangle.svg';

// const url = `url('${rectangle}')`

export const useAnonymousNewCommentDisplayStyles = makeStyles({

    newComment: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
    },

    newCommentInput: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        // maxHeight: '123px',
        // maxHeight: '11.38888888888889vh',
        maxHeight: '6.406249999999999vw',

        // minHeight: '47px',
        // minHeight: '4.351851851851852vh',
        minHeight: '2.5vw',
        // height: '2.5vw',

        background: '#011E23 0% 0% no-repeat padding-box',
        border: '0.5px solid #F2F2F2',
        // borderRadius: '12px',
        borderRadius: '0.625vw',
        opacity: '1',
        width: '100%',
    },

    inputHolder: {
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        // maxHeight: '123px',
        // maxHeight: '11.38888888888889vh',
        maxHeight: '6.406249999999999vw',

        // minHeight: '48px',
        // minHeight: '4.351851851851852vh',
        minHeight: '2.5vw',
        // height: '2.5vw',
    },

    input: {
        ...FONT,
        color: '#F2F2F2',
        // fontSize: '17px',
        fontSize: '0.8854166666666666vw',
        // lineHeight: '27px',
        lineHeight: '2.5vh',
        // alignItems: 'flex-start',
        overflowY: 'auto',
        // maxHeight: '123px',
        // maxHeight: '11.38888888888889vh',
        maxHeight: '6.406249999999999vw',

        // minHeight: '43px',
        // minHeight: '4.351851851851852vh',
        minHeight: '2.5vw',
        // height: '2.5vw',

        padding: 'unset',
    },

    options: {
        display: 'flex',
        alignItems: 'ceneter',
        position: 'absolute',
        // right: '200px',
        right: '10.416666666666668vw',
    },

    iconButtons: {
        height: '2.5vw',
        width: '2.5vw',
        padding: '0.625vw',
        fontSize: '1.25vw',
    },

    icon: {
        height: '1.25vw',
        width: '1.25vw',
    },

    button: {
        // marginLeft: '16px',
        marginLeft: '0.8333333333333334vw',
        // marginRight: '16px',
        marginRight: '0.8333333333333334vw',
        // borderRadius: '12px',
        borderRadius: '0.625vw',
        opacity: '1',
        // background: url,
        backgroundColor: '#26C6DA',
        // height: '48px',
        height: '2.5vw',
        // width: '48px',
        width: '2.5vw',
        // minWidth: 'unset',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },

    sendIcon: {
        height: '1.3020833333333335vw',
        width: '1.3020833333333335vw',
    },

});
