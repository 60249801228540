import { makeStyles } from '@material-ui/core/styles';

const font = {
    fontFamily: 'Varela Round, sans-serif, Regular',
    color: '#011E23',
    letterSpacing: 0,
    opacity: 1,
};

export const useLogoutStyles = makeStyles({

    logout: {
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center', 
    },

    title: {
        ...font,
        fontSize: '24px',
        lineHeight: '29px',
        marginBottom: '24px',
        marginTop: '24px',
    },

    divider: {
        width: '100%',
    },

    logoutHolder: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '552px',
        paddingLeft: '44px',
        paddingRight: '44px',
        paddingBottom: '64px',
    },

    logoutMessage: {
        ...font,
        display: 'flex',
        alignSelf: 'flex-start',
        fontSize: '17px',
        lineHeight: '21px',
        marginTop: '48px',
    },

    buttons: {
        display: 'flex',
        alignSelf: 'flex-end',
        marginTop: '64px',
    },

    cancelButton: {
        ...font,
        fontSize: '16px',
        color: '#011E23',
        opacity: 1,
        borderRadius: '12px',
        width: '132px',
        textTransform: 'unset',
    },
    
    logoutButton: {
        ...font,
        fontSize: '16px',
        color: '#011E23', //'#FFFFFF',
        background: '#E8E9EB 0% 0% no-repeat padding-box', //'#DA2626 0% 0% no-repeat padding-box',
        borderRadius: '12px',
        opacity: 1,
        textTransform: 'unset',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#E8E9EB', //'#DA2626',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
        },
        width: '132px'
    },

});
