import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FONT } from '../../utils/font.utils';

export const useClasNavStyles = makeStyles({

    box: {
        // display: 'flex',
        // flexDirection: 'column',
        // height: 'calc(100vh - 132px)',
        height: '100%',
        // padding: '0',
        padding: 'unset',
        backgroundColor: '#E8E9EB',
        // width: 'inherit',
    },

    boxc: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    
    classNavTabs: {
        // height: '-webkit-fill-available',
        // width: '436px'
        width: '100%',
    },

    tabs: {
        backgroundColor: '#E8E9EB',
        width: 'inherit',
        // height: '72px',
        height: '6.666666666666667vh',
    },

    tabPanels: {
        height: 'calc(100% - 6.666666666666667vh)',
    },

    tabPanel: {
        // width: 'inherit',
        height: '100%',
    },

    newCommentInput: {
        display: 'flex',
        justifyContent: 'center',
    },

});

export const StyledTabs = withStyles({
    root:{
        minHeight: 'unset',
        height: '6.666666666666667vh',
    },
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      height: '0.10416666666666667vw',
      '& > div': {
        maxWidth: 96,
        // minHeight: 'unset',
        width: '100%',
        backgroundColor: '#00181C',
      },
    },
  })(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);
  
 export const StyledTab = withStyles(theme => ({
    root: {
        ...FONT,
        // fontSize: '20px',
        fontSize: '1.0416666666666665vw',
        textTransform: 'lowercase',
        whiteSpace: 'nowrap',
        minWidth: 'unset',
        minHeight: 'unset',
        // paddingTop: '24px',
        paddingTop: '2.2222222222222223vh',
        // paddingBottom: '12px',
        paddingBottom: '1.1111111111111112vh',
        // paddingLeft: '6px',
        paddingLeft: '0.3125vw',
        // paddingRight: '6px',
        paddingRight: '0.3125vw',
        // marginRight: theme.spacing(1),
        marginRight: '0.4166666666666667vw',
        '&:focus': {
            opacity: 1,
        },
    },
  }))(props => <Tab disableRipple {...props} />);