import { updateFields } from '../../firebase/firebase.utils'; 
import { Class } from '../../models/domain/class';

export const onUpdateClassTitle = async(firebase, data, classId) => {


    console.log('onUpdateClassTitle() > ()', data);

    try {

        if(!data) {
            console.log('No Title change! Skkipping updating ...');
            return void 0;
        }

        const now = firebase.database.ServerValue.TIMESTAMP;
        const path = `${classId}`;

        await updateFields(firebase, Class.path(path), {
            title: data,
            updatedUTime: now
        });

    } catch (e) {

        console.error('onUpdateClassTitle() > catch{}', e);

    }

};
