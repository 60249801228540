import React from 'react';

// import login from '../../assets/login.svg';
import login from '../../assets/loginu.svg';

import SignUp from '../../components/sign-up/sign-up.component';

import { useSignUpPageStyles } from './sign-up-page.styles';

const SignUpPage = ({ locale }) => {

    const classes = useSignUpPageStyles();

    
    return(
        <div className={classes.signUpPage}>

            <img src={login} alt='login' className={classes.image} />

            <div className={classes.signUp}>
                <SignUp locale={locale} />
            </div>
            
        </div>
    )
    
};

export default SignUpPage;
