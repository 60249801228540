export const LANGUAGE_SELECT = {

    en: {
        english: 'English',
        hebrew: 'Hebrew',
    },

    he: {
        english: 'אנגלית',
        hebrew: 'עברית',
    }

}