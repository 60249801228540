import { COMMON } from './common';

export const LOGGED_IN_LINKS = {

    en: {
        profile: 'Profile',
        logout: COMMON['en'].logout,
    },

    he: {
        profile: 'פרופיל',
        logout:  COMMON['he'].logout,
    },

};
