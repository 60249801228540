import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';
import { functions } from '../../firebase/firebase.utils';
import Lottie from 'react-lottie';
import animationData from './loadingIndicator.json';
import { decode } from 'jsonwebtoken'

import { onJoinClassroom } from '../join-page/on-join-classrom.handler';

const TokenPage = ({ match, history }) => {

    const [title, setTitle] = useState("Loading...");
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);

    const firebase = useFirebase();

    const getClassIdFromProviderId = async (providerId) => {
        if (providerId && providerId !== '0') {
            const getClassId = functions.httpsCallable("classIdFromProviderId");
            const result = await getClassId({ id: providerId });
            console.log("getClassIdFromProviderId > result", result);
            if (result.data && result.data.classId) {
                return result.data.classId;
            }
        }
        return 0;
    }

    const getTokenForJWT = async jwt => {
        try {
            const getToken = functions.httpsCallable("userTokenFromJWT");
            const result = await getToken({ jwt: jwt });
            console.log("getTokenForJWT > result", JSON.stringify(result));
            if (result.data && result.data.token) {
                return result.data.token;
            }
        } catch (err) {
            console.error(err);
            throw new Error("Didn't recieve token");
        }

    }

    const sendErrorMailToAdmins = content => {
        const sendMail = functions.httpsCallable("sendMailToAdmins");
        return sendMail({
            title: "Error from Web App token-page",
            message: content
        });
    }

    // console.log('Token Page props', match, history);

    const token = match.params.token || null;
    let email, providerClassId;
    if (token) {
        const data = decode(token);
        console.log(data);
        email = data.email;
        providerClassId = data.providerClassId;
    }

    // console.log('Token ', token, 'Class Id, ', classId);

    useEffect(() => {

        const checkIfUserSignedIn = email => {
            return new Promise((resolve, reject) => {
                firebase.auth().onAuthStateChanged(user => {
                    if (user && user.email === email) {
                        console.log(user);
                        console.log("state = definitely signed in");
                        resolve(user.uid);
                    }
                    else {
                        console.log("state = definitely signed out");
                        resolve(false);
                    }
                });
            });
        }

        const getClassId = getClassIdFromProviderId(providerClassId);
        checkIfUserSignedIn(email).then(userId => {
            if (userId) {
                console.log("is user id");
                getClassId.then(async classId => {
                    console.log(classId);
                    if (classId === '0' || classId === 0) {
                        setTitle(`Class was not created yet, please try again later`);
                        setShowLoadingAnimation(false);
                        sendErrorMailToAdmins(`class was not created, provider class id: ${providerClassId} user mail ${email}`);
                        await timeout(4000);
                        history.push(`/classes/:classId/posts/:postId`);
                        return void 0;
                    };

                    const data = {
                        user: userId,
                        classId: classId,
                    };
                    await onJoinClassroom(firebase, data);
                    history.push(`/classes/${classId}/posts/:postId`);
                }).catch(err => {
                    console.log(err);
                    history.push(`/classes/:classId/posts/:postId`);
                });
            } else {
                getTokenForJWT(token).then(token => {
                    if (token) {
                        console.log("is token");
                        firebase.auth().signInWithCustomToken(token).then(async (userAuth) => {
                            // console.log('User Auth', userAuth);
                            const classId = await getClassId;
                            console.log('You are successfully logged in!');

                            const currentUser = userAuth.user.uid;
                            // console.log('Current User Id', currentUser);

                            if (classId === '0') {
                                setTitle(`Class was not created yet, please try again later`);
                                setShowLoadingAnimation(false);
                                sendErrorMailToAdmins(`class was not created, provider class id: ${providerClassId} user mail ${email}`);
                                await timeout(4000);
                                history.push(`/classes/:classId/posts/:postId`);
                                return void 0;
                            };

                            const data = {
                                user: currentUser,
                                classId: classId,
                            };
                            await onJoinClassroom(firebase, data);
                            history.push(`/classes/${classId}/posts/:postId`);
                        });
                    } else {
                        console.log("toke is ", token);
                        throw new Error("user was not created");
                    }
                }).catch(e => {
                    const errorCode = e.code;
                    const errorMessage = e.message;
                    console.log('onSignIn > catch{}', errorCode, errorMessage);
                    sendErrorMailToAdmins(e.message + " mail address: " + email);
                    setTitle(`User was not created yet,\n please try again later`);
                    setShowLoadingAnimation(false);
                    //history.push(`/classes/:classId/posts/:postId`);
                });
            }
        }).catch(error => {
            console.log(error);
            history.push(`/classes/:classId/posts/:postId`);
        });

    }, [email, providerClassId, firebase, history, token])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    
    return (

        <div>
            <center>
                <br />
                <br />
                {showLoadingAnimation ?
                    <Lottie options={defaultOptions}
                        height={300}
                        width={300}
                        isStopped={false}
                        isPaused={false} />
                    : ""}
                <br />
                <h1>{title}</h1>
            </center>
        </div>

    );

};

function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}

export default withRouter(TokenPage);
