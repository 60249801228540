import { InviteClassmateActionTypes } from './invite-classmate.types';

export const openInviteClassmatePage = onClass => ({
    type: InviteClassmateActionTypes.OPEN_INVITE_CLASSMATE_PAGE,
    payload: onClass,
});

export const closeInviteClassmatePage = () => ({
    type: InviteClassmateActionTypes.CLOSE_INVITE_CLASSMATE_PAGE,
});
