import { analytics } from '../firebase/firebase.utils'

const createTracker = (

    () => {


        // if (!window.cooladata) {
        //     // TODO: @azder: log the exception
        //     return noop;
        // }

        // if (!window.cooladata.trackEvent) {
        //     // TODO: @azder: log the exception
        //     return noop;
        // }


        // if all good, this will be the function used, instead of noop

        // noinspection JSUnresolvedFunction
        return (
            (eventName, options) => analytics.logEvent(eventName, options)
        );

    }

);


export const trackFirebase = (createTracker());
