import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist'

import { getFirebase } from 'react-redux-firebase'
// import logger from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from './root-reducer';

const middlewares = [thunk.withExtraArgument({ getFirebase })];
// const middlewares = [thunk.withExtraArgument({ getFirebase }), logger];


// if (process.env.NODE_ENV === 'development') {
//     middlewares.push(logger);
// }

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

export const persistor = persistStore(store);

export default { store, persistor };