import React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import { onLeaveClassroom } from './on-leave-classroom.handler';

import { getCurrentUserId } from '../../firebase/user.utils';

import { LEAVE_CLASSROOM } from  '../../localized-strings/leave-classroom';

import { closeLeaveClassroomPage } from '../../redux/leave-classroom/leave-classroom.actions';

import { useLeaveClassroomStyles } from './leave-classroom.styles';
import { useLeaveClassroomStylesR } from './leave-classrom-r.styles';


const LeaveClassroom = ({ classroom, closeLeaveClassroomPage, locale }) => {

    // console.log('Leave Classsrom props', classroom);

    const classesL = useLeaveClassroomStyles();
    const classesR = useLeaveClassroomStylesR();
    const classes = locale === 'he'? classesR : classesL;

    const firebase = useFirebase();

    const currentUserId = getCurrentUserId(firebase);
    // console.log('Curren User Id', currentUserId);

    const strings = LEAVE_CLASSROOM[locale];

    const popUpdir = locale === 'he'? 'rtl' : 'ltr';

    const images = classroom.images;
    const image = images.originalUrl || images.thumbnailUrl || classroom.imageUrl || null;

    const onLeave = () => {
        // console.log('On leave classroom', classroom);

        const data = {classId: classroom.idKey, user: currentUserId};

        onLeaveClassroom(firebase, data);
        
        closeLeaveClassroomPage();
    };
    
    
    return (
        
        <div dir={popUpdir} className={classes.leaveClassroom}>

            <Typography dir='auto' className={classes.title}>{strings.title}</Typography>

            <Divider className={classes.divider} />

            <div className={classes.leaveClassroomHolder}>

                <Typography dir='auto' className={classes.leaveMessage}>
                    {strings.leaveMessage}
                </Typography>

                <div className={classes.classroomHolder}>
                    <div className={classes.classroom}>
                        <img className={classes.classroomImage} src={image} alt='' />
                        <Typography dir='auto' className={classes.classroomTitle}>{classroom.title}</Typography>
                    </div>
                </div>

                <div className={classes.buttons}>
                    <div>
                        <Button className={classes.cancelButton} onClick={closeLeaveClassroomPage}>
                            {strings.cancelButton}
                        </Button>
                    </div>

                    <div>
                        <Button className={classes.leaveButton} onClick={onLeave}>
                            {strings.leaveButton}
                        </Button>
                    </div>
                </div>

            </div>
            
        </div>
        
    )
};

const mapDispatchToProps = dispatch => ({
    closeLeaveClassroomPage: () => dispatch(closeLeaveClassroomPage())
});

export default connect(null, mapDispatchToProps)(LeaveClassroom);
