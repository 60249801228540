import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';

import cursor from '../../assets/cursor.svg';

const url = `url('${cursor}'), auto`;


export const usePostHeaderStyles = makeStyles({

    loading: {
        display: 'flex', 
        // height: '140px',
        height: '100%',
        alignItems: 'center', 
        justifyContent: 'center', 
        flexDirection: 'column',
    },

    loadmessage: {
        ...FONT,
        fontSize: '0.8333333333333334vw',
    },

    postHeader: {
        display: 'flex', 
        alignItems: 'center', 
        // height: '140px',
        height: '100%',
        backgroundColor: '#FCFCFC',
        boxShadow: '0px 3px 6px #E3E3E329',
        opacity: 1,
        // paddingLeft: '60px',
        paddingLeft: '3.125vw',
        // paddingRight: '60px',
        paddingRight: '3.125vw',
    },

    newContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },

    subContent:{
        width: '100%',
        // height: '100%',
    },

    space: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    grid:{
        display: 'grid',
    },

    body: {
        ...FONT,
        // fontSize: '24px',
        fontSize: '1.25vw',
        display: 'inline',
        // marginRight: '40px',
        marginRight:'0.8333333333333334vw',
    },

    bodyLink: {
        ...FONT,
        // color: 'none',
        // fontSize: '24px',
        fontSize: '1.25vw',
        cursor: url,
        marginRight:'0.8333333333333334vw',
        // color: '-webkit-link',
        color: '#2bc4d8',
        textDecoration: 'underline',
    },

    flexAlign: {
        display: 'flex',
        alignItems: 'center', 
        justifyContent: 'center', 
    },

    time: {
        ...FONT,
        // fontSize: '16px',
        fontSize: '0.8333333333333334vw',
        marginRight:'0.8333333333333334vw',
    },

    view: {
        display: 'flex',
        alignItems: 'center'
    },

    anonIcon: {
        // width: '0.8333333333333334vw',
        width: '0.8854166666666666vw',
        // height: '0.8333333333333334vw',
        height: '0.8854166666666666vw',
    },

    anonymous: {
        ...FONT,
        color: '#A9AAAB',
        // fontSize: '20px',
        fontSize: '1.0416666666666665vw',
        // marginLeft: '8px',
        marginLeft: '0.4166666666666667vw',
        // marginRight: '8px',
        marginRight: '0.4166666666666667vw',
    },

    brackets: {
        ...FONT,
        color: '#A9AAAB',
        // fontSize: '18px',
        // fontSize: '0.9375vw',
        fontSize: '1.0416666666666665vw',
    },

    flexBase: {
        display: 'flex',
        alignItems: 'baseline',
        marginRight:'0.8333333333333334vw',
    },

    commentsDisplay: {
        // marginRight: '38px', //'24px',
        marginRight: '1.9791666666666665vw',
        display: 'flex',
        alignItems: 'baseline',
    },

    commnetsIcon: {
        height: '1.5625vw',
        width: '1.5625vw',
    },

    commentsFont: {
        ...FONT,
        // fontSize: '12px',
        fontSize: '0.625vw',
        // marginLeft: '8px',
        marginLeft: '0.4166666666666667vw',
    },

    settings: {
        // marginRight: '-24px',
        // marginRight: '-1.25vw',
        marginRight: '-0.625vw',
        // alignSelf: 'flex-start',
        // marginTop: '20px',
        // marginTop: '1.8518518518518519vh',
    },

    popover: {
        pointerEvents: 'none',
    },

    paper: {
        background: '#011E23',
        boxShadow: '0px 3px 6px #014A4C29',
        padding: '8px', // theme.spacing(1),
        marginLeft: '12px',
    },

    title: {
        ...FONT,
        color: '#FFFFFF',
        fontSize: '12px',
    },
    
});

export const postImageStylesL = {
    // width: '80px',
    width: '4.166666666666666vw',
    // height: '80px',
    height: '4.166666666666666vw',
    objectFit: 'cover', 
    // borderRadius: '12px',
    borderRadius: '0.625vw',
    opacity: 1,
    // marginRight:'16px',
    marginRight:'0.8333333333333334vw',
    marginLeft: 'unset'
};

export const authorImageStyle = {
    display: 'none',
};

export const authorNameStyle ={
    ...FONT,
    // fontSize: '20px',
    fontSize: '1.0416666666666665vw',
}

export const anonymousNameStyle = {
    ...FONT,
    // fontSize: '20px',
    fontSize: '1.0416666666666665vw',
    color: '#A9AAAB',
};

export const linkText = {
    ...FONT,
    // color: 'none',
    color: '#2bc4d8',
    // fontSize: '24px',
    fontSize: '1.25vw',
    cursor: url,
};
