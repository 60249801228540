import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';

import Comment from './comment.component';

import { idsOf, valuesOfObjectSet } from '../../utils/object.utils';

import { COMMENTS } from '../../localized-strings/comments';

import { useCommentsStyles } from './comments.styles';
import { useCommentsStylesR } from './comments-r.styles';


const Comments = ({ comments, locale }) => {

    // console.log('Comments props', comments, locale)

    const [isVisible, setIsVisible] = useState(false);

    const classesL = useCommentsStyles();
    const classesR = useCommentsStylesR();
    const classes = locale === 'he'? classesR : classesL;

    const endRef = useRef();

    const rootMargin = '100px';

    const strings = COMMENTS[locale];

    useEffect(() => {

        let observerRefValue = null;

        //   const observer = new IntersectionObserver(callbackFunction, options);
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            }, { rootMargin }
        );

        if(comments && endRef){
            // console.log(endRef);
            endRef.current.scrollIntoView({ behavior: "auto" });

            observer.observe(endRef.current);
            observerRefValue = endRef.current;
        };

        return () => {
            if (observerRefValue) observer.unobserve(observerRefValue);
        };

    }, [comments, endRef]);

    // console.log(isVisible)

    if (!isLoaded(comments) && isEmpty(comments)) {
        return (
            <div className={classes.noComments}>
                <Typography dir='auto' className={classes.messages}>Loading...</Typography>
                <CircularProgress />
            </div>
        );
    };

    if (isEmpty(comments)) {
        return (
            <div className={classes.noComments}>
                <Typography dir='auto' className={classes.messages}>{strings.noMessage}</Typography>
            </div>
        )
    };
    
    
    return(
        <div className={classes.commentsHolder}>

            <div className={classes.comments}>
                {
                    comments && comments.map(comment => {

                        const attId = idsOf(comment.value.attachments);
                        const type = valuesOfObjectSet(comment.value.attachments);

                        return (
                            
                            <Comment 
                                id={comment.key}
                                key={comment.key}
                                comment={comment}
                                attId={attId}
                                author={comment.value.author}
                                type={type}
                                strings={strings}
                                locale={locale}
                            />
                            
                        )   
                    })
                }
                {/* <div className={classes.button}>
                    <IconButton className={classes.iconButton} onClick={ () => endRef.current.scrollIntoView({ behavior: "auto" })} >
                        <ExpandMoreIcon />
                    </IconButton>
                </div> */}
                <div ref={endRef} />
            </div>

            <div className={classes.button} style={{visibility: isVisible ? 'hidden': 'visible' }}>
                <IconButton className={classes.iconButton} onClick={ () => endRef.current.scrollIntoView({ behavior: "auto" })} >
                    <ExpandMoreIcon className={classes.icon} />
                </IconButton>
            </div>

        </div>
    );
    
};

const mapStateToProps = state => {

    // console.log('Comments state', state);
    
    const comments = state.firebase.ordered.comments;
    const locale = state.localization && state.localization.locale;
    
    return {
        comments: comments,
        locale,
    }
};

export default compose(
    connect(mapStateToProps),
    firebaseConnect(ownProps => [
        { path: 'comments', queryParams: ['orderByChild=post', 'notParsed', `equalTo=${ownProps.postId}`] }
    ]),       
)(Comments);
