import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import DeletedComment from './deleted.comment';
import NormalComment from './normal.comment';

import { selectCurrentUserView, selectCurrentUserCanEditAll } from '../../redux/user/user.selector';


const Comment = ({ comment, attId, type, author, id, view, edit, strings, locale }) => {

    // console.log('Comment props', comment, attId, author, id, view, edit, strings );

    return (
        <div>
            {
                comment.value.isDeleted ? (
                    <DeletedComment comment={comment} attId={attId} author={author} id={id} strings={strings} locale={locale} />
                ):(
                    <NormalComment 
                        comment={comment}
                        attId={attId}
                        author={author}
                        id={id}
                        type={type}
                        view={view}
                        edit={edit}
                        strings={strings}
                        locale={locale}
                    />
                )
            }
        </div>        
    );
};

const mapStateToProps = createStructuredSelector({
    view: selectCurrentUserView,
    edit: selectCurrentUserCanEditAll
});

export default connect(mapStateToProps)(Comment);
