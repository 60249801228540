import { EditCommentActionTypes } from './edit-comment-types';

export const openEditCommentPage = onComment => ({
    type: EditCommentActionTypes.OPEN_EDIT_COMMENT_PAGE,
    payload: onComment,
});

export const closeEditCommentPage = () => ({
    type: EditCommentActionTypes.CLOSE_EDIT_COMMENT_PAGE,
});
