import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';


export const useLanguageSelectStyles = makeStyles({

    button: {
        ...FONT,
        color: '#26C6DA',
        textTransform: 'capitalize',
        fontSize: '18px',
    },

});
