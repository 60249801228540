export const nil = (value => null === value || void 0 === value);

export const object = (o => null === o || void 0 === o ? {} : o);

export const df = ((dfault, value) => nil(value) ? dfault : value);

export const idsOf = (

    obj => Object
        .entries(obj || {})
        .filter(([key, val]) => val)
        .map(([key]) => key)

);

export const allKeys = ( object ) => {

    var keys = Object.keys(object);

    return keys
};


export const valuesOfObjectSet = (

    source => (

        idsOf(source).map(key => source[key])

    )

);