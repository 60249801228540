import { createSelector } from 'reselect';

import { object,  idsOf } from '../../utils/object.utils';

const selecUser = state => state.user;

// export const selectCurrentUserId = createSelector(
//     [selecUser],
//     user => user.currentUserId.userId
// );

export const selectCurrentUser = createSelector(
    [selecUser],
    user => user.currentUser
);

export const selectCurrentUserId = createSelector(
        [selectCurrentUser],
        currentUser => currentUser.idKey
);

export const selectCurrentUserClasses = createSelector(
    [selectCurrentUser],
    currentUser => [...idsOf(currentUser.classesStudent), ...idsOf(currentUser.classesTeacher)]
);

export const selectCurentUserImages = createSelector(
    [selectCurrentUser],
    currentUser => object(currentUser.images) 
);

export const selectThumbImage = createSelector(
    [selectCurentUserImages],
    images => images.thumbnailUrl
);

export const selectOriginalImage = createSelector(
    [selectCurentUserImages],
    images => images.originalUrl
);

export const selectImageUrl = createSelector(
    [selectCurrentUser],
    currentUser => currentUser.imageUrl
);

export const selectCurrentUserPermissions = createSelector(
    [selectCurrentUser],
    currentUser => object(currentUser.permissions)
);

export const selectCurrentUserCanEditAll = createSelector(
    [selectCurrentUserPermissions],
    permissions => permissions.canEditAll
);

export const selectCurrentUserView = createSelector(
    [selectCurrentUserPermissions],
    permissions => permissions.canViewAnonymousName
);
