import { makeStyles } from '@material-ui/core/styles';

import pattern from '../../assets/pattern.svg';

const url = `url('${pattern}')`;

export const useSignUpPageStyles = makeStyles({
    
    signUpPage: {
        margin: 'auto', 
        display: 'flex', 
        height: '100vh',
        background: url,
        backgroundColor: '#f1f5f9',
    },

    image: {
        objectFit: 'cover',
        height: '100%'
    },

    signUp: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    
});
