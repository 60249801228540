import { LogoutActionTypes } from './logout.types';

const INITIAL_STATE = {
    visible: false,
};

const logoutPageReducer = (state = INITIAL_STATE, action) => {

    if(action.type === LogoutActionTypes.OPEN_LOGOUT_PAGE){
        return {
            ...state,
            visible: true,
        }
    }

    if(action.type === LogoutActionTypes.CLOSE_LOGOUT_PAGE){
        return {
            ...state,
            visible: false,
        }
    };

    return state;
};

export default logoutPageReducer;