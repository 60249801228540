import React from 'react';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import Attachment from '../attachment/attachment.component'
import PreviwAttachment from '../previw-attachment/previw-attachment.component';

import { useNewShowImageStyles } from './new-show-image.styles';

const NewShowImage = ({ attId, file, onDelete, imageStyle, caracterStyle, strings }) => {

    // console.log('ShowImage Props', attId, file);

    const classes = useNewShowImageStyles();

    if(attId) {
        return (
            
            <div className={classes.fileContent}>
                <div dir='ltr'>
                    <Attachment dir='ltr' attId={attId}  attImageStyle={imageStyle} />
                </div>
                <div className={classes.iconButtonHolder}>
                    <IconButton size='small' className={classes.iconButton} onClick={onDelete}>
                        <CloseIcon style={{fontSize: '16px'}} />
                    </IconButton>
                </div>
            </div>

        )
    };

    if(file) {
        return (

            <div>

                <div className={classes.fileContent}>
                    <PreviwAttachment file={file}/>
                    <div className={classes.iconButtonHolder}>
                        <IconButton size='small' className={classes.iconButton} onClick={onDelete}>
                            <CloseIcon style={{fontSize: '16px'}} />
                        </IconButton>
                    </div>
                </div>

            </div>
        )
    };

    return (
        <div>
            <Typography className={classes.text} style={caracterStyle}>{strings.selectFile}</Typography>
        </div>
    );

};

export default NewShowImage;
