import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import InviteClassmate from '../../components/invite-classmate/invite-classmate.component';
import CustomModal from '../../components/common/custom-modal/custom-modal.component';

import { selectVisible, selectOnClass } from '../../redux/invite-classmate/invite-classmate.selector';
import { selectLocale } from '../../redux/localization/localization.selector';
import { closeInviteClassmatePage } from '../../redux/invite-classmate/invite-classmate.actions';


const InviteClassmatePage = ({ classroom, visible, closeInviteClassmatePage, locale }) => {

    // console.log('Invite Classmate Page Props', classroom, visible, closeInviteClassmatePage, locale);

    return (
        <div>
            <CustomModal 
                open={visible}
                onClose={closeInviteClassmatePage}
                content={<InviteClassmate classroom={classroom} locale={locale} />}
            />
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    visible: selectVisible,
    classroom: selectOnClass,
    locale: selectLocale,
});

const mapDispatchToProps = dispatch => ({
    closeInviteClassmatePage: () => dispatch(closeInviteClassmatePage())
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteClassmatePage);
