import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/he';

import { auth, createUserProfileDocument } from './firebase/firebase.utils';
import { setCurrentUser } from './redux/user/user.actions';
import { selectCurrentUser } from './redux/user/user.selector';
import { selectLocale } from './redux/localization/localization.selector';

// import Navbar from './components/navbar/navbar.component';
// import WelcomePage from './pages/welcome-page/welcome-page.component';

import SignUpPage from './pages/sign-up-page/sign-up-page.component';

import SignInPage from './pages/sign-in-page/sign-in-page.component';
import MainPage from './pages/main-page/main-page.component';

import JoinPage from './pages/join-page/join-page.component';

import TokenPage from './pages/token/token-page.component';


class App extends Component {

    unsubscribeFromAuth = null;


    componentDidMount(){

        // console.log('App Props', this.props);
        const {setCurrentUser} = this.props;

        this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {

            // console.log('userAuth', userAuth);
            
            if(userAuth) {
                const userRef = await createUserProfileDocument(userAuth);

                userRef.on('value', snapshot => {

                    // redux action
                    setCurrentUser({
                       ...snapshot.val(),
                    });

                });
            }else{
                setCurrentUser(userAuth);
            };
            
            // setCurrentUser(userAuth);
            // setCurrentUser(null);

        });
    
    };

    componentWillUnmount(){
        this.unsubscribeFromAuth()
    };


    render() {
        // console.log('App Props', this.props);

        const { locale } = this.props;
        moment.locale(locale);

        const direction = (locale === 'he')? 'rtl' : 'ltr'

        return (
            <BrowserRouter>
                <div dir={direction} className="App">
                    <Switch>

                        <Route exact path='/' 
                            render={() => this.props.currentUser && this.props.currentUser.idKey !== undefined?  
                                <Redirect to='/classes/:classId/posts/:postId' />
                                :
                                <div>
                                    {
                                    this.props.currentUser && this.props.currentUser.idKey === undefined?
                                        <SignUpPage locale={locale} />
                                        :
                                        <SignInPage />
                                    }
                                </div>

                            }
                        />

                        <Route path='/classes/:classId/posts/:postId'
                            render={() => this.props.currentUser? 
                                <MainPage currentUser={this.props.currentUser} locale={locale} />
                                :
                                <Redirect to='/' />
                            }
                        />

                        <Route path='/join/:classId'
                            render={() => this.props.currentUser? 
                                <JoinPage currentUser={this.props.currentUser} locale={locale} />
                                :
                                <Redirect to='/' />
                            }
                        />

                        <Route path='/signin-token/:token'>
                            <TokenPage />
                        </Route>

                    </Switch>
                </div>
            </BrowserRouter>
        )
    }
};


const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    locale: selectLocale,
});


const mapDispatchToProps = dispatch => ({
    setCurrentUser: user => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
