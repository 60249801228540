import React from 'react';

import { idsOf } from '../../utils/object.utils';

import Typography from '@material-ui/core/Typography';
import Author from '../common/author/author.component';
import Attachment from '../common/attachment/attachment.component';

import Share from '../../assets/share.svg';
import anonymousImage from '../../assets/anonymousBlackSmall.svg';

import Linkify from 'react-linkify';

import { 
    usereplyStyles,
    authorImageStyle,
    authorNameStyle,
    anonymousNameStyle,
    attImageStyle,
    linkText,
    anonymousIcon 
} from './reply.styles';

import { usereplyStylesR } from './reply-r.styles';


const Reply = ({ reply, view, locale }) => {

    // console.log('Reply', reply);

    const classesL = usereplyStyles();
    const classesR = usereplyStylesR();

    const classes = locale === 'he'? classesR : classesL;

    const componentDecorator = (href, text, key) => (
        <a href={href} key={key} target='_blank' rel='noopener noreferrer' style={linkText}>
          {text}
        </a>
    );

    if (reply === null){
        return null;
    }

    const attId = idsOf(reply.attachments);
    const anonymous = reply.anonymous || null;

    return(
        <div className={classes.reply}>

            <img className={classes.share} src={Share} alt='shareicon' />

            <div className={classes.replyContent}>
                <div>
                    {
                        anonymous? (
                            <div className={classes.author}>
                                <div>
                                    {
                                        view?(
                                            <div style={{display: 'flex'}}>
                                                <div className={classes.anonymousImageHolder}>
                                                    {/* <img src={anonymousImage} width='10' alt='' /> */}
                                                    <img src={anonymousImage} style={anonymousIcon} alt='' />
                                                </div>
                                                <Typography className={classes.anonymous}>Anonymous</Typography>
                                                {/* <Typography className={classes.anonymous} style={{marginLeft: '4px'}}>(</Typography> */}
                                                <Typography className={classes.anonymous} style={{marginLeft: '0.20833333333333334vw'}}>
                                                    (
                                                </Typography>
                                                <Author 
                                                    author={reply.author}
                                                    authorImageStyle={authorImageStyle}
                                                    authorNameStyle={anonymousNameStyle}
                                                />
                                                <Typography className={classes.anonymous}>)</Typography>
                                            </div>
                                        ):(
                                            <div style={{display: 'flex'}}>
                                                <div className={classes.anonymousImageHolder}>
                                                    {/* <img src={anonymousImage} width='10' alt='' /> */}
                                                    <img src={anonymousImage} style={anonymousIcon} alt='' />
                                                </div>
                                                <Typography className={classes.anonymous}>Anonymous</Typography>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        ):(
                            <div className={classes.author}>
                                <Author 
                                    author={reply.author}
                                    authorImageStyle={authorImageStyle}
                                    authorNameStyle={authorNameStyle}
                                />
                            </div>
                        )
                    }
                </div>
                {
                    (attId[0] && reply.text)? (
                        // <div style={{marginBottom: '20px'}}>
                        <div dir='ltr' style={{marginBottom: '1.8518518518518519vh'}}>
                            <Attachment attId={attId[0]}  attImageStyle={attImageStyle} withLitebox={true} />
                        </div>
                    ):(
                        <div dir='ltr'>
                        <Attachment dir='ltr' attId={attId[0]}  attImageStyle={attImageStyle} withLitebox={true} />
                        </div>
                    )
                }
                <Linkify componentDecorator={componentDecorator}>
                    <Typography dir='auto' className={classes.replyText}>{reply.text}</Typography>
                </Linkify>
            </div>

        </div>
    )
}

export default Reply;
