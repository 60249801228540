import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toggleEditQuestionPageHidden } from '../../redux/edit-question/edit-question.actions';
import { selectCurrentQuestion } from '../../redux/edit-question/question.selector';
import { selectEditQuestionPageState } from '../../redux/edit-question/edit-question.selector';
import { selectLocale } from '../../redux/localization/localization.selector';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import EditQuestion from '../../components/edit-question/edit-question.comnponent';
import CustomModal from '../../components/common/custom-modal/custom-modal.component';

import { useEditQuestionPageStyles } from './edit-question-page.styles';


const EditQuestionPage = ({ question, editQuestionHidden, toggleEditQuestionPageHidden, locale }) => {

    // console.log('Edit Question Page props', question, editQuestionHidden, locale);
    const classes = useEditQuestionPageStyles();

    return (
        <div>
            <IconButton className={classes.button} onClick={toggleEditQuestionPageHidden}>
                <MoreVertIcon className={classes.icon} />
            </IconButton>
            <CustomModal open={editQuestionHidden} onClose={toggleEditQuestionPageHidden} content={<EditQuestion question={question} locale={locale}/>}/>
        </div>
    )
}


const mapStateToProps = (state, ownProps) => ({
    question: selectCurrentQuestion(ownProps.match.params.postId)(state),
    editQuestionHidden: selectEditQuestionPageState(state),
    locale: selectLocale(state)
});

const mapDispatchToProps = dispatch => ({
    toggleEditQuestionPageHidden: () => dispatch(toggleEditQuestionPageHidden())
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditQuestionPage));
