import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFirebase } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

import NewClassCommentAttachDisplay from './new-class-comment-attach-display.component';

import { getCurrentUserId } from '../../firebase/user.utils';

import { NEW_COMMENT } from '../../localized-strings/new-comment';

import { toggleNewClassCommentPageHidden, classCommentMessage } from '../../redux/new-class-comment/new-class-comment.actions';
import { selectClassCommentMessage } from '../../redux/new-class-comment/new-class-comment.selector';

import { onCreateClassComment } from './on-new-class-comment.handler';


const NewClassCommentAttach = ({ toggleNewClassCommentPageHidden, match, commentMessage, onCommentMessage, locale }) => {


    const firebase = useFirebase();

    const [comment, setComment] = useState(commentMessage);
    const [file, setFile] = useState(null);

    const strings = NEW_COMMENT[locale];

    const onChange = ev => {
        const { value } = ev.target;
        if(value[0] === ' '){
            setComment('');
        }else{
            setComment(value);
            onCommentMessage(value);
        }
    };

    const onFile = files => {
        const file = files && files[0];
        // console.log(file);
        setFile(file);
    };

    const onReset = () => {
        setFile(null);
    };

    const onSend = () => {

        // console.log('FILE', file);

        const currentUserId = getCurrentUserId(firebase);
        // console.log('Curren User Id', currentUserId);

        const data = {
            file,
            text: comment,
            author: currentUserId,
            class: match.params.classId,
        };

        onCreateClassComment(firebase, data);

        setComment('');
        setFile(null);
        onCommentMessage('');

        toggleNewClassCommentPageHidden();
    };


    return (
        <div>

           <NewClassCommentAttachDisplay
                strings={strings}
                file={file}
                onReset={onReset}
                onFile={onFile}
                onChange={onChange}
                comment={comment}
                onToggle={toggleNewClassCommentPageHidden}
                onSend={onSend}
                locale={locale}
           /> 
            
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    commentMessage: selectClassCommentMessage,
});

const mapDispatchToProps = dispatch => ({
    toggleNewClassCommentPageHidden: () => dispatch(toggleNewClassCommentPageHidden()),
    onCommentMessage: message => dispatch(classCommentMessage(message)),
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(NewClassCommentAttach));
