import { COMMON } from './common';

export const POSTS = {

    en: {
        selectMessage: COMMON['en'].selectClassMessage,
        noMessage: 'No Sessions in this Channel',
        selectQuestionMessage: 'Please select Question',
    },

    he: {
        selectMessage: COMMON['he'].selectClassMessage,
        noMessage: 'התחל את הערוץ על ידי יצירת מפגש חדש',
        selectQuestionMessage: 'אנא בחר מפגש',
    },

};
