import { makeStyles } from '@material-ui/core/styles';

const font = {
    fontFamily: 'Varela Round, sans-serif, Regular',
    color: '#011E23',
    letterSpacing: 0,
    opacity: 1,
}

export const useCommentForDeleteStylesR = makeStyles({

    comment: {
        display: 'flex',
        marginTop: '26px',
        marginBottom: '26px', 
        alignItems: 'flex-start',
    },

    anonymousImageHolder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '48px', 
        height: '48px',
        // marginRight: '12px',
        marginLeft: '12px',
        borderRadius: '12px',
        background: '#011E23 0% 0% no-repeat padding-box',
        opacity: 1,
    },

    content: {
        width: '100%',
    },

    flexSpaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },

    authorDate: {
        display: 'flex',
        alignItems: 'center',
    },

    anonymous: {
        ...font,
        fontSize: '16px',
        color: '#26C6DA',
    },

    date: {
        ...font,
        fontSize: '16px',
        // marginLeft: '16px'
        marginRight: '16px',
    },

    editedDate: {
        ...font,
        fontSize: '14px',
        color: '#046D7F',
        // marginLeft: '8px',
        marginRight: '8px',
    },

    text: {
        ...font,
        fontSize: '17px',
        lineHeight: '27px',
    },

});

export const authorImageStyleR = {
    width: '48px', 
    height: '48px', 
    objectFit: 'cover',
    // marginRight: '12px',
    marginLeft: '12px',
    borderRadius: '12px',
    opacity: 1,
};
