import React from 'react';

import ClassDispaly from './class-display.component';

import { trackCoool } from '../../utils/cooladata.utils';
import { trackFirebase } from '../../utils/firebase-analytics.utils';


const ClassP = ({ locale, classroom, edit, id }) => {

    // console.log('ClassP props', classroom, edit, id, locale);

    const eventParametars = {
        user_id: id,
        class_Id: classroom.idKey,
        organization_id: classroom.organization,
        class_title: classroom.title
    }

    const onClick = () => {
        trackCoool('class_opened', eventParametars);
        trackFirebase('class_opened', eventParametars);
    }

    return (
        <div onClick={onClick}>
            <ClassDispaly activeClass={classroom} edit={edit} locale={locale} />
        </div>
    );
}

export default React.memo(ClassP);
