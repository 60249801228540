import React, { useState } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import ReplyDisplay from './reply-display.component';
import AnonymousReplyDisplay from './anonymous-reply-display.component';

import { object } from '../../utils/object.utils';
import { getCurrentUserId } from '../../firebase/user.utils';

import { REPLY_TO_COMMENT } from  '../../localized-strings/reply-to-comment';

import { closeReplyToCommentPage } from '../../redux/reply-to-comment/reply-to-comment.actions';
import { selectCurrentUserView } from '../../redux/user/user.selector';

import { onCreateReplyToComment } from './on-create-reply-ro-comment-handler';


const ReplyToComment = ({  match, comment, closeReplyToCommentPage, view, locale }) => {

    // console.log('Reply to comment', comment);

    const firebase = useFirebase()

    const [anonymous, setAnonymous] = useState(false);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');

    const rComment = object(comment.value);

    const currentUserId = getCurrentUserId(firebase);
    // console.log('Curren User Id', currentUserId);

    const strings = REPLY_TO_COMMENT[locale];

    const onFile = files => {

        const file = files && files[0];
        setFile(file);

    };

    const onDelete = () => { setFile('') };

    const onChange = ev => {
        const { value } = ev.target;
        if(value[0] === ' '){
            setMessage('');
        }else{
            setMessage(value);
        }
    };

    const toggleReplyAnonymous = () => { setAnonymous(!anonymous) };

    const onClose = () => {
        closeReplyToCommentPage()
    };

    const onSend = () => {
        
        const writeRAnon = (rComment.anonymous === true)? true : null;

        const data = {
            file,
            anonymous,
            text: message,
            author: currentUserId,
            post: match.params.postId,
            class: match.params.classId,
            createdTime: firebase.database.ServerValue.TIMESTAMP,
            lastUpdatedUTime: firebase.database.ServerValue.TIMESTAMP,
            replyTo: {
                anonymous: writeRAnon,
                attachments: rComment.attachments || null,
                author: rComment.author,
                idKey: rComment.idKey,
                text: rComment.text
            }
        };

        // console.log('Data for Re', data);

        onCreateReplyToComment(firebase, data);

        setMessage('');

        setFile(null);

        closeReplyToCommentPage();
    };

    const disabled = !message && !file? true : false;

    
    return (

        <div>

            {
                anonymous? (
                    <AnonymousReplyDisplay
                        file={file}
                        onFile={onFile}
                        onDelete={onDelete}
                        message={message}
                        onChange={onChange}
                        toggleReplyAnonymous={toggleReplyAnonymous}
                        onClose={onClose}
                        rComment={rComment}
                        view={view}
                        onSend={onSend}
                        disabled={disabled}
                        strings={strings}
                        locale={locale}
                    />
                ):(
                    <ReplyDisplay
                        file={file}
                        onFile={onFile}
                        onDelete={onDelete}
                        message={message}
                        onChange={onChange}
                        toggleReplyAnonymous={toggleReplyAnonymous}
                        onClose={onClose}
                        rComment={rComment}
                        view={view}
                        onSend={onSend}
                        disabled={disabled}
                        strings={strings}
                        locale={locale}
                    />
                )
            }

        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    view: selectCurrentUserView
});

const mapDispatchToProps = dispatch => ({
    closeReplyToCommentPage: () => dispatch(closeReplyToCommentPage())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReplyToComment));
