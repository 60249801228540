import React from 'react';

import Typography from '@material-ui/core/Typography';
import anonymousImage from '../../assets/anonymousSmall.svg';

// import Linkify from 'react-linkify';

import PostAttachment from '../common/post-attachment/post-attachment.component';
import Author from '../common/author/author.component';

// import { findUrls } from '../../utils/url.utils';

import { COMMON } from '../../localized-strings/common';

import { usePostStyles, postImageStyles, authorImageStyle, authorNameStyle, anonymousNameStyle} from './post.styles';


const PostDisplay = ({ onClick, attId, body, anonymous, view, author, locale, type, url }) => {

    // console.log('Post Display props', attId, body, anonymous, view, author,  url);

    const classes = usePostStyles();

    const strings = COMMON[locale];

    // const componentDecorator = (href, text, key) => (
    //     // <a href={href} key={key} target='_blank' rel='noopener noreferrer' style={linkText}>
    //     //     {text}
    //     // </a>
    //     <>{strings.urlString}</>
    // );


    // const urls = findUrls(body);
    // console.log('Urls', urls);
    // const urlFromBody = urls && urls[0];
    // console.log('Url', url);

    // const combinedUrl = url? url : urlFromBody;

    // const titleFromWeb = (type === 'WEB')? combinedUrl : null

    // const titleForPost = (type === 'WEB')? combinedUrl : body;
    const titleForPost = (type === 'WEB')? strings.urlString : body ;
    // const titleForPost = titleFromWeb? titleFromWeb : body;
    // console.log('title', titleForPost)

    return (
        <div className={classes.post} onClick={onClick} >

            <PostAttachment attId={attId} postImageStyles={postImageStyles} />
    
            <div className={classes.postText} >
                {/* <Linkify componentDecorator={componentDecorator}> */}
                    {/* <Typography dir='auto' noWrap={true} className={classes.postBody}>{body}</Typography> */}
                    <Typography dir='auto' noWrap={true} className={classes.postBody}>{titleForPost}</Typography>
                {/* </Linkify> */}
                <div>
                    {
                        anonymous ? (
                            <div>
                                {
                                    // the autor who make anonymous post
                                    // (view || (current === author))?(
                                    view ? (
                                        <div>
                                            <div className={classes.view}>
                                                <img src={anonymousImage} alt='' />
                                                <Typography className={classes.anonymous}>Anonymous</Typography>
                                            </div>
                                            <div className={classes.view}>
                                                <Typography className={classes.brackets}>(</Typography>
                                                <Author
                                                    author={author}
                                                    authorImageStyle={authorImageStyle}
                                                    authorNameStyle={anonymousNameStyle}
                                                />
                                                <Typography className={classes.brackets}>)</Typography>
                                            </div>
                                        </div>
                                    ) : (
                                            <div className={classes.view}>
                                                <img src={anonymousImage} alt='' />
                                                <Typography className={classes.anonymous}>Anonymous</Typography>
                                            </div>
                                        )
                                }
                            </div>
                        ) : (
                                <Author author={author} authorImageStyle={authorImageStyle} authorNameStyle={authorNameStyle} />
                            )
                    }
                </div>

            </div>
        </div>
    )
};

export default PostDisplay;
