import { makeStyles } from '@material-ui/core/styles';

import cursor from '../../assets/cursor.svg';

const url = `url('${cursor}'), auto`;

const font = {
    fontFamily: 'Varela Round, sans-serif, Regular',
    color: '#F2F2F2',
    letterSpacing: 0,
    opacity: 1,
};

export const useAnonymousNewCommentAttachDisplayStyles = makeStyles({
    
    newCommmentAttachDisplay: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#011E23',
    },

    title: {
        ...font,
        fontSize: '24px',
        lineHeight: '29px',
        marginBottom: '24px',
        marginTop: '24px',
    },

    divider: {
        width: '100%',
        backgroundColor: '#F2F2F2',
    },

    inputsHolder: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '552px',
        paddingLeft: '44px',
        paddingRight: '44px',
        paddingBottom: '64px',
    },

    fileHolder: {
        width: '-webkit-fill-available',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '114px',
        background: '#011E23 0% 0% no-repeat padding-box;',
        border: '1px solid #F2F2F2',
        borderRadius: '12px',
        opacity: 1,
        marginTop: '64px',
        padding: '20px',
    },

    fileContent: {
        display: 'flex',
        position: 'relative',
    },

    iconButtonHolder: {
        position: 'absolute',
        top: '-10px',
        right: '-10px',
    },

    iconButton: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '0.5px solid #011E23',
        opacity: 1,
        '&:hover': {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
        }
    },

    addFile: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },

    addButton: {
        ...font,
        fontSize: '12px',
        color: '#F2F2F2',
        opacity: 1,
        borderRadius: '12px',
        textTransform: 'unset',
        paddingBottom: '4px',
        paddingTop: '4px',
        marginTop: '8px',
        marginBottom: '8px',
    },

    inputHolder: {
        display: 'flex',
        alignItems: 'center',
        width: '552px',
        height: '196px',
        background: '#011E23 0% 0% no-repeat padding-box;',
        border: '1px solid #F2F2F2',
        borderRadius: '12px',
        opacity: 1,
        marginBottom: '8px',
        overflow: 'auto',
    },

    input: {
        ...font,
        fontSize: '17px',
        lineHeight: '21px',
        height: 'inherit',
        alignItems: 'flex-start',
        marginLeft: '20px',
        marginRight: '20px',
    },

    caractersHolder: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '64px',
        width: '552px',
        minWidth: '100%',
        justifyContent: 'space-between'
    },

    anonymous: {
        display: 'flex',
        alignItems: 'center',
    },

    anonymousButton: {
        borderRadius: '6px',
        opacity: 1,
        marginRight: '12px',
        minWidth: 'unset',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#EEFBFC',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        cursor: url,
    },

    caracters: {
        ...font,
        fontSize: '12px',
        color: '#A9AAAB',
    },

    buttons: {
        display: 'flex',
        alignSelf: 'flex-end',
    },

    cancelButton: {
        ...font,
        color: '#FFFFFF',
        fontSize: '16px',
        opacity: 1,
        borderRadius: '12px',
        width: '132px',
        textTransform: 'unset',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#A9AAAB', //'transparent',
            color: '#011E23',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },

    uploadButton: {
        ...font,
        fontSize: '16px',
        color: '#FFFFFF',
        background: '#26C6DA 0% 0% no-repeat padding-box',
        borderRadius: '12px',
        opacity: 1,
        textTransform: 'unset',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#26C6DA',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        width: '132px'
    },
    
});
