import { newIdKey, updateDomain } from '../firebase/firebase.utils';
import { Attachment } from '../models/domain/attachment';


export const createClassCommentAttachment = (
    
    async (firebase, data) => {
        
        console.log('createClassCommentAttachment() ', data);

        if (!data || !data.fileType || !data.size || !data.url || !data.classChat || !data.author) {
            console.warn('createCommentAttachment()', 'no valid creation data', data);
            return void 0;
        }

        const attaId = newIdKey(firebase);

        const attachment = Attachment.fromNew({
            idKey: attaId,
            ...data
        });

        await updateDomain(firebase, Attachment, attachment);

        return attachment;
    }
)