import { makeStyles } from '@material-ui/core/styles';

const font = {
    fontFamily: 'Varela Round, sans-serif, Regular',
    color: '#011E23',
    letterSpacing: 0,
    opacity: 1,
};

export const useDeleteCommentStyles = makeStyles({

    deleteComment: {
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center', 
    },

    title: {
        ...font,
        fontSize: '24px',
        lineHeight: '29px',
        marginBottom: '24px',
        marginTop: '24px',
    },

    divider: {
        width: '100%',
    },

    deleteCommentHolder: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '552px',
        paddingLeft: '44px',
        paddingRight: '44px',
        paddingBottom: '64px',
    },

    deleteMessage: {
        ...font,
        display: 'flex',
        alignSelf: 'flex-start',
        fontSize: '17px',
        lineHeight: '21px',
        marginBottom: '22px',
        marginTop: '48px',
    },

    commentHolder: {
        // display: 'flex',
        // alignItems: 'center',
        paddingTop: '16px',
        paddingBottom: '16px',
        paddingLeft: '20px',
        paddingRight: '20px',
        width: '552px',
        // height: '56px',
        background: '#FCFCFC 0% 0% no-repeat padding-box',
        border: '1px solid #011E23',
        borderRadius: '12px',
        opacity: 1,
        marginBottom: '64px',
    },

    buttons: {
        display: 'flex',
        alignSelf: 'flex-end',
    },

    cancelButton: {
        ...font,
        fontSize: '16px',
        color: '#011E23',
        opacity: 1,
        borderRadius: '12px',
        width: '132px',
        textTransform: 'unset',
    },
    
    deleteButton: {
        ...font,
        fontSize: '16px',
        color: '#FFFFFF',
        background: '#DA2626 0% 0% no-repeat padding-box',
        borderRadius: '12px',
        opacity: 1,
        textTransform: 'unset',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#DA2626',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
        },
        width: '132px'
    },

});