import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';

import cursor from '../../assets/cursor.svg';

const url = `url('${cursor}'), auto`;

export const useClassCommentDisplayStyles = makeStyles({

    classComment: {
        display: 'flex',
        width: '100%',
        marginTop: '1.25vw',
    },

    content: {
        width: '100%',
        // width: '18.333333333333332vw',
    },

    authorDate: {
        display: 'flex',
        alignItems: 'center',
    },

    date: {
        ...FONT,
        fontSize: '0.8333333333333334vw',
        marginLeft: '0.8333333333333334vw'
    },

    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #E3E3E329',
        borderRadius: '0.625vw',
        opacity: 1,
        paddingLeft: '1.0416666666666665vw',
        paddingRight: '1.0416666666666665vw',
        paddingTop: '1.1111111111111112vh',
        paddingBottom: '1.1111111111111112vh',
    },

    text: {
        ...FONT,
        fontSize: '0.8854166666666666vw',
        // lineHeight: '2.5vh',
        lineHeight: '1.40625vw',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        // width: '100%',
        width: '15.46875vw',
    },

});

export const authorImageStyleL = {
    width: '2.5vw',
    height: '2.5vw',
    objectFit: 'cover',
    marginRight: '0.625vw',
    borderRadius: '0.625vw',
    opacity: 1,
};

export const authorNoimage = {
    display: 'none'
};

export const authorNameStyle = {
    ...FONT,
    fontSize: '0.8333333333333334vw',
};

export const authorNoName = {
    display: 'none',
};

export const attImageStyle = {
    // width: '300px',
    // width: '15.625vw',
    width: '15.46875vw',
    // width: '13.020833333333334vw',
    // height: '225px',
    height: '11.71875vw',
    // height: '9.114583333333332vw',
    objectFit: 'cover', 
};

export const linkText = {
    ...FONT,
    color: 'none',
    fontSize: '0.8854166666666666vw',
    lineHeight: '2.5vh',
    cursor: url,
};
