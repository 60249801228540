import React, { useState } from 'react';
import { connect } from 'react-redux';

import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import { MISSING } from '../../utils/const.utils';
import { object } from '../../utils/object.utils';

import { CLASSROOMS } from '../../localized-strings/classrooms';

import { StyledMenu, StyledMenuItem } from '../common/styled-menu/styled-menu.component';

import { openClassromSettingsPage } from '../../redux/classroom-settings/classroom-settings.actions';
import { openLeaveClassroomPage } from '../../redux/leave-classroom/leave-classroom.actions';
import { openInviteClassmatePage } from '../../redux/invite-classmate/invite-classmate.actions';

import { useClassDispalyStyles } from './class-display.styles';
import { useClassDispalyStylesR } from './class-display-r.styles';


const initialState = {
    mouseX: null,
    mouseY: null,
};


const ClassDispaly = ({ activeClass, openClassromSettingsPage, openLeaveClassroomPage, openInviteClassmatePage, edit, locale }) => {

    // console.log('Class Dispaly props', activeClass);

    const classesL = useClassDispalyStyles(); 
    const classesR = useClassDispalyStylesR();
    
    const classes = locale === 'he'? classesR : classesL; 

    const [state, setState] = useState(initialState);
    const [anchorEl, setAnchorEl] = useState(null);

    const strings = CLASSROOMS[locale];

    const onRClick = event => {
        event.preventDefault();
        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };
    
    const onMenuClose = () => {
        setState(initialState);
    };

    const onEdit = () => {
        openClassromSettingsPage(activeClass.idKey);
        onMenuClose();
    };

    const onLeave = () => {
        // openLeaveClassroomPage(activeClass.idKey);
        openLeaveClassroomPage(activeClass);
        onMenuClose();
    };

    const onInvite = () => {
        openInviteClassmatePage(activeClass);
        onMenuClose();
    };

    const onPopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const onPopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const images = object(activeClass.images);
    const image = images.thumbnailUrl || images.originalUrl || object(activeClass.imageUrl) || MISSING;


    return (
        <div onContextMenu={onRClick}>
            <img 
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={onPopoverOpen}
                onMouseLeave={onPopoverClose}
                className={classes.image} 
                src={image} 
                alt={activeClass.title}
            />

            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{paper: classes.paper}}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: locale === 'he'? 'left' : 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: locale === 'he'? 'right' : 'left',
                }}
                onClose={onPopoverClose}
                disableRestoreFocus
            >
                <Typography dir='auto' className={classes.title}>{activeClass.title}</Typography>
            </Popover>

            <StyledMenu
                keepMounted
                open={state.mouseY !== null}
                onClose={onMenuClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    state.mouseY !== null && state.mouseX !== null
                        ? { top: state.mouseY, left: state.mouseX }
                        : undefined
                }
            >
                {
                    edit? (
                    <StyledMenuItem onClick={onEdit}>{strings.editOption}</StyledMenuItem>
                    ):(
                        null
                    )
                }
                <StyledMenuItem  onClick={onLeave}>{strings.leaveOption}</StyledMenuItem>
                <StyledMenuItem  onClick={onInvite}>{strings.inviteOption}</StyledMenuItem>
            </StyledMenu>

        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    openClassromSettingsPage: onClass => dispatch(openClassromSettingsPage(onClass)),
    openLeaveClassroomPage: onClass => dispatch(openLeaveClassroomPage(onClass)),
    openInviteClassmatePage: onClass => dispatch(openInviteClassmatePage(onClass)),

});

export default connect(null, mapDispatchToProps)(React.memo(ClassDispaly));
