import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../../utils/font.utils';


export const useDispayFileStyles = makeStyles({

    download: {
        color: 'unset',
        textDecoration: 'none',
    },

    displayFile: {
        display: 'flex',
        alignItems: 'center',
        background: '#FCFCFC 0% 0% no-repeat padding-box',
        border: '0.5px solid #011E23',
        // borderRadius: '12px',
        borderRadius: '0.625vw',
        opacity: 1,
        // padding: '12px',
        padding: '0.625vw',
        width: 'fit-content',
    },

    image: {
        // width: '40px',
        width: '2.083333333333333vw',
        // height: '52px',
        height: '2.7083333333333335vw',
    },

    text: {
        // marginLeft: '20px',
        marginLeft: '1.0416666666666665vw',
    },

    fileName: {
        ...FONT,
        // fontSize: '17px',
        fontSize: '0.8854166666666666vw',
        // lineHeight: '6px',
    },

    fileSize: {
        ...FONT,
        // fontSize: '13px',
        fontSize: '0.6770833333333334vw',
        // lineHeight: '6px',
    },

});
