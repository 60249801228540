import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';


export const useLoggedInLinksStyles = makeStyles({

    loggedInLinks: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    grow: {
        flexGrow: 1,
    },

    profile: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    name: {
        ...FONT,
        color: '#26C6DA',
        textTransform: 'capitalize',
        '@media (min-width: 0px)': {
            marginRight: '8px',
            fontSize: '16px',
        },
        '@media (min-width: 600px)': {
            marginRight: '12px',
            fontSize: '20px',
        },
    },

    iconButton: {
        padding: 'unset',
        '@media (min-width: 0px)': {
            minHeight: '32px',
            minWidth: '32px',
        },
        '@media (min-width: 600px)': {
            minHeight: '40px',
            minWidth: '40px',
        },
    },

    avatar: {
        border: '1px solid #26C6DA',
        opacity: 1,
        '@media (min-width: 0px)': {
            height: '32px',
            width: '32px',
        },
        '@media (min-width: 600px)': {
            height: '40px',
            width: '40px',
        },
    },

});
