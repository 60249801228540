import { createSelector } from 'reselect';

export const selectEditComment = state => state.editCommentPage;

export const selectVisible = createSelector(
    [selectEditComment],
    editCommentPage => editCommentPage.visible
);

export const selectOnComment = createSelector(
    [selectEditComment],
    editCommentPage => editCommentPage.onComment
);
