import { makeStyles } from '@material-ui/core/styles';

const font = {
    fontFamily: 'Varela Round, sans-serif, Regular',
    color: '#F2F2F2',
    letterSpacing: 0,
    opacity: 1,
};

export const useAnonymousInlineEditStyles = makeStyles({

    input: {
        ...font,
        fontSize: '17px',
        lineHeight: '21px',
        height: 'inherit',
        alignItems: 'flex-start',
        marginLeft: '20px',
        marginRight: '20px',
    },

});
