import { makeStyles } from '@material-ui/core/styles';

const appbar = {
    height: '60px',
    background: '#F3F2F2',
    opacity: '1',
    boxShadow: 'unset',
};

export const useNavbarStyles = makeStyles({
    
    navbar: {
        flexGrow: 1,
        // height: '60px',
        boxShadow: 'unset',
    },

    appbar: appbar,

    toolbar: {
        ...appbar,
        minHeight: '60px',
    //    '@media (min-width: 600px)':
    //         {
    //             minHeight: '60px',
    //         },
        
    },

    logoEn: {
        paddingRight: '16px',
    },

    logoHe: {
        paddingLeft: '16px',
    },

});