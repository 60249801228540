export const No = Object.freeze({

    String: '',
    Number: 0,
    Boolean: false,

    Id:        '',
    Timestamp: 0,
    Url:       '',

    File:  void 0,
    Count: 0,
    Images: {},

    ContentType: '*',

});

