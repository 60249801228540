import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';

import cursor from '../../assets/cursor.svg';

const url = `url('${cursor}'), auto`;

export const usereplyStyles = makeStyles({

    reply: {
        display: 'flex',
        alignItems: 'flex-start',
        // marginBottom: '16px',
        marginBottom: '1.4814814814814814vh',
        // marginTop: '8px',
        marginTop: '0.7407407407407407vh',
    },

    share: {
        // marginRight: '8px',
        marginRight: '0.4166666666666667vw',
    },

    replyContent: {
        background: '#FCFCFC 0% 0% no-repeat padding-box;',
        border: '0.5px solid #011E23',
        // borderRadius: '12px',
        borderRadius: '0.625vw',
        opacity: 1,
        // paddingRight: '20px',
        paddingRight: '1.0416666666666665vw',
        // paddingTop: '14px',
        paddingTop: '1.2962962962962963vh',
        // paddingBottom: '12px',
        paddingBottom: '1.1111111111111112vh',
        // paddingLeft: '20px',
        paddingLeft: '1.0416666666666665vw',
        width: '100%',
    },

    author: {
        // marginBottom: '12px',
        marginBottom: '1.1111111111111112vh',
    },

    anonymousImageHolder:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // width: '20px',
        width: '1.0416666666666665vw',
        // height: '20px',
        height: '1.0416666666666665vw',
        // marginRight: '8px',
        marginRight: '0.4166666666666667vw',
        // borderRadius: '6px',
        borderRadius: '0.3125vw',
        background: '#011E23 0% 0% no-repeat padding-box',
        opacity: 1,
    },

    anonymous: {
        ...FONT,
        // fontSize: '14px',
        fontSize: '0.7291666666666666vw',
        // lineHight: '17px',
        lineHight: '1.5740740740740742vh',
        color: '#26C6DA',
    },

    replyText: {
        ...FONT,
        // fontSize: '17px',
        fontSize: '0.8854166666666666vw',
        // lineHight: '27px',
        lineHight: '2.5vh',
        whiteSpace: 'pre-wrap',
    },

    propsAttachment: {
        // width: '250px',
        width: '13.020833333333334vw',
        // height: '200px',
        height: '10.416666666666668vw',
        objectFit: 'cover', 
        // margin: '8px',
        margin: '0.4166666666666667vw',
    },

});

export const authorImageStyle = {
    display: 'none',
};

export const authorNameStyle = {
    ...FONT,
    // fontSize: '14px',
    fontSize: '0.7291666666666666vw',
    // lineHight: '17px',
};

export const anonymousNameStyle = {
    ...FONT,
    // fontSize: '14px',
    fontSize: '0.7291666666666666vw',
    // lineHight: '17px',
    color: '#26C6DA',
};

export const attImageStyle = {
    // width: '300px',
    width: '15.625vw',
    // height: '225px',
    height: '11.71875vw',
    objectFit: 'cover',
};

export const linkText = {
    ...FONT,
    color: 'none',
    // fontSize: '17px',
    fontSize: '0.8854166666666666vw',
    // lineHeight: '27px',
    lineHeight: '1.40625vw',
    cursor: url,
};

export const anonymousIcon = {
    height: '0.5208333333333333vw',
    width: '0.5208333333333333vw',
};
