import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';

import Typography from '@material-ui/core/Typography';

import Lightbox from 'react-lightbox-component';
import 'react-lightbox-component/build/css/index.css';

import DisplayFile from './display-file.component';

import { FONT } from '../../../utils/font.utils';
import { MISSING } from '../../../utils/const.utils';
import { object } from '../../../utils/object.utils'


const Attachment = ({ attachment, dd, attImageStyle, download, withLitebox }) => {

    // console.log('Attachment props', attachment, dd, attImageStyle);

    const lightBoxstyle = { ...attImageStyle, margin: 'unset'}

    if (!isLoaded(attachment)) {
        // return <div>Loading...</div>;
        return <Typography dir='auto' style={{...FONT, fontSize: '0.8333333333333334vw',}}>Loading...</Typography>;
    }

    if (isEmpty(attachment)) {
        return null; // <div>No Author</div>;
    }

    if(attachment.fileType === 'IMAGE' && withLitebox){
        const images = object(attachment.images);
        // const image1 = images.originalUrl || images.thumbnailUrl || null;
        // const image = image1? image1 : MISSING;

        const imageOriginal = images.originalUrl || images.thumbnailUrl || MISSING;
        const imageThumb = images.thumbnailUrl || images.originalUrl || MISSING;

        const imageForLitebox = [
            {
                src: imageOriginal,
                // title: 'image title',
                // description: 'image description',
                thumb: imageThumb,
            }
        ]
        
        return (
            <Lightbox
                images={imageForLitebox}
                renderImageFunc={(idx, imageForLitebox, toggleLightbox) => (
                    <img
                        key={idx}
                        src={imageForLitebox.thumb}
                        className={'lightbox-img-thumbnail'}
                        style={lightBoxstyle}
                        onClick={toggleLightbox.bind(null, idx)}
                        alt=''
                    />
                
                )}
                renderDescriptionFunc={() => {
                    return (
                        null
                    )
                }}
            />
        )
    }

    if(attachment.fileType === 'IMAGE'){
        const images = object(attachment.images);
        const image1 = images.thumbnailUrl || images.originalUrl || null;
        const image = image1? image1 : MISSING;
        return(
            <div style={{ width: `${attImageStyle.width}`, height: `${attImageStyle.height}` }}>
                <img src={image} alt={attachment.idKey} style={attImageStyle}/>
            </div>
        )
    }

    if(attachment.fileType === 'VIDEO'){
        return(
            // <video width='300' controls >
            <video style={{width: '15.625vw'}} controls >
                <source src={attachment.url} type="video/mp4" />
                <source src={attachment.url} type="video/ogg" />
                <source src={attachment.url} type="video/webm" />
                {/* <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" /> */}
                Your browser does not support HTML5 video.
            </video >
        )
    }

    if(attachment.fileType === 'AUDIO'){
        return(
            // <div style={{height: '54px'}}>
            <div style={{height: '5vh'}}>
                <audio
                style={{height: '5vh', width: '15.625vw'}}
                controls
                    src={attachment.url}>
                    Your browser does not support the
                    <code>audio</code> element.
                </audio>
            </div>
        )
    }

    if(attachment.fileType === 'VOICE'){
        return(
            <audio
            style={{height: '5vh', width: '15.625vw'}}
            controls
                src={attachment.url}>
                Your browser does not support the
                <code>audio</code> element.
            </audio>
        )
    }


    if(attachment.fileType === 'PDF'){
        return(
            <DisplayFile src='/images-types/file_pdf.png' attachment={attachment} download={download}/>
        )
    }

    if(attachment.fileType === 'PPT'){
        return(
            <DisplayFile src='/images-types/file_ppt.png' attachment={attachment} download={download}/>
        )
    }

    if(attachment.fileType === 'GENERAL'){
        return(
            <DisplayFile src='/images-types/file_general.png' attachment={attachment} download={download}/>
        )
    }

    if(attachment.fileType === 'DOC'){
        return(
            <DisplayFile src='/images-types/file_doc.png' attachment={attachment} download={download}/>
        )
    }

    if(attachment.fileType === 'XLS'){
        return(
            <DisplayFile src='/images-types/file_xls.png' attachment={attachment} download={download}/>
        )
    }


    if(attachment.url){
        return(
            attachment.url
        )
    }
    
    
    return(
        <div>
            <img src={MISSING} alt={attachment.idKey} style={dd}/>
        </div>
    )
    
};

const mapStateToProps = (state, ownProps) => {

    // console.log('Attachment state', state);
    
    const attachments = state.firebase.data.attachments;
    const attachment = attachments? attachments[ownProps.attId] : null;
    
    return {
        attachment: attachment,
    }
};

export default compose(
    connect(mapStateToProps),
    firebaseConnect(ownProps => [
        `attachments/${ownProps.attId}`
    ]),       
)(Attachment);
