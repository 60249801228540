import React from 'react'

import Typography from '@material-ui/core/Typography';
import Author from '../common/author/author.component';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import moment from 'moment';

import { useCommentStyles,
    authorImageStyleL,
    authorNoimage,
    authorNameStyle,
    authorNoName
} from './comment.styles';

import { useCommentStylesR, authorImageStyleR } from './comment-r.styles';


const DeletedComment = ({ comment, attId, author, id, strings, locale }) => {

    // console.log('Deleted Comment props', comment, attId, author, id);

    const classesL = useCommentStyles();
    const classesR = useCommentStylesR();
    const classes = locale === 'he'? classesR : classesL;

    const authorImageStyle = locale === 'he'? authorImageStyleR : authorImageStyleL;

    const time = moment(comment.value.createdTime).calendar();

    return(
        <div className={classes.comment}>
            <Author author={author} authorImageStyle={authorImageStyle} authorNameStyle={authorNoName} />
            <div className={classes.content}>
                <div className={classes.flexSpaceBetween}>
                    <div className={classes.authorDate}>
                        <Author author={author} authorImageStyle={authorNoimage} authorNameStyle={authorNameStyle} />
                        <Typography dir='auto' className={classes.date}>{time}</Typography>
                    </div>
                </div>
                <div className={classes.deletedbody}>
                <Typography dir='auto' className={classes.deletedtext}>{strings.deletedCommentMessage}</Typography>
                <ErrorOutlineIcon className={classes.deletedErrorIcon}/>
                </div>
            </div>
        </div>
    )
}

export default DeletedComment
