import React from 'react';

import { usePickedPhoto } from '../../../utils/files/picked-photo.utils';
import PreviewAttachDisplay from './preview-attachment-display.component';

import { attachmentType } from '../../../utils/string.utils';


const PreviwAttachment = ({ file }) => {

    // console.log('PreviwAttachment props', file);

    const {name, type, size } = file;

    const imagePreviewUrl = usePickedPhoto(file);

    const imageStyle = {width: '300px', height: '225px', objectFit: 'cover',}


    if(attachmentType(type) === 'IMAGE'){
        return(
            <img src={imagePreviewUrl} alt={name} style={imageStyle}/>
        )
    };

    if(attachmentType(type) === 'VIDEO'){
        return(
            <PreviewAttachDisplay icon={'/images-types/file_video.png'} name={name} size={size} />
        )
    };

    if(attachmentType(type) === 'AUDIO'){
        return(
            <PreviewAttachDisplay icon={'/images-types/file_sound.png'} name={name} size={size} />
        )
    };

    if(attachmentType(type) === 'VOICE'){
        return(
            <PreviewAttachDisplay icon={'/images-types/file_sound.png'} name={name} size={size} />
        )
    };


    if(attachmentType(type) === 'PDF'){
        return(
            <PreviewAttachDisplay icon={'/images-types/file_pdf.png'} name={name} size={size} />
        )
    }

    if(attachmentType(type) === 'PPT'){
        return(
            <PreviewAttachDisplay icon={'/images-types/file_ppt.png'} name={name} size={size} />
        )
    }

    if(attachmentType(type) === 'GENERAL'){
        return(
            <PreviewAttachDisplay icon={'/images-types/file_general.png'} name={name} size={size} />
        )
    }

    if(attachmentType(type) === 'DOC'){
        return(
            <PreviewAttachDisplay icon={'/images-types/file_doc.png'} name={name} size={size} />
        )
    }

    if(attachmentType(type) === 'XLS'){
        return(
            <PreviewAttachDisplay icon={'/images-types/file_xls.png'} name={name} size={size} />
        )
    }
    
    return(
        null
    )
    
};


export default PreviwAttachment;