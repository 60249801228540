import { makeStyles } from '@material-ui/core/styles';

export const useNewCommentStyles = makeStyles({

    newComment: {
        display: 'flex',
        background: '#FCFCFC',
        alignItems: 'center',
        // paddingLeft: '120px',
        paddingLeft: '6.25vw',
        // paddingRight: '120px',
        paddingRight: '6.25vw',
        // paddingTop: '28px',
        paddingTop: '2.5925925925925926vh',
        // paddingBottom: '36px',
        paddingBottom: '3.3333333333333335vh',
    },

});
