import { COMMON } from './common';

export const EDIT_CLASSROOM ={

    en: {
        title: 'Edit Channel',
        writeTitle: 'Write a Channel Title',
        cancelButton: COMMON['en'].cancel,
        saveButton: COMMON['en'].save,
    },

    he: {
        title: 'ערוך ערוץ',
        writeTitle: 'הוסף שם ערוץ',
        cancelButton: COMMON['he'].cancel,
        saveButton: COMMON['he'].save,
    },

};
