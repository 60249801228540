import { User } from './user';
import { No } from '../models-core';
import { Attachment } from './attachment';
import { empty } from '../../utils/string.utils';


export class Comment {

    static name() {
        return 'comments';
    }

    static path(idKey) {

        const name = Comment.name();
        return empty(idKey) ? `/${name}` : `/${name}/${idKey}`;
    }


    static field(idKey, name) {
        return `${Comment.path(idKey)}/${name}`;
    }

    static empty() {

        return {

            idKey:            No.Id,
            author:           No.String,
            createdTime:      No.Timestamp,
            lastUpdatedUTime: No.Timestamp,
            post:             No.Id,
            text:             No.String,

            attachments:             {},
            significantAttachmentId: No.Id,

            replyTo: {},

            anonymous: {},
            isDeleted: {},
            isEdited:  {},

        };

    }


    static fromEmpty(merge) {
        return {...Comment.empty(), ...merge};
    }


    static fromNew(merge) {

        // const now = nowTimestamp$();
        // const now = firebase.database.ServerValue.TIMESTAMP;

        // return Comment.fromEmpty({
        //     createdTime:      now,
        //     lastUpdatedUTime: now,
        //     ...merge,
        // });

        return Comment.fromEmpty(merge);

    }


    static populates() {

        return [
            {
                child:      'author',
                childAlias: 'authorItem',
                root:       User.path(),
            },
            {
                child:      'significantAttachmentId',
                childAlias: 'significantAttachmentItem',
                root:       Attachment.path(),
            },
            {
                child:      'attachments',
                childAlias: 'attachmentsItemSet',
                root:       Attachment.path(),
            },
        ];

    }

}


