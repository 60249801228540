import React from 'react';

import Typography from '@material-ui/core/Typography';

import { formatBytes } from '../../../utils/files/file-size';

import { usePreviewAttachmentStyles } from './preview-attachment-display.styles';

const PreviewAttachDisplay = ({ icon, name, size }) => {

    const classes = usePreviewAttachmentStyles();

    // const size = formatBytes(size);

    return (
        // <div className={classes.container}>
        //     <div className={classes.previewAttachment}>
        //         <div className={classes.icon}>
        //             <img src={icon} alt={name} />
        //         </div>
        //         <div>{name}</div>
        //     </div>
        // </div>
        <div dir='ltr' className={classes.previewAttachmentDisplay}>
            <img className={classes.image} src={icon} alt={name} />
            <div className={classes.text}>
                <Typography className={classes.fileName}>{name}</Typography>
                <Typography className={classes.fileSize}>{formatBytes(size)}</Typography>
            </div>
        </div>
    )
}

export default PreviewAttachDisplay
