export const isURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
};

// function replaceURLWithLinks(text){
// export const replaceURLWithLinks = (text) => {
//     var text = "";
//     text= text.replace(/\r?\n/g, '<br />');
//     var result = URI.withinString(text, function(url) {
//         return "<a href='"+url+"' target='_blank'>" + url + "</a>";
//     });
// };

export const findUrls = (text) => {
    // eslint-disable-next-line no-useless-escape
    // var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    // eslint-disable-next-line no-useless-escape
    var urlRegex = /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi;
    const result = text.match(urlRegex);
    return result;
};

export const matchYoutubeUrl = (url) => {
    // var p = /^(?:https?:\/\/)?(?:www\.)?    (?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    var p = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
    return (url.match(p)) ? true : false ;
};

export const matchZoomUrl = (url) => {
    var p = /^https:\/\/openu\.zoom\.us\/rec\/.*/;
    return (url.match(p)) ? true : false ;
};

export const videoId = (url) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length===11)? match[7] : false;
};

export const zoomVideoId = (url) => {

    const id = url.substr(32);
    
    return id;
};

export const matchFirebaseUrl = (url) => {
    const sub = 'https://firebasestorage.googleapis.com/v0/b/classme-150316.appspot.com/o/videos';
    const match = url.includes(sub);
    // console.log(match);
    return match;
};