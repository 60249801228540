import { empty } from '../../utils/string.utils';
import { No } from '../models-core';
import { FileType } from '../domain-core';

export class Attachment {

    static name() {
        return 'attachments';
    }

    static path(idKey) {
        const name = Attachment.name();
        return empty(idKey) ? `/${name}` : `/${name}/${idKey}`;
    }


    static field(idKey, name) {
        return `${Attachment.path(idKey)}/${name}`;
    }


    static empty() {
        return {
            idKey:            No.Id,
            author:           No.String,
            createdTime:      No.Timestamp,
            fileName:         No.String,
            fileType:         FileType.Other,
            images:           {
                originalUrl:  No.Url,
                thumbnailUrl: No.Url,
            },
            lastUpdatedUTime: No.Timestamp,
            // ownerId:          No.Id,
            // post:             No.Id,
            size:             No.Number,
            url:              No.Url,
        };
    }


    static fromEmpty(merge) {

        return {...Attachment.empty(), ...merge};

    }


    static fromNew(merge) {

        // const now = firebase.database.ServerValue.TIMESTAMP;

        // return Attachment.fromEmpty({
        //     createdTime:      now,
        //     lastUpdatedUTime: now,
        //     ...merge,
        // });
        return Attachment.fromEmpty(merge);

    }


    static populates() {
        return [];
    }


}


