import { NewClassroomActionTypes } from './new-classroom.types';

const INITIAL_STATE = {
    hidden: false,
};

const newClassroomReducer = (state = INITIAL_STATE, action) => {

    if(action.type === NewClassroomActionTypes.TOGGLE_CLASSROOM_PAGE_HIDDEN){
        return {
            ...state,
            hidden: !state.hidden
        }
    };

    return state;
};

export default newClassroomReducer;