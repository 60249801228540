import React from 'react';

import Typography from '@material-ui/core/Typography';

import { formatBytes } from '../../../utils/files/file-size';

import { useDispayFileStyles } from './display.file.styles';

const DisplayFile = ({ src, attachment, download }) => {

    // console.log('DisplayFile props', src, attachment);

    const classes = useDispayFileStyles();

    const size = formatBytes(attachment.size);


    return (

        <div>
            {
                download? (
                    <a className={classes.download} href={attachment.url} target="_blank" rel="noopener noreferrer" download>
                        <div dir='ltr' className={classes.displayFile} >
                            <img className={classes.image} src={src} alt={attachment.idKey} />
                            <div className={classes.text}>
                                <Typography className={classes.fileName}>{attachment.fileName}</Typography>
                                <Typography className={classes.fileSize}>{size}</Typography>
                            </div>
                        </div>
                    </a>
                ):(
                    <div dir='ltr' className={classes.displayFile} >
                        <img className={classes.image} src={src} alt={attachment.idKey} />
                        <div className={classes.text}>
                            <Typography className={classes.fileName}>{attachment.fileName}</Typography>
                            <Typography className={classes.fileSize}>{size}</Typography>
                        </div>
                    </div>
                )
            }
        </div>

        
    )
}

export default DisplayFile;
