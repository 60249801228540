import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect, populate } from 'react-redux-firebase'
import { withRouter } from 'react-router-dom';

import ClassroomsP from './classroomsP.component'

import { valuesOfObjectSet, object } from '../../utils/object.utils';


const populates = [
    { child: 'classesStudent', root: 'classes' }, // replace classesStudent id with class object
    { child: 'classesTeacher', root: 'classes' } // replace classesTeacher id with class object
];

function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
};


const enhance = compose(
    withRouter,
    // firebaseConnect(({ id }) => {
    firebaseConnect((ownProps) => {
        // console.log('ClassroomsP container Props', ownProps);
        const { id } = ownProps;
        return [
            // passing populates parameter also creates all necessary child queries
            ({ path: `users/${id}`, populates })
        ]
    }),
    connect((state, ownProps) => {
        // console.log("ClassroomsP container state props",  state, ownProps);

        const { firebase } = state;
        const { id } = ownProps;

        // populate original from data within separate paths redux
        // const currentUser = populate(firebase, `users/${firebase.auth.phoneNumber}`, populates);
        const currentUser = populate(firebase, `users/${id}`, populates);
        // firebase.ordered.todos or firebase.data.todos for unpopulated todos

        // console.log('currentUser', currentUser);

        if (!currentUser) {
            return { loading: true };
        }

        const { classesTeacher } = currentUser;
        const { classesStudent } = currentUser;
        // console.log('classesStudent', classesStudent);

        const classes = [...valuesOfObjectSet(classesTeacher), ...valuesOfObjectSet(classesStudent)];
        // console.log('All Classes', classes);
        const uniqueClasses = getUniqueListBy(classes, 'idKey');
        // console.log('All Unique Classes', uniqueClasses);

        const permissions = object(currentUser.permissions);
        const edit = permissions.canEditAll;

        const locale = state.localization && state.localization.locale;
        // console.log(locale);

        return { currentUserClasses: uniqueClasses, loading: false, edit, locale }
    })
)

export default enhance(ClassroomsP);
