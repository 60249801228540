import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useFirebaseConnect, isLoaded } from 'react-redux-firebase'

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import AddQuestionImage from '../../assets/addQuestion.png'

import NewQuestionPage from '../../pages/new-question-page/new-question-page.component';
import PostHeader from './post-header.component';
import Comments from '../comments/comments.component';
import NewComment from '../new-commment/new-comment.component';

// import { POSTS } from '../../localized-strings/posts';

import { usePostDetailsStyles } from './post-details.styles';


const PostDetails = ({ match, locale }) => {

    // console.log('PostDetails Props',  match, locale);

    const classes = usePostDetailsStyles();

    const classId = match.params.classId;
    const postId = match.params.postId;

    // const strings = POSTS[locale];

    useFirebaseConnect([`classes/${classId}`], [classId]) // sync /classes/${classId} from firebase into redux
    useFirebaseConnect([`postsByClass/${classId}`], [classId]) // sync /postsByClass/${classId} from firebase into redux

    const classroom = useSelector(state => {
        return state.firebase.data.classes && state.firebase.data.classes[classId]
    })
    // console.log(classroom);

    const postsByClass = useSelector(({ firebase: { ordered: { postsByClass } } }) => postsByClass && postsByClass[classId]);
    // console.log(postsByClass);


    if (classId === ':classId' && postId === ':postId') {
        return (
            <div className={classes.select}>
                {/* <Typography dir='auto' className={classes.messages}>{strings.selectQuestionMessage}</Typography> */}
            </div>
        )
    };

    if (classId !== ':classId' && postId === ':postId') {

        if (!isLoaded(postsByClass)) {
            return (
                <div className={classes.circularholder}>
                    <CircularProgress />
                </div>
            )
        };

        if (!isLoaded(classroom)) {
            return (
                <div className={classes.circularholder}>
                    <CircularProgress />
                </div>
            )
        };

        return (

            <div className={classes.circularholder}>
                <Typography dir='auto' className={classes.messages}>{classroom.title}</Typography>
                <img className={classes.addQuestionImage} src={AddQuestionImage} alt='Add Question' />
                <NewQuestionPage first={true} />
            </div>

        );

    };

    return (
        <div className={classes.postDetails}>

            <div className={classes.postDetailsHeader}>
                <PostHeader postId={postId} classId={classId} locale={locale} />
            </div>

            <div className={classes.postDetailsComments}>

                <div className={classes.holder}>

                    <div className={classes.subHolder}>

                        <div className={classes.comments}>
                            <Comments postId={postId} />
                        </div>

                        <NewComment />

                    </div>

                </div>

            </div>

        </div>
    )

};

export default withRouter(PostDetails);
