import { User } from '../models/domain/user';
import { updateDomain } from '../firebase/firebase.utils';

export const createUser = (

    async (firebase, data) => {

        console.log('Create user data', data);

        if (!data) {
            console.warn('createUser', 'no data');
            return void 0;
        }

        const userId = data.idKey
        const user = User.fromNew({...data, idKey: userId});

        console.log('user', user);

        await updateDomain(firebase, User, user);

        return user;

    }

);
