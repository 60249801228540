import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import { firebaseReducer } from 'react-redux-firebase';
import userReducer from './user/user.reducer';
import newClassroomReducer from './new-classroom/new-classroom.reducer';
import newQuestionReducer from './new-question/new-question.reducer';
import newCommentAttachReducer from './new-comment/new-comment.reducer';
import newClassCommentReducer from './new-class-comment/new-class-comment.reducer';
import deleteCommentReducer from './delete-comment/delete-comment.reducer';
import replyToCommentReducer from './reply-to-comment/reply-to-comment.reducer';
import editCommentReducer from './edit-comment/edit-comment.reducer';
import profilePageReducer from './profile/profile.reducer';
import logoutPageReducer from './logout/logout.reducer';
import classromSettingsPageReducer from './classroom-settings/classroom-settings.reducer';
import leaveClassromPageReducer from './leave-classroom/leave-classroom.reducer';
import editQuestionReducer from './edit-question/edit-question.reducer';
import inviteClassmatePageReducer from './invite-classmate/invite-classmate.reducer';
import localizationReducer from './localization/localization.reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user']
};


const rootReducer = combineReducers({
    firebase: firebaseReducer,
    user: userReducer,
    newClassroomPage: newClassroomReducer,
    newQuestionPage: newQuestionReducer,
    newCommentAttachPage: newCommentAttachReducer,
    newClassCommentPage: newClassCommentReducer,
    deleteCommentPage: deleteCommentReducer,
    replyToCommentPage: replyToCommentReducer,
    editCommentPage: editCommentReducer,
    profilePage: profilePageReducer,
    logoutPage: logoutPageReducer,
    classromSettingsPage: classromSettingsPageReducer,
    leaveClassroomPage: leaveClassromPageReducer,
    editQuestionPage: editQuestionReducer,
    inviteClassmatePage: inviteClassmatePageReducer,
    localization: localizationReducer,
});

export default persistReducer(persistConfig, rootReducer);