import React from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

import CloseIcon from '@material-ui/icons/Close';
import anonymousImage from '../../assets/anonymousBlackSmall.svg';

import { FilePick } from '../common/files-input/files.pick.component';
import PreviwAttachment from '../common/previw-attachment/previw-attachment.component';

import { useAnonymousNewCommentAttachDisplayStyles } from './anonymous-new-comment-attach-display.styles';
import { useAnonymousNewCommentAttachDisplayStylesR } from './anonymous-new-comment-attach-display-r.styles';


const AnonymousNewCommentAttachDisplay = ({ onToggle, toggleCommentAnonymous,
    onChange, comment, file, onReset, onFile, onSend, strings, locale }) => {

    const classesL = useAnonymousNewCommentAttachDisplayStyles();
    const classesR = useAnonymousNewCommentAttachDisplayStylesR();
    const classes = locale === 'he'? classesR : classesL;

    const popUpdir = locale === 'he'? 'rtl' : 'ltr';
    const direction = locale === 'he'? 'rtl' : 'ltr';

    const disabled = file? false : true;

    return (

        <div dir={popUpdir} className={classes.newCommmentAttachDisplay}>

            <Typography dir='auto' className={classes.title}>{strings.title}</Typography>

            <Divider className={classes.divider} />

            <div className={classes.inputsHolder}>

                <div className={classes.fileHolder}>
                    {
                        file ? (
                            <div className={classes.fileContent}>
                                <PreviwAttachment  file={file} />
                                <div className={classes.iconButtonHolder}>
                                    <IconButton size='small' className={classes.iconButton} onClick={onReset}>
                                        <CloseIcon style={{fontSize: '16px'}} />
                                    </IconButton>
                                </div>
                            </div>
                        ) : (null)
                    }
                </div>

                <div className={classes.addFile}>
                    <FilePick

                        onPick={onFile}
                        content={() =>
                            (
                                <Button className={classes.addButton} component="span">
                                    {strings.addButton}
                                </Button>
                            )
                        }
                    />
                </div>

                <div className={classes.inputHolder}>
                    <Input
                        multiline
                        className={classes.input}
                        fullWidth
                        disableUnderline
                        placeholder={strings.pageInputPlaceholder}
                        inputProps={{
                            'aria-label': 'description',
                            maxLength: 1024,
                            dir: {direction},
                        }}
                        onChange={onChange}
                        value={comment}
                    />
                </div>

                <div className={classes.caractersHolder}>

                    <div className={classes.anonymous}>

                        <Button className={classes.anonymousButton} component='span' onClick={toggleCommentAnonymous}>
                            <img src={anonymousImage} alt='abutton' />
                        </Button>

                        <Typography className={classes.caracters}>{strings.writeAnonymously}</Typography>

                    </div>

                    <Typography className={classes.caracters}>{`${comment.length}/1024`}</Typography>

                </div>


                <div className={classes.buttons}>

                    <div className={classes.anonymous}>

                        <div>
                            <Button className={classes.cancelButton} onClick={onToggle}>
                                {strings.cancelButton}
                            </Button>
                        </div>

                        <div>
                            <Button disabled={disabled} className={classes.uploadButton} onClick={onSend}>
                                {strings.uploadButton}
                            </Button>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    );

};

export default AnonymousNewCommentAttachDisplay;
