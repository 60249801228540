import { LeaveClassromActionTypes } from './leave-classroom.types';

const INITIAL_STATE = {
    visible: false,
    onClass: null
};

const leaveClassromPageReducer = (state = INITIAL_STATE, action) => {

    if(action.type === LeaveClassromActionTypes.OPEN_LEAVE_CLASSROOM_PAGE){
        return {
            ...state,
            visible: true,
            onClass: action.payload,
        }
    }

    if(action.type === LeaveClassromActionTypes.CLOSE_LEAVE_CLASSROOM_PAGE){
        return {
            ...state,
            visible: false,
        }
    };

    return state;
};

export default leaveClassromPageReducer;
