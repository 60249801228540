import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';


export const useVotingStyles = makeStyles({

    voting: {
        display: 'flex',
        // marginRight: '38px',
        marginRight: '1.9791666666666665vw',
    },

    dispaly: {
        // marginRight:'12px',
        display: 'flex',
        alignItems: 'baseline',
    },

    number: {
        ...FONT,
        // fontSize: '12px',
        fontSize: '0.625vw',
        // marginRight: '8px',
    },

    button: {
        padding: 'unset',
        marginLeft: '0.625vw',
        marginRight: '0.625vw',
    },

    likes: {
        height: '1.1979166666666667vw',
        width: '1.1979166666666667vw',
    }

});
