import { updateFields } from '../../firebase/firebase.utils';
import { Comment } from '../../models/domain/comment';

export const onDeleteComment = async(firebase, commentId) => {

    // console.log('onDeleteComment() => ', commentId);

    try {

        if(!commentId) {
            console.warn('No info for the comment! Aborting delete ...');
            return void 0;
        }

        const now = firebase.database.ServerValue.TIMESTAMP;
        const path = `${commentId}`;

        console.log(now, path)

        const upData = {
            isDeleted: now,
            lastUpdatedUTime: now
        }

        await updateFields(firebase, Comment.path(path), upData);

        console.warn('onDeleteComment() > try{}', 'Comment deleted!');

    } catch (e) {

        console.error('onDeleteComment() > catch{}', e);

    }

}
