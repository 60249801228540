import { COMMON } from './common';

export const CLASSROOMS = {

    en: {
        noMessage: 'You don`t have Channels',
        editOption: COMMON['en'].edit,
        leaveOption: 'Leave',
        inviteOption: 'Invite',
    },

    he: {
        noMessage: 'אין לך כרגע הרצאות',
        editOption: COMMON['he'].edit,
        leaveOption: 'צא מההרצאה',
        inviteOption: 'הזמן חבר',
    },

};
