import React from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import anonymousImage from '../../assets/anonymousBlackSmall.svg';

import AnonymousImageInputAndDisply from './anonymous-image-input-and-disply.component';
// import AnonymousTypeSelector from './anonymous-type-selector.component';
// import AnonymouseDatePicker from './anonymouse-date-picker.component';
import AnonymousQuestionInput from './anonymous-question-input.component';

import { useAnonymousNewQuestionDispalyStyles } from './anonymous-new-question-display.styles';
import { useAnonymousNewQuestionDispalyStylesR } from './anonymous-new-question-display-r.styles';


const AnonymousNewQuestionDisplay = ({ file, imagePreviewUrl, onFile, type, onType, selectedDate, onDateChange,
    onChange, question, onAnonymous, disabled, disabledWithdate, onCreate, onClose, strings, locale }) => {

    const classesL = useAnonymousNewQuestionDispalyStyles();
    const classesR = useAnonymousNewQuestionDispalyStylesR();
    const classes = locale === 'he'? classesR : classesL;

    const popUpdir = locale === 'he'? 'rtl' : 'ltr';


    return (
        <div dir={popUpdir} className={classes.newQuestion}>

            <Typography dir='auto' className={classes.title}>{strings.title}</Typography>

            <Divider className={classes.divider} />

            <div className={classes.inputsHolder}>

                <AnonymousImageInputAndDisply file={file} imagePreviewUrl={imagePreviewUrl} onFile={onFile} />

                {/* <AnonymousTypeSelector type={type} onType={onType} strings={strings} locale={locale} />

                <AnonymouseDatePicker type={type} selectedDate={selectedDate} onDateChange={onDateChange} strings={strings} locale={locale}/> */}

                <AnonymousQuestionInput onChange={onChange} question={question} strings={strings} locale={locale}/>

                <div className={classes.caractersHolder}>

                    <div className={classes.anonymous}>

                        <Button className={classes.anonymousButton} component='span' onClick={onAnonymous}>
                            <img src={anonymousImage} alt='' />
                        </Button>

                        <Typography className={classes.caracters}>{strings.writeAnonymously}</Typography>

                    </div>

                    <div>
                        <Typography className={classes.caracters}>{`${question.length}/1024`}</Typography>
                    </div>

                </div>

                <div className={classes.buttons}>
                    <div>
                        <Button className={classes.cancelButton} onClick={onClose}>
                            {strings.cancelButton}
                        </Button>
                    </div>

                    <div>
                        {
                            (type === 'QUESTION') ? (
                                    <Button disabled={disabled} className={classes.addButton} onClick={onCreate}>
                                        {strings.addButton}
                                    </Button>
                                ):(
                                    <Button disabled={disabledWithdate} className={classes.addButton} onClick={onCreate}>
                                        {strings.addButton}
                                    </Button>
                                )
                        }
                    </div>
                </div>

            </div>

        </div>
    )
};

export default AnonymousNewQuestionDisplay;
