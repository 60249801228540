import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CustomModal from '../../components/common/custom-modal/custom-modal.component';
import ProfileComponent from '../../components//profile/profile.component';

import { selectVisible } from '../../redux/profile/profile.selector';
import { selectLocale } from '../../redux/localization/localization.selector';
import { closeProfilePage } from '../../redux/profile/profile.actions';


const ProfilePage = ({ locale, visible, closeProfilePage }) => {

    // console.log('Profile Page Props', locale, visible);

    return(
        <div>
            <CustomModal 
                open={visible}
                onClose={closeProfilePage}
                content={<ProfileComponent closeProfilePage={closeProfilePage} locale={locale} />} 
            />
        </div>
    )
    
};

const mapStateToProps = createStructuredSelector({
    visible: selectVisible,
    locale: selectLocale,
});

const mapDispatchToProps = dispatch => ({
    closeProfilePage: () => dispatch(closeProfilePage())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
