import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EditClassroom from '../../components/edit-classroom/edit-classroom.component';
import CustomModal from '../../components/common/custom-modal/custom-modal.component';

import { selectVisible, selectOnClass } from '../../redux/classroom-settings/classroom-settings.selector';
import { selectLocale } from '../../redux/localization/localization.selector';
import { closeClassromSettingsPage } from '../../redux/classroom-settings/classroom-settings.actions';


const EditClassroomPage = ({ classId, visible, closeClassromSettingsPage, locale }) => {

    // console.log('Class Settings Page props', classId, visible, closeClassromSettingsPage, selectVisible, locale)

    return (
        <div>
            <CustomModal 
                open={visible}
                onClose={closeClassromSettingsPage} 
                content={<EditClassroom classId={classId} closeClassromSettingsPage={closeClassromSettingsPage} locale={locale} />}
            />
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    visible: selectVisible,
    classId: selectOnClass,
    locale: selectLocale,
});

const mapDispatchToProps = dispatch => ({
    closeClassromSettingsPage: () => dispatch(closeClassromSettingsPage())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditClassroomPage);
