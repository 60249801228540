import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { createStructuredSelector } from 'reselect';

import ProfileDisplayComponent from './profile-display.component';

import { usePickedPhoto } from '../../utils/files/picked-photo.utils';
import { trackCoool } from '../../utils/cooladata.utils';
import { trackFirebase } from '../../utils/firebase-analytics.utils';

import { onUpdateUserImage } from './on-update-user-image.handler';
import { onUpdateUserName } from './on-update-user-name.handler';

import { selectCurrentUser, selectThumbImage, selectOriginalImage, selectImageUrl } from '../../redux/user/user.selector';

const ProfileComponent = ({ locale, currentUser, thumb, original, imageUrl, closeProfilePage }) => {

    // console.log('Profile Component props', locale, currentUser,  thumb, original, imageUrl);

    const user = currentUser.idKey;
    const firstName = currentUser.firstName;
    const lastName = currentUser.lastName;
    const oneOf = thumb || original || imageUrl;
    const oldImageUrl = oneOf ? oneOf : null;

    const [file, setFile] = useState(null);
    const [fName, setFname] = useState(firstName);
    const [lName, setLname] = useState(lastName);

    const imagePreviewUrl = usePickedPhoto(file);

    const firebase = useFirebase();

    const paramsForEvent = {
        user_id: user,
        user_name: firstName + ' ' + lastName,
    };

    useEffect(() => {
        trackCoool('edit_user_details_initiated', paramsForEvent);
        trackFirebase('edit_user_details_initiated', paramsForEvent);
    }, [paramsForEvent]);

    const onFile = files => {
        const file = files && files[0];
        setFile(file)
    };

    const onChangeFname = ev => {

        const { value } = ev.target;

        if (value[0] === ' ') {
            setFname('');
        } else {
            setFname(value);
        }

    };

    const onChangeLname = ev => {

        const { value } = ev.target;

        if (value[0] === ' ') {
            setLname('');
        } else {
            setLname(value);
        }

    };

    const onClose = () => {
        closeProfilePage();
    };

    const fileForEdit = () => {
        if (file) {
            onUpdateUserImage(firebase, file);
        } else {
            return null;
        }
    };

    const onConfirm = () => {

        const data = {
            firstName: fName,
            lastName: lName,
        };

        fileForEdit();
        onUpdateUserName(firebase, data);

        closeProfilePage();

    };

    const disabled = (!fName || !lName) ? true : false;

    return (
        <div>
            <ProfileDisplayComponent
                oldImageUrl={oldImageUrl}
                file={file}
                imagePreviewUrl={imagePreviewUrl}
                onFile={onFile}
                onChangeFname={onChangeFname}
                fName={fName}
                onChangeLname={onChangeLname}
                lName={lName}
                onClose={onClose}
                disabled={disabled}
                onConfirm={onConfirm}
                locale={locale}
            />
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    thumb: selectThumbImage,
    original: selectOriginalImage,
    imageUrl: selectImageUrl,
});


export default connect(mapStateToProps)(ProfileComponent);
