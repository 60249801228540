import { makeStyles } from '@material-ui/core/styles';


const font = {
    fontFamily: 'Varela Round, sans-serif, Regular',
    color: '#F2F2F2',
    letterSpacing: 0,
    opacity: 1,
};

export const useAnonymousQuestionInputStyles = makeStyles({

    inputHolder: {
        display: 'flex',
        alignItems: 'center',
        width: '552px',
        height: '196px',
        background: '#011E23 0% 0% no-repeat padding-box;',
        border: '1px solid #F2F2F2',
        borderRadius: '12px',
        opacity: 1,
        marginBottom: '8px',
        overflow: 'auto',
    },

    input: {
        ...font,
        fontSize: '17px',
        lineHeight: '21px',
        height: 'inherit',
        alignItems: 'flex-start',
        marginLeft: '20px',
        marginRight: '20px',
    },

});
