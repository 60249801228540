export const dynamicLinksData = (classId) => {

    const linkData = {
        dynamicLinkInfo: {
            domainUriPrefix: 'https://kxg82.app.goo.gl',//'https://kxg82.app.goo.gl', // 'https://threadsapp.page.link',
            link: `https://kxg82.app.goo.gl/group/join?id=${classId}`, //`https://threads-1511.web.app/threads/${tweet.id_str}`,
            // link: `https://classme-web-app.firebaseapp.com/group/join?id=${classId}`, //`https://threads-1511.web.app/threads/${tweet.id_str}`,
            // link: `http://localhost:3000/group/join?id=${classroom.idKey}`, //`https://threads-1511.web.app/threads/${tweet.id_str}`,
            // link: `http://localhost:3000/join/${classroom.idKey}`, //`https://threads-1511.web.app/threads/${tweet.id_str}`,
    
            iosInfo: {
                // iOS related attributes to the Dynamic Link..
    
                iosAppStoreId: '1264583380',
                // iOS App Store ID.
    
                // iosBundleId: 'com.google.samples.quickstart.SignInExample',
                iosBundleId: 'com.classme.classme.qa',
                // iOS bundle ID of the app.
    
                // iosCustomScheme:string
                // Custom (destination) scheme to use for iOS. By default, we’ll use the bundle ID as the custom scheme. Developer can override this behavior using this param.
    
                // iosFallbackLink:string
                // Link to open on iOS if the app is not installed.
    
                // iosIpadBundleId:string
                // iPad bundle ID of the app.
    
                // iosIpadFallbackLink:string
                // If specified, this overrides the ios_fallback_link value on iPads.
    
                // iosMinimumVersion:string
                // iOS minimum version.
    
            },
    
            androidInfo: {
                // Android related attributes to the Dynamic Link.
    
                androidFallbackLink: 'https://play.google.com/store/apps/details?id=com.class.messenger',
                // Link to open on Android if the app is not installed.
    
                // androidLink:string
                // If specified, this overrides the ‘link’ parameter on Android.
    
                // androidMinPackageVersionCode:string
                // Minimum version code for the Android app. If the installed app’s version code is lower, then the user is taken to the Play Store.
    
                androidPackageName: 'com.class.messenger.integ',
                // Android package name of the app.
    
            },
    
            desktopInfo: {
                // Desktop related attributes to the Dynamic Link.
                desktopFallbackLink: `https://classme-web-app.firebaseapp.com/join/${classId}`,
                // Link to open on desktop.
            },
    
            socialMetaTagInfo: {
                // Parameters for social meta tag params. Used to set meta tag data for link previews on social sites.
    
                // socialDescription:string
                // A short description of the link. Optional.
    
                socialImageLink: 'http://class.me/wp-content/uploads/2017/06/logo.png',
                // An image url string. Optional.
    
                // socialTitle:string
                // Title to be displayed. Optional.
    
            }
        },
        suffix: {
            option: 'SHORT',
        },

    };

    return linkData;

};
