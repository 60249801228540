import React from 'react';

import Button from '@material-ui/core/Button';

import NewShowImage from './new-show-image.component';

import { FilePick } from '../files-input/files.pick.component';

import { useAnonymousFileEditStyles, imageStyle, caracterStyle } from './anonymous-file-edit.styles';


const AnonymousFileEdit = ({ attId, file, onDelete, onFile, strings, accept }) => {

    const classes = useAnonymousFileEditStyles();

    return  (
        
        <div className={classes.holder}>

            <div className={classes.fileHolder}>
                <NewShowImage 
                    attId={attId}
                    file={file}
                    onDelete={onDelete}
                    imageStyle={imageStyle}
                    caracterStyle={caracterStyle}
                    strings={strings}
                />
            </div>

            <div className={classes.addFile}>

                <FilePick
                    accept={accept}
                    onPick={onFile}
                    content={() =>
                        (
                            <div>
                                <Button className={classes.addButton} component="span">{strings.addFileButton}</Button>
                            </div>
                        )
                    }
                />

            </div>

        </div>
    )

}

export default AnonymousFileEdit;
