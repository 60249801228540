import { NewClassCommentActionTypes } from './new-class-comment.types';

const INITIAL_STATE = {
    newClassCommentHidden: false,
    classMessage: '',
};

const newClassCommentReducer = (state = INITIAL_STATE, action) => {

    if(action.type === NewClassCommentActionTypes.TOGGLE_CLASS_COMMENT_PAGE_HIDDEN){
        return {
            ...state,
            newClassCommentHidden: !state.newClassCommentHidden
        }
    };

    if(action.type === NewClassCommentActionTypes.CLASS_COMMENT_MESSAGE){
        return {
            ...state,
            classMessage: action.payload,
        }
    };

    return state;
};

export default newClassCommentReducer;