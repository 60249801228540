import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Typography from '@material-ui/core/Typography';
import Author from '../common/author/author.component';
import Attachment from '../common/attachment/attachment.component';
import Reply from '../comments/reply.component';

import anonymousImage from '../../assets/anonymousBlack.svg';

import moment from 'moment';

import { selectCurrentUserView } from '../../redux/user/user.selector';

import {
    useCommentForDeleteStyles,
    authorImageStyleL,
    authorNoimage,
    authorNameStyle,
    anonymousNameStyle,
    authorNoName,
    attImageStyle
} from './comment-for-delete-display.styles';

import { useCommentForDeleteStylesR, authorImageStyleR } from './comment-for-delete-display-r.styles';


const CommentForDelete = ({ comment, attId, author, view, locale }) => {

    // console.log('Comment for delete props', comment, attId, author, view);

    const classesL = useCommentForDeleteStyles();
    const classesR = useCommentForDeleteStylesR();
    const classes = locale === 'he'? classesR : classesL;

    const authorImageStyle = locale === 'he'? authorImageStyleR : authorImageStyleL;

    const anonymous = comment.anonymous || null;
    const time = moment(comment.createdTime).calendar();
    const isEdited = comment.isEdited || null;


    return(
        <div className={classes.comment}>
            <div>
                {
                    anonymous? (
                        <div className={classes.anonymousImageHolder}>
                            <img src={anonymousImage} width='32' height='32' alt='' />
                        </div>
                    ):(
                        <Author author={author} authorImageStyle={authorImageStyle} authorNameStyle={authorNoName} />
                    )
                }
            </div>
            <div className={classes.content}>
                <div className={classes.flexSpaceBetween}>
                    <div>
                        {
                            anonymous? (
                                <div>
                                    {
                                        view?(
                                            <div className={classes.authorDate}>
                                                <Typography className={classes.anonymous}>Anonymous</Typography>
                                                <Typography className={classes.anonymous} style={{marginLeft: '8px'}}>(</Typography>
                                                <Author author={author} authorImageStyle={authorNoimage} authorNameStyle={anonymousNameStyle} />
                                                <Typography className={classes.anonymous}>)</Typography>
                                                <Typography className={classes.date}>{time}</Typography>
                                                <div>
                                                    {
                                                        isEdited? (
                                                            <Typography className={classes.editedDate}>{moment(isEdited).calendar()}</Typography>
                                                        ): null
                                                    }
                                                </div>
                                            </div>
                                        ):(
                                            <div className={classes.authorDate}>
                                                <Typography className={classes.anonymous}>Anonymous</Typography>
                                                <Typography className={classes.date}>{time}</Typography>
                                                <div>
                                                    {
                                                        isEdited? (
                                                            <Typography className={classes.editedDate}>{moment(isEdited).calendar()}</Typography>
                                                        ): null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            ):(
                                <div className={classes.authorDate}>
                                    <Author author={author} authorImageStyle={authorNoimage} authorNameStyle={authorNameStyle} />
                                    <Typography className={classes.date}>{time}</Typography>
                                    <div>
                                        {
                                            isEdited? (
                                                <Typography className={classes.editedDate}>
                                                    (edited {moment(isEdited).calendar()})
                                                </Typography>     
                                            ): null
                                        }
                                    </div>
                                </div>
                            )
                        }
                        
                    </div>
                </div>
                
                <div>
                    <Reply reply={comment.replyTo || null} view={view} locale={locale} />
                    <Attachment attId={attId[0]}  attImageStyle={attImageStyle} />
                    <Typography dir='auto' className={classes.text}>{comment.text}</Typography>
                </div>

            </div>
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    view: selectCurrentUserView
});

export default connect(mapStateToProps)(CommentForDelete);
