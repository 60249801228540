import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';

export const useJoinStylesR = makeStyles({

    join: {
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center', 
        // background: '#FCFCFC 0% 0% no-repeat padding-box',
        // border: '1px solid #011E23',
        // borderRadius: '12px',
        // opacity: 1,
    },

    title: {
        ...FONT,
        // fontSize: '24px',
        fontSize: '1.25vw',
        // lineHeight: '29px',
        // marginBottom: '24px',
        marginBottom: '2.2222222222222223vh',
        // marginTop: '24px',
        marginTop: '2.2222222222222223vh',
    },

    divider: {
        width: '100%',
    },

    joinHolder: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // width: '552px',
        width: '28.749999999999996vw',
        // paddingLeft: '44px',
        paddingLeft: '2.2916666666666665vw',
        // paddingRight: '44px',
        paddingRight: '2.2916666666666665vw',
        // paddingBottom: '64px',
        paddingBottom: '5.925925925925926vh',
    },

    joinMessage: {
        ...FONT,
        display: 'flex',
        alignSelf: 'flex-start',
        // fontSize: '17px',
        fontSize: '0.8854166666666666vw',
        // lineHeight: '21px',
        lineHeight: '1.9444444444444444vh',
        // marginBottom: '22px',
        marginBottom: '2.037037037037037vh',
        // marginTop: '48px',
        marginTop: '4.444444444444445vh',
    },

    classroomHolder: {
        // width: '552px',
        width: '28.749999999999996vw',
        background: '#FCFCFC 0% 0% no-repeat padding-box',
        border: '1px solid #011E23',
        // borderRadius: '12px',
        borderRadius: '0.625vw',
        opacity: 1,
        // marginBottom: '64px',
        marginBottom: '5.925925925925926vh',
    },

    classroom: {
        // marginTop: '16px',
        marginTop: '1.4814814814814814vh',
        // marginBottom: '16px',
        marginBottom: '1.4814814814814814vh',
        // marginLeft: '20px',
        marginLeft: '1.0416666666666665vw',
        // marginRight: '20px',
        marginRight: '1.0416666666666665vw',
        display: 'flex',
        alignItems: 'center',
    },

    classroomImage: {
        // width: '48px;',
        width: '2.5vw',
        // height: '48px',
        height: '2.5vw',
        // borderRadius: '12px',
        borderRadius: '0.625vw',
        opacity: 1,
    },

    classroomTitle: {
        ...FONT,
        // fontSize: '16px',
        fontSize: '0.8333333333333334vw',
        // lineHeight: '20px',
        lineHeight: '1.8518518518518519vh',
        // marginLeft: '12px',
        // marginLeft: '0.625vw',
        marginRight: '0.625vw',
    },

    buttons: {
        display: 'flex',
        alignSelf: 'flex-end',
    },

    cancelButton: {
        ...FONT,
        // fontSize: '16px',
        fontSize: '0.8333333333333334vw',
        color: '#011E23',
        opacity: 1,
        // borderRadius: '12px',
        borderRadius: '0.625vw',
        // width: '132px',
        width: '6.875000000000001vw',
        textTransform: 'unset',
    },
    
    joinButton: {
        ...FONT,
        // fontSize: '16px',
        fontSize: '0.8333333333333334vw',
        color: '#FFFFFF', // #011E23', //'#FFFFFF',
        background: '#26C6DA 0% 0% no-repeat padding-box', //'#DA2626 0% 0% no-repeat padding-box', //'#E8E9EB 0% 0% no-repeat padding-box', //,
        // borderRadius: '12px',
        borderRadius: '0.625vw',
        opacity: 1,
        textTransform: 'unset',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#26C6DA', // '#E8E9EB', //'#DA2626',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
        },
        // width: '132px',
        width: '6.875000000000001vw',
    },

});
