import { createSelector } from 'reselect';

const selectClassroomSettingsPage = state => state.classromSettingsPage;

export const selectVisible = createSelector(
    [selectClassroomSettingsPage],
    classromSettingsPage => classromSettingsPage.visible
);

export const selectOnClass = createSelector(
    [selectClassroomSettingsPage],
    classromSettingsPage => classromSettingsPage.onClass
);
