import { makeStyles, withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

const font = {
    fontFamily: 'Varela Round, sans-serif, Regular',
    color: '#011E23',
    letterSpacing: 0,
    opacity: 1,
};

export const useNewInlineEditStyles = makeStyles({

    input: {
        ...font,
        fontSize: '17px',
        lineHeight: '21px',
        height: 'inherit',
        alignItems: 'flex-start',
        marginLeft: '20px',
        marginRight: '20px',
    },

});

export const StyledTextField = withStyles({
    root: {
        height: '100%',
        '& .MuiInputLabel-root': {
            fontFamily: 'Varela Round, sans-serif, Regular',
        },
        '& label.Mui-focused': {
            color: '#011E23',
            borderRadius: '12px',
            paddingRight: '20px',
        },
        '& .MuiOutlinedInput-root': {
            height: '100%',
            '& fieldset': {
                borderColor: '#011E23',
                borderRadius: '12px',
                paddingRight: '20px',
            },
            '&:hover fieldset': {
                borderColor: '#011E23',
                borderRadius: '12px',
                paddingRight: '20px',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#011E23',
                borderRadius: '12px',
                paddingRight: '20px',
            },
        },
        '& .MuiSvgIcon-root': {
            color: '#A9AAAB',
            paddingRight: '20px',
        },
        '& .MuiSelect-root': {
            paddingLeft: '20px',
            ...font,
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: '#EEFBFC',
            borderRadius: '12px',
        },
        '& .MuiOutlinedInput-input': {
            paddingRight: '20px',
            paddingTop: '18px',
            paddingBottom: '18px',
            height: '100%',
            overflow: 'auto',
            '& textarea' :{
                height: 'inherit',
                overflow: 'auto',
            }
        },
        '& .MuiTextField-root': {
            height: '100%',
        },
        '& .MuiFormControl-root': {
            height: '100%',
            '& fullWidth': {
                height: '100%'
            }
        },
        '& .MuiFormControl-fullWidth': {
            // width: 100%
            height: 'inherit',
        },
        '& .MuiOutlinedInput-multiline': {
            height: 'inherit',
            overflow: 'auto',
        },
        '& .MuiInputBase-input': {
            overflow: 'auto',
            height: 'inherit',
            '& textarea' :{
                height: 'inherit',
                overflow: 'auto',
            }
        },
        '& .MuiInputBase-inputMultiline': {
            overflow: 'auto',
            height: 'inherit',
            '& textarea' :{
                height: 'inherit',
                overflow: 'auto',
            }
        },
    },
    textarea :{
        height: 'inherit',
        overflow: 'auto',
    },
})(TextField);