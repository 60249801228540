import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import IconButton from '@material-ui/core/IconButton';
import AttachIcon from '../../assets/attachIcon.svg';
import AttachIconAnonymous from '../../assets/attachIconAnonymous.svg';

import CustomModal from '../../components/common/custom-modal/custom-modal.component';
import NewCommmentAttach from '../../components/new-commment/new-comment-attach.component';

import { toggleNewCommentAtttachPageHidden } from '../../redux/new-comment/new-comment.actions';
import { selectNewCommentAttachPageState, selectCommentAnonymouseState } from '../../redux/new-comment/new-comment.selector';
import { selectLocale } from '../../redux/localization/localization.selector';

import { useNewCommentAttachPageStyles } from './new-comment-attach-page.styles';


const NewCommentAttachPage = ({ newCommentAttachHidden, toggleNewCommentAtttachPageHidden, commentAnonymouseState, locale }) => {

    // console.log('New Comment Attach Page props', newCommentAttachHidden, commentAnonymouseState, locale);

    const classes = useNewCommentAttachPageStyles();

    return (

        <div>

            {
                commentAnonymouseState ? (
                    <IconButton className={classes.iconButtons} onClick={toggleNewCommentAtttachPageHidden}>
                        <img className={classes.icon} src={AttachIconAnonymous} alt='button' />
                    </IconButton>
                ) : (
                    <IconButton className={classes.iconButtons} onClick={toggleNewCommentAtttachPageHidden}>
                        <img className={classes.icon} src={AttachIcon} alt='button' />
                    </IconButton>
                )
            }
            <CustomModal 
                open={newCommentAttachHidden}
                onClose={toggleNewCommentAtttachPageHidden}
                content={<NewCommmentAttach locale={locale} />} 
            />
            
        </div>
    );

};

const mapStateToProps = createStructuredSelector({
    newCommentAttachHidden: selectNewCommentAttachPageState,
    commentAnonymouseState: selectCommentAnonymouseState,
    locale: selectLocale,
});


const mapDispatchToProps = dispatch => ({
    toggleNewCommentAtttachPageHidden: () => dispatch(toggleNewCommentAtttachPageHidden())
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCommentAttachPage);
