import { EditQuestionActionTypes } from './edit-question.types';

const INITIAL_STATE = {
    editQuestionHidden: false,
};

const editQuestionReducer = (state = INITIAL_STATE, action) => {

    if(action.type === EditQuestionActionTypes.TOGGLE_EDIT_QUESTION_PAGE){
        return {
            ...state,
            editQuestionHidden: !state.editQuestionHidden
        }
    };

    return state;
};

export default editQuestionReducer;