import { ReplyToCommentActionTypes } from './reply-to-comment.types';

const INITIAL_STATE = {
    visible: false,
    onComment: null
};

const replyToCommentReducer = (state = INITIAL_STATE, action) => {

    if(action.type === ReplyToCommentActionTypes.OPEN_REPLY_TO_COMMENT_PAGE){
        return {
            ...state,
            visible: true,
            onComment: action.payload,
        }
    }

    if(action.type === ReplyToCommentActionTypes.CLOSE_REPLY_TO_COMMENT_PAGE){
        return {
            ...state,
            visible: false,
            // onComment: null
        }
    };

    return state;
};

export default replyToCommentReducer;