import { updateFields } from '../../firebase/firebase.utils'
import { Class } from '../../models/domain/class';
import { User } from '../../models/domain/user';

export const onJoinClassroom = async (firebase,data) => {

    console.log('onJoinClassroom() > ()', data);

    const classId = data.classId;
    const user = data.user;

    try{

        const pathForClass = `${classId}/users`;
        const newUser = {[user]: 1};

        await updateFields(firebase, Class.path(pathForClass), newUser);

        console.log('onJoinClassroom() > try{}', 'Add user to the classroom SUCCESS !!!');

        const pathForUser = `${user}/classesStudent`;
        const newClass = {[classId]: true};

        await updateFields(firebase, User.path(pathForUser), newClass);

        console.log('onCreate() > try{}', 'Add classroom to the user SUCCESS !!!');

    } catch (e) {

        console.warn('onJoinClassroom > catch{}', e);

    };

};
