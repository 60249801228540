import React from 'react'
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import { useLoggedOutLinksStyle } from './logged-out-links.styles';

const LoggedOutLinks = ({ history }) => {

    const classes = useLoggedOutLinksStyle();

    return (
        <div className={classes.loggedOutLinks}>
            <div className={classes.grow} />
            <div>
                <Button className={classes.loginButton} onClick={() => history.push('/signin')} color="inherit">
                    Login
                </Button>
                {/* <Button onClick={() => history.push('/signup')} color="inherit">
                    Sigin Up
                </Button> */}
            </div>
        </div>
    )
}

export default withRouter(LoggedOutLinks);
