import React, { useState } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';

import moment from 'moment';

import EditQuestionDisplay from './edit-question-display.component';
import AnonymousEditQuestionDisplay from './anonymous-edit-question-display.component';

import { EDIT_QUESTION } from '../../localized-strings/edit-question';

import { onUpdateQuestionImage } from './on-update-quetion-image.handler';
import { onUpdateQuestionDeleteImage } from './on-update-question-delete-file.handler';
import { onUpdateQuestion } from './on-udate-question.handler';

import { toggleEditQuestionPageHidden } from '../../redux/edit-question/edit-question.actions';


const EditQuestion = ({ question, toggleEditQuestionPageHidden, locale }) => {

    // console.log('Edit question props', question);

    const firebase = useFirebase();

    const strings = EDIT_QUESTION[locale];

    const classId = question.classId;
    const postId = question.idKey;
    const attId = question.significantAttachmentId;
    const questionType = question.type;
    const date = question.dueDateUTime;
    const text = question.body;
    const anon = question.anonymous;

    const [att, setAtt] = useState(attId);
    const [file, setFile] = useState(null);
    const [deleted, setDeleted] = useState(false);
    const [type, setType] = useState(questionType);
    const [selectedDate, setSelectedDate] = useState(date);
    const [editValue, setEditValue] = useState(text);
    const [anonymous, setAnonymous] = useState(anon);

    const onFile = files => {
        setDeleted(false);
        setAtt('');
        const file = files && files[0];
        setFile(file);
    };

    const onDelete = () => {
        setDeleted(true);
        setAtt('');
        setFile('');
    };

    const onType = ev => {
        setType(ev.target.value);
    };

    const onDateChange = date => {
        // TO UTC
        setSelectedDate(moment(new Date(date)).valueOf());
        
    };

    const onChangeText = ev => {
        const { value } = ev.target;
        if(value[0] === ' '){
            setEditValue('');
        }else{
            setEditValue(value);
        }
    };

    const onAnonymous = () => {
        setAnonymous(!anonymous);
    };

    const fileForEdit = () => {
        if(file){
            onUpdateQuestionImage(firebase, file, classId, postId);
        } else {
            return null;
        }
    };

    const fileForDelete = () => {
        if(deleted){
            onUpdateQuestionDeleteImage(firebase, classId, postId);
        } else {
            return null;
        }
    }

    const onConfirm = () => {

        const writeAnon = (anonymous === true)? true : null;
        const haveDate = selectedDate? selectedDate : null;
        const writeDate = (type === 'QUESTION')? null: haveDate;

        const data = {
            anonymous: writeAnon,
            body: editValue,
            dueDateUTime: writeDate,
            type,
        };

        fileForEdit();
        fileForDelete();
        onUpdateQuestion(firebase, data, classId, postId);
        toggleEditQuestionPageHidden();
    };

    const onClose = () => {
        setEditValue(text);
        setAtt(attId);
        setFile(null);
        setType(questionType);
        setSelectedDate(date);
        toggleEditQuestionPageHidden();
    };

    const disabled = editValue? false : true;


    return (
        <div>
            {
                anonymous? (
                    <AnonymousEditQuestionDisplay
                        attId={att}
                        file={file}
                        onDelete={onDelete}
                        onFile={onFile}
                        type={type}
                        onType={onType}
                        selectedDate={selectedDate}
                        onDateChange={onDateChange}
                        editValue={editValue}
                        onChangeText={onChangeText}
                        onAnonymous={onAnonymous}
                        disabled={disabled}
                        onConfirm={onConfirm}
                        onClose={onClose}
                        strings={strings}
                        locale={locale}
                    />
                ):(
                    <EditQuestionDisplay 
                        attId={att}
                        file={file}
                        onDelete={onDelete}
                        onFile={onFile}
                        type={type}
                        onType={onType}
                        selectedDate={selectedDate}
                        onDateChange={onDateChange}
                        editValue={editValue}
                        onChangeText={onChangeText}
                        onAnonymous={onAnonymous}
                        disabled={disabled}
                        onConfirm={onConfirm}
                        onClose={onClose}
                        strings={strings}
                        locale={locale}
                    />
                )
            }
        </div>
    )
};

const mapDispatchToProps = dispatch => ({
    toggleEditQuestionPageHidden: () => dispatch(toggleEditQuestionPageHidden())
});

export default connect(null,mapDispatchToProps)(EditQuestion);
