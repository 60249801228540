import React from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import anonymousImage from '../../assets/anonymousSmall.svg';

import NewFileEdit from '../common/file-edit/new-file-edit.component';
// import TypeSelector from '../new-question/type-selector.component';
// import DatePicker from '../new-question/date-picker.component';
import NewInlineEdit from '../common/inline-edit/new-inline-edit';

import { useEditQuestionStyles } from './edit-question-display.styles';
import { useEditQuestionStylesR } from './edit-question-display-r.styles';


const EditQuestionDisplay = ({ attId, file, onDelete, onFile, editValue, onChangeText,
    type, onType, selectedDate, onDateChange, onAnonymous, disabled, onConfirm, onClose, strings, locale }) => {

    // console.log('Edit Question props', attId, file, editValue);

    const classesL = useEditQuestionStyles();
    const classesR = useEditQuestionStylesR();
    const classes = locale === 'he'? classesR : classesL;

    const popUpdir = locale === 'he'? 'rtl' : 'ltr';

    const caractersLength = !editValue? 0 : editValue.length;

    return (
        <div dir={popUpdir} className={classes.editQuestion}>

            <Typography dir='auto' className={classes.title}>{strings.title}</Typography>

            <Divider className={classes.divider} />

            <div className={classes.inputsHolder}>

                <NewFileEdit
                    accept='image/*'
                    attId={attId}
                    file={file}
                    onDelete={onDelete}
                    onFile={onFile}
                    strings={strings}
                />

                {/* <TypeSelector type={type} onType={onType} strings={strings} locale={locale} />

                <DatePicker type={type} selectedDate={selectedDate} onDateChange={onDateChange} strings={strings} locale={locale} /> */}

                <div className={classes.inputHolder}>

                    <NewInlineEdit
                        autoFocus={true}
                        multiline={true}
                        value={editValue}
                        onChangeText={onChangeText}
                        strings={strings}
                        locale={locale}
                    />

                </div>

                <div className={classes.caractersHolder}>

                    <div className={classes.anonymous}>

                        <Button className={classes.anonymousButton} component='span' onClick={onAnonymous}>
                            <img src={anonymousImage} alt='abutton' />
                        </Button>

                        <Typography className={classes.caracters}>{strings.writeAnonymously}</Typography>

                    </div>

                    <Typography className={classes.caracters}>{`${caractersLength}/1024`}</Typography>

                </div>

                <div className={classes.buttons}>

                    <div className={classes.anonymous}>

                        <div>
                            <Button className={classes.cancelButton} onClick={onClose}>
                                {strings.cancelButton}
                            </Button>
                        </div>

                        <div>
                            <Button disabled={disabled} className={classes.saveButton} onClick={onConfirm}>
                                {strings.saveButton}
                            </Button>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default EditQuestionDisplay;
