import { DeleteCommentActionTypes } from './delete-comment.types';

export const openDeleteCommentPage = onComment => ({
// export const openDeleteCommentPage = () => ({
    type: DeleteCommentActionTypes.OPEN_DELETE_COMMENT_PAGE,
    payload: onComment,
});

export const closeDeleteCommentPage = () => ({
    type: DeleteCommentActionTypes.CLOSE_DELETE_COMMENT_PAGE,
});
