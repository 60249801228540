import { makeStyles } from '@material-ui/core/styles';

import { FONT } from '../../utils/font.utils'

export const useClassmatesStyles = makeStyles({

    select: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    info: {
        ...FONT,
        // fontSize: '20px',
        fontSize: '1.0416666666666665vw',
        // marginTop: '58px',
        marginTop: '5.37037037037037vh',
    },

    loading: {
        display: 'flex',
        width: 'inherit',
        justifyContent: 'center',
        // paddingTop: '24px',
        marginTop: '5.37037037037037vh',
    },

    circular: {
        // maxHeight: '4.166666666666666vw',
        // maxWidth: '4.166666666666666vw',
        height: '4.166666666666666vw',
        width: '4.166666666666666vw',
    },

    noClasses: {
        display: 'flex', 
        // marginTop: '150px',
        marginTop: '5.37037037037037vh',
        alignItems: 'center', 
        justifyContent: 'center', 
        flexDirection: 'column',
    },

    classmates: {
        // height: '-webkit-fill-available',
        height: '100vh',
        paddingRight: '1.4583333333333333vw',
        paddingLeft: '1.4583333333333333vw',
        // paddingTop: '5.37037037037037vh',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },

    classmate: {
        marginTop: '1.25vw',
    }

});

export const authorImageStyleL = {
    width: '3.3333333333333335vw',
    height: '3.3333333333333335vw', 
    objectFit: 'cover', 
    borderRadius: '0.625vw',
    marginRight: '0.8333333333333334vw',
};

export const authorNameStyle = {
    ...FONT,
    fontSize: '0.9375vw',
};
