import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CustomModal from '../../components/common/custom-modal/custom-modal.component';
// import EditComment from '../../components/comment-options/edit-comment.component';
import NewEditComment from '../../components/comment-options/new-edit-comment.component';

import { closeEditCommentPage } from '../../redux/edit-comment/edit-comment.actions';
import { selectVisible, selectOnComment } from '../../redux/edit-comment/edit.comment.selector';
import { selectLocale } from '../../redux/localization/localization.selector';


const EditCommentPage = ({ visible, onComment, closeEditCommentPage, match, locale }) => {

    // console.log('Edit Comment Page props',  visible, onComment, closeEditCommentPage, match, locale);

    const postId = match.params.postId;


    return (
        <div>
            {/* TO DO REMOVE AND RENAME COMPONENTS NOT IN USE IN INLINE EDIT AND FILE EDIT*/}
            {/* <CustomModal open={visible} onClose={closeEditCommentPage} content={<EditComment comment={onComment} postId={postId} />}/> */}
            <CustomModal 
                open={visible}
                onClose={closeEditCommentPage}
                content={<NewEditComment comment={onComment} postId={postId} locale={locale} />}
            />
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    visible: selectVisible,
    onComment: selectOnComment,
    locale: selectLocale,
});

const mapDispatchToProps = dispatch => ({
    closeEditCommentPage: () => dispatch(closeEditCommentPage())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditCommentPage));
