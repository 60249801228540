import { uploadCommentFile } from '../../crud/upload-comment-file';
import { createClassCommentAttachment } from '../../crud/create-class-comment-attachment';
import { createClassComment } from '../../crud/create-new-class-comment';
import { attachmentType } from '../../utils/string.utils';


export const onCreateClassComment = async(firebase, data) => {


    console.log('onCreateClassComment() > ()', data);


    try {

        if(!data.file) {
            console.warn('No file for the comment! Skkipping upload and creating message...');

            if(!data.text) {
                console.error('No message for the comment! Skkipping creating message...');
                return void 0;
            }

            const comment = await createClassComment(firebase, {
                author: data.author,
                text: data.text,
                class: data.class,
                createdTime: firebase.database.ServerValue.TIMESTAMP,
                lastUpdatedUTime: firebase.database.ServerValue.TIMESTAMP,
            });
            
            console.log('onCreateClassComment() > try{}', 'Comment ctreated: ', comment);

            return void 0;
        }

        const updata = await uploadCommentFile(firebase, data.file);
        console.log('onCreateClassComment() > try{}', 'Comment file uploaded: ', updata);


        const dataForAttachment = {
            author: data.author,
            classChat: {
                classId: data.class,
                messageId: updata.folder,
            },
            createdTime: firebase.database.ServerValue.TIMESTAMP,
            lastUpdatedUTime: firebase.database.ServerValue.TIMESTAMP,
            size: updata.size,
            url: updata.url,
            fileType: attachmentType(updata.contentType),
            fileName: updata.metadata.name,
            images: { originalUrl: updata.url, thumbnailUrl: updata.url },
            
        }
        console.log('Data for creating attachment: ', dataForAttachment);

        const attachment = await createClassCommentAttachment(firebase, dataForAttachment);
        console.log('onCreateClassComment() > try{}', 'Class comment attachment created: ', attachment);

        const comment = await createClassComment(firebase, {
            attachments: {
                [attachment.idKey]: true,
            },
            idKey: updata.folder,
            author: data.author,
            text: data.text,
            class: data.class,
            createdTime: firebase.database.ServerValue.TIMESTAMP,
            lastUpdatedUTime: firebase.database.ServerValue.TIMESTAMP,
        });

        
        console.log('onCreateClassComment() > try{}', 'Class comment ctreated: ', comment);

    } catch (e) {

        console.error('onCreateClassComment() > catch{}', e);

    }

};
