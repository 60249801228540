import React, { useState } from 'react';

import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';

import Picker from 'emoji-picker-react';

import { useEmojiPoperStyles } from  './emoji-poper.styles';


const EmojiPoper = ({ icon, onEmojiClick }) => {

    const classes = useEmojiPoperStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'emoji-popper' : undefined;

    const onClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    return (
        <div>

            <IconButton className={classes.iconButtons} id='emoji' aria-describedby={id} type="button" onClick={onClick}>
                <img className={classes.icon} src={icon} alt='smileybutton' />
            </IconButton>
            <Popper id={id} open={open} anchorEl={anchorEl}>
                <Picker onEmojiClick={onEmojiClick} />
            </Popper>

        </div>
    )
}

export default EmojiPoper;
