import React from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

import anonymousImage from '../../assets/anonymousSmall.svg';

import NewFileEdit from '../common/file-edit/new-file-edit.component';
import Reply from '../comments/reply.component';

import { useReplyDisplayStyles } from './reply-display.syles';
import { useReplyDisplayStylesR } from './reply-display-r.syles';


const ReplyDisplay = ({ file, onFile, onDelete, message, onChange, 
    toggleReplyAnonymous, onClose, rComment, view, onSend, disabled, strings, locale }) => {

    const classesL = useReplyDisplayStyles();
    const classesR = useReplyDisplayStylesR();
    const classes = locale === 'he'? classesR : classesL;

    const popUpdir = locale === 'he'? 'rtl' : 'ltr';
    const direction = locale === 'he'? 'rtl' : 'ltr';


    return (

        <div dir={popUpdir} className={classes.replyDisplay}>

            <Typography dir='auto' className={classes.title}>{strings.title}</Typography>

            <Divider className={classes.divider} />

            <div className={classes.inputsHolder}>

                <NewFileEdit
                    file={file}
                    onDelete={onDelete}
                    onFile={onFile}
                    strings={strings}
                />

                <div className={classes.inputHolder}>
            
                    <Reply reply={rComment} view={view} locale={locale} />

                    <Input
                        multiline
                        className={classes.input}
                        fullWidth
                        disableUnderline
                        placeholder={strings.inputPlaceholder}
                        inputProps={{
                            'aria-label': 'description',
                            maxLength: 1024,
                            dir: {direction},
                        }}
                        onChange={onChange}
                        value={message}
                    />
                </div>

                <div className={classes.caractersHolder}>

                    <div className={classes.anonymous}>
                        
                        <Button className={classes.anonymousButton} component='span' onClick={toggleReplyAnonymous}>
                            <img src={anonymousImage} alt='abutton' />
                        </Button>

                        <Typography className={classes.caracters}>{strings.replyAnonymously}</Typography>

                    </div>

                    <div>

                        <Typography className={classes.caracters}>{`${message.length}/1024`}</Typography>

                    </div>

                </div>


                <div className={classes.buttons}>

                    <div className={classes.anonymous}>

                        <div>
                            <Button className={classes.cancelButton} onClick={onClose}>
                                {strings.cancelButton}
                            </Button>
                        </div>

                        <div>
                            <Button disabled={disabled} className={classes.sendButton} onClick={onSend}>
                                {strings.sendButton}
                            </Button>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    );

};

export default ReplyDisplay;
