import React from 'react';

// import login from '../../assets/login.svg';
import login from '../../assets/loginu.svg';

import  SignIn from '../../components/sign-in/sign-in.component';

import { useSignInStyles } from './sign-in-page.styles';

const SignInPage = () => {

    const classes = useSignInStyles();

    
    return(
        <div className={classes.signInPage}>

            <img src={login} alt='class.me' className={classes.image} />

            <div className={classes.login}>  
                <SignIn  />
            </div>
            
        </div>
    )
    
};

export default SignInPage;