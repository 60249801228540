import { makeStyles } from '@material-ui/core/styles';

const font = {
    fontFamily: 'Varela Round, sans-serif, Regular',
    color: '#011E23',
    letterSpacing: 0,
    opacity: 1,
};

export const useNewFileEditStyles = makeStyles({
    
    holder: {
        width: 'inherit',
    },

    fileHolder: {
        width: '-webkit-fill-available',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#FCFCFC 0% 0% no-repeat padding-box',
        border: '1px solid #011E23',
        borderRadius: '12px',
        opacity: 1,
        marginTop: '48px',
        padding: '20px',
    },

    addFile: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },

    addButton: {
        ...font,
        fontSize: '12px',
        color: '#011E23',
        opacity: 1,
        borderRadius: '12px',
        textTransform: 'unset',
        paddingBottom: '4px',
        paddingTop: '4px',
        marginTop: '16px',
        marginBottom: '16px',

    },

});

export const imageStyle = { 
    width: '300px',
    height: '225px',
    objectFit: 'cover',
};
