import { COMMON } from './common';

export const REPLY_TO_COMMENT = {

    en: {
        title: 'Reply',
        addFileButton: COMMON['en'].addFileButton,
        selectFile: COMMON['en'].selectFile,
        inputPlaceholder: COMMON['en'].addMessage,
        replyAnonymously: 'Reply Anonymously',
        cancelButton: COMMON['en'].cancel,
        sendButton: 'Send',
    },

    he: {
        title: 'השב',
        addFileButton: COMMON['he'].addFileButton,
        selectFile: COMMON['he'].selectFile,
        inputPlaceholder: COMMON['he'].addMessage,
        replyAnonymously: 'השב בתגובה אנונימית',
        cancelButton: COMMON['he'].cancel,
        sendButton: 'שלח',
    },

};
