import { createSelector } from 'reselect';

const selecNewClassComment = state => state.newClassCommentPage;

export const selectNewClassCommentPageState = createSelector(
    [selecNewClassComment],
    newClassCommentPage => newClassCommentPage.newClassCommentHidden
);

export const selectClassCommentMessage = createSelector(
    [selecNewClassComment],
    newClassCommentPage => newClassCommentPage.classMessage
);