import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, isLoaded, isEmpty, useFirebase } from 'react-redux-firebase';

import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import CommentsIcon from '../../assets/chatBubble.svg';
import anonymousImage from '../../assets/anonymousSmall.svg';

import moment from 'moment';
import Linkify from 'react-linkify';

import PostAttachment from '../common/post-attachment/post-attachment.component';
import Author from '../common/author/author.component';
import ClassHeader from '../classes/class-header.component';
import Voting from './voting.component';
import EditQuestionPage from '../../pages/edit-question-page/edit-question-page.component';
// import DisplayPostTypeIcon from '../common/post-type/display-post-type-icon.component';
import TemporaryDrawer from './drawer.component';

import { COMMON } from '../../localized-strings/common';
import { matchYoutubeUrl, findUrls, matchFirebaseUrl } from '../../utils/url.utils';

import { usePostHeaderStyles, postImageStylesL, authorImageStyle, authorNameStyle, anonymousNameStyle, linkText } from './post-header.styles';
import { usePostHeaderStylesR, postImageStylesR } from './post-header-r.styles';


const PostHeader = ({ classId, post, edit, view, voting, postId, locale }) => {

    // console.log('PostHeader props', classId, post, edit, view, voting, postId);

    const classesL = usePostHeaderStyles();
    const classesR = usePostHeaderStylesR();
    const classes = locale === 'he'? classesR : classesL;

    const postImageStyles = locale === 'he'? postImageStylesR : postImageStylesL;

    const strings = COMMON[locale];

    const [anchorEl, setAnchorEl] = useState(null);

    const [drawer, setDrawer] = useState(false);

    const toggleDrawer = () => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawer(!drawer);
    };

    const firebase = useFirebase();

    const componentDecoratorLink = (href, text, key) => (
        <a href={href} key={key} target='_blank' rel='noopener noreferrer' style={linkText}>
            {/* {text} */}
            {strings.urlHeaderString}
        </a>
    );

    const componentDecorator = (key) => (
        // <>{strings.urlHeaderString}</>
        <span key={key}>{strings.urlHeaderString}</span>
        );

    if (isEmpty(post) && isLoaded(post)) {
        return (
            <div className={classes.loading}>
                <Typography dir='auto' className={classes.loadmessage}>Loading...</Typography>
                <CircularProgress size={30} />
            </div>
        );
    };

    if (isEmpty(post)) {
        return (
            <div className={classes.loading}>
                <Typography dir='auto' className={classes.loadmessage}>No info</Typography>
                <CircularProgress size={30} />
            </div>
        )
    };

    const onPopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const onPopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const attId = post.significantAttachmentId || null;
    const anonymous = post.anonymous || null;
    const results = post.votes || null;
    const type = post.type || null;
    // const dueDate = post.dueDateUTime || null;
    const body = post.body || null;
    const url = post.url || null;

    const urls = findUrls(body);
    // console.log('Urls', urls);
    const urlFromBody = urls && urls[0];
    // console.log('Url', url);

    const combinedUrl = url || urlFromBody

    const isYouTube = combinedUrl?  matchYoutubeUrl(combinedUrl) : null;

    const isFirebase = url? matchFirebaseUrl(url) : null;

    const time = moment(post.createdUTime).format('Do MMMM YYYY, h:mm:ss a'); // ('MMMM Do YYYY, h:mm:ss a')


    return (
        <div className={classes.postHeader}>

            <div className={classes.newContent}>

                <PostAttachment attId={attId} postImageStyles={postImageStyles} withLitebox={true} />

                <div className={classes.subContent}>

                    <div className={classes.space}>

                        <div className={classes.grid}>

                            {
                                type === 'WEB'? (
                                    <div>
                                        {
                                            // matchYoutubeUrl(post.body)? (
                                            // matchYoutubeUrl(url)? (
                                            (isYouTube || isFirebase)? (
                                                <div>
                                                    <Linkify componentDecorator={componentDecorator}>
                                                        <Typography
                                                            dir='auto'
                                                            aria-owns={open ? 'mouse-over-popover' : undefined}
                                                            aria-haspopup="true"
                                                            onMouseEnter={onPopoverOpen}
                                                            onMouseLeave={onPopoverClose}
                                                            noWrap
                                                            className={classes.bodyLink}
                                                            onClick={toggleDrawer()}
                                                        >
                                                            {/* {post.body} */}
                                                            {combinedUrl}
                                                        </Typography>
                                                    </Linkify>

                                                    <TemporaryDrawer
                                                        opend={drawer}
                                                        toggleDrawer={toggleDrawer()}
                                                        body={post.body}
                                                        locale={locale}
                                                        strings={strings}
                                                        url={combinedUrl}
                                                    />
                                                </div>
                                            ) : (
                                                <Linkify componentDecorator={componentDecoratorLink}>
                                                    <Typography
                                                        dir='auto'
                                                        aria-owns={open ? 'mouse-over-popover' : undefined}
                                                        aria-haspopup="true"
                                                        onMouseEnter={onPopoverOpen}
                                                        onMouseLeave={onPopoverClose}
                                                        noWrap
                                                        className={classes.bodyLink}
                                                        onClick={toggleDrawer()}
                                                    >
                                                        {/* {post.body} */}
                                                        {combinedUrl}
                                                    </Typography>
                                                </Linkify>
                                            )
                                        }
                                    </div>
                                ) : (
                                    <Typography
                                        dir='auto'
                                        aria-owns={open ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={onPopoverOpen}
                                        onMouseLeave={onPopoverClose}
                                        noWrap
                                        className={classes.body}
                                    >
                                        {post.body}
                                    </Typography>
                                )
                            }
                            
                            <Popover
                                id="mouse-over-popover"
                                className={classes.popover}
                                classes={{ paper: classes.paper }}
                                open={open}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom', // 'center',
                                    horizontal: 'left', // 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top', //'left', //'center',
                                    horizontal: 'left',
                                }}
                                onClose={onPopoverClose}
                                disableRestoreFocus
                            >
                                <Linkify componentDecorator={componentDecorator}>
                                    <Typography dir='auto' className={classes.title}>{post.body}</Typography>
                                </Linkify>

                            </Popover>
                        </div>

                        <div className={classes.flexAlign}>
                            <Typography noWrap className={classes.time}>{time}</Typography>
                        </div>

                    </div>

                    <div className={classes.space}>

                        <div>
                            <div>
                                {
                                    anonymous ? (
                                        <div>
                                            {
                                                view ? (
                                                    <div>
                                                        <div className={classes.view}>
                                                            <img className={classes.anonIcon} src={anonymousImage} alt='' />
                                                            <Typography className={classes.anonymous}>Anonymous </Typography>
                                                            <Typography className={classes.brackets}>(</Typography>
                                                            <Author
                                                                author={post.author}
                                                                authorImageStyle={authorImageStyle}
                                                                authorNameStyle={anonymousNameStyle}
                                                            />
                                                            <Typography className={classes.brackets}>)</Typography>
                                                        </div>
                                                    </div>
                                                ) : (
                                                        <div className={classes.view}>
                                                            <img className={classes.anonIcon} src={anonymousImage} alt='' />
                                                            <Typography className={classes.anonymous}>Anonymous</Typography>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    ):(
                                        <Author author={post.author} authorImageStyle={authorImageStyle} authorNameStyle={authorNameStyle} />
                                    )
                                }
                            </div>
                            <ClassHeader classId={classId} />
                        </div>

                        <div className={classes.flexBase}>

                            <div className={classes.commentsDisplay}>
                                <img className={classes.commnetsIcon} src={CommentsIcon} alt='comments' />
                                <Typography className={classes.commentsFont}>{post.commentsCount}</Typography>
                            </div>

                            <Voting results={results} voting={voting} postId={postId} locale={locale} />

                            {/* <DisplayPostTypeIcon type={type} dueDate={dueDate} locale={locale} /> */}

                        </div>

                    </div>

                </div>

                <div className={classes.settings}>
                    {
                        // (edit || (post.author === firebase.auth().currentUser.phoneNumber)) ? <EditQuestionPage /> : null
                        //RESTRUCTURE
                        (edit || (post.author === firebase.auth().currentUser.uid)) ? <EditQuestionPage /> : null
                    }
                </div>

            </div>

        </div>
    )

};

const mapStateToProps = (state, ownProps) => {

    // console.log('PostHeader state', state);

    const posts = state.firebase.data.posts;
    const post = posts ? posts[ownProps.postId] : null;
    const user = state.user;
    const currentUser = user ? user.currentUser : null;
    const permissions = currentUser ? currentUser.permissions : null;
    const edit = permissions ? permissions.canEditAll : null;
    const view = permissions ? permissions.canViewAnonymousName : null;
    const votes = state.firebase.data.votes;
    const votesForPost = votes ? votes[ownProps.postId] : null;

    return {
        post: post,
        edit: edit,
        view: view,
        voting: votesForPost,
    }
};

export default compose(
    connect(mapStateToProps),
    firebaseConnect(ownProps => [
        `posts/${ownProps.postId}`,
        `votes/${ownProps.postId}`,
    ]),
)(PostHeader);
