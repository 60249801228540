import { makeStyles } from '@material-ui/core/styles';
import { ListItem, withStyles } from "@material-ui/core";
import { FONT } from '../../utils/font.utils';

import cursor from '../../assets/cursor.svg';

const url = `url('${cursor}'), auto`

export const usePostsStyles = makeStyles({

    select: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    info: {
        ...FONT,
        // fontSize: '20px',
        fontSize: '1.0416666666666665vw',
        // marginTop: '58px',
        marginTop: '5.37037037037037vh',
    },

    loading: {
        display: 'flex',
        width: 'inherit',
        justifyContent: 'center',
        // paddingTop: '24px',
        marginTop: '5.37037037037037vh',
    },

    circular: {
        // maxHeight: '4.166666666666666vw',
        // maxWidth: '4.166666666666666vw',
        height: '4.166666666666666vw',
        width: '4.166666666666666vw',
    },

    posts: {
        height: 'inherit',
        overflowY: 'auto',
    },

    list: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'inherit',
        paddingLeft: '0.625vw',
        paddingRight: '0.625vw',
    },

});

export const StyledPostListItem = withStyles({
    root: {
        // display: 'inline-block',
        // justifyItems: 'center',
        // width: '404px',
        // width: '40px',
        // width: '21.458333333333332vw',
        // width: '21.041666666666668vw',
        width: '100%',
        // height: '104px',
        height: '9.62962962962963vh',
        // marginBottom: '16px',
        marginBottom: '1.4814814814814814vh',
        // marginLeft: '12px',
        // borderRadius: '12px',
        borderRadius: '0.625vw',
        background: '#FFFFFF',
        padding: 'unset',
        boxSizing: 'unset',
        textAlign: 'unset',
        '&$selected': {
            backgroundColor: '#FFFFFF',
            border: '2px solid #26C6DA',
            '&:hover': {
                backgroundColor: '#EEFBFC',
                border: '2px solid #26C6DA',
            },
        },
        '&:hover': {
            backgroundColor: '#EEFBFC',
        },
        opacity: 1,
        cursor: url,
    },
    selected: {}
})(ListItem);