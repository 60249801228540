import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, isLoaded } from 'react-redux-firebase'

import Typography from '@material-ui/core/Typography';

import { useClassHeaderStyles } from './class-header.styles';


const ClassHeader = ({ classHeader, classId }) => {

    // console.log('ClassHeader props', classHeader, classId);

    const classes = useClassHeaderStyles();

    if (!isLoaded(classHeader)) {
        return <Typography dir='auto' className={classes.title}>Loading...</Typography>;
    }
    if (!classHeader) {
        return <Typography dir='auto' className={classes.title}>No active Class</Typography>;
    }
    
    return(
        <div>
            <Typography dir='auto' className={classes.title}>{classHeader.title}</Typography>
        </div>
    )
    
};

const mapStateToProps = (state, ownProps) => {

    // console.log('ClassHeader state', state);

    const activeClasses = state.firebase.data.activeStates;
    const activeClass = activeClasses? activeClasses[ownProps.classId] : null;
    
    return {
        classHeader: activeClass,
    }
};

export default compose(
    connect(mapStateToProps),
    firebaseConnect(ownProps => [
        `activeStates/${ownProps.classId}`
    ]),       
)(ClassHeader);
