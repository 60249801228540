import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';

import Typography from '@material-ui/core/Typography';

import { MISSING } from '../../../utils/const.utils';

import { useAuthorStyles } from './author.styles';


const Author = ({user, author, authorImageStyle, authorNameStyle}) => {

    // console.log('Autor props', user, author, authorImageStyle, authorNameStyle);

    const classes = useAuthorStyles();

    if (isEmpty(user) && isLoaded(user)) {
        return <Typography className={classes.messages}>Loading...</Typography>
    }

    if (isEmpty(user)) {
        return <Typography className={classes.messages}>No Author</Typography>
    }

    const full = `${user.firstName || ''} ${user.lastName || ''}`.trim();
    const name = (full || user.name || author || '').trim();
    const image1 = user.imageUrl || null;
    const image = image1? image1 : MISSING;

    // console.log('Image', image);
    
    return(
        <div className={classes.author} >
            <img src={image} alt='user' style={authorImageStyle}/> 
            <Typography dir='auto' style={authorNameStyle}>{name}</Typography>
        </div>
    )
    
};

const mapStateToProps = (state, ownProps) => {

    // console.log('Author state', state);
    
    const users = state.firebase.data.users;
    const user = users? users[ownProps.author] : null;
    
    return {
        user: user,
    }
};

export default compose(
    connect(mapStateToProps),
    firebaseConnect(ownProps => [
        `users/${ownProps.author}`
    ]),       
)(Author);
