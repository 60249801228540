import { Post, PostByClass } from '../models/domain/post';
import { updateDomain, updateFields, newIdKey } from '../firebase/firebase.utils';


export const createPost = (

    async (firebase, data) => {

        if (!data.classId) {
            console.error('createPost()', 'No classId in', data, 'Aborting creation process');
            return void 0;
        }

        if (!data.idKey) {
            console.warn('createPost()', 'No postId in', data, 'Creating new...');
            // const newPostIdKey = newIdKey(firebase);
            // return newPostIdKey;
        }

        if (!data.body) {
            console.error('createPost()', 'no Question in', data, 'Aborting creation process');
            return void 0;
        }

        const postId = data.idKey || newIdKey(firebase);
        const post = Post.fromNew({ ...data, idKey: postId });

        await updateDomain(firebase, Post, post);
        await updateFields(firebase, PostByClass.path(post.classId, postId), post);

        return post;

    }

);
