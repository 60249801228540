import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';

export const useCommentsStylesR = makeStyles({

    noComments: {
        display: 'flex',
        flexDirection: 'column', 
        alignItems: 'center',
        justifyContent: 'center', 
        // height: '-webkit-fill-available',
        height: '100%',
    },

    messages: {
        ...FONT,
        fontSize: '0.8333333333333334vw',
    },

    commentsHolder: {
        height: '100%',
    },

    comments: {
        display: 'flex',
        flexDirection: 'column',
        // height: '-webkit-fill-available',
        height: '100%',
        overflowY: 'auto',
        // paddingLeft: '60px',
        // paddingLeft: '3.125vw',
        paddingRight: '3.125vw',
        // paddingRight: '120px',
        // paddingRight: '6.25vw',
        paddingLeft: '6.25vw',
    },

    button: {
        // bottom: '193px', //'133px',
        // bottom: '1.1111111111111112vh',
        bottom: '4.814814814814815vh',
        // right: '40px',
        // right: '2.083333333333333vw',
        right: '-2.083333333333333vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // position: 'absolute',
        position: 'relative',

        // width: '2.083333333333333vw',
        // height: '2.083333333333333vw',
    },

    iconButton: {
        background: '#E8E9EB 0% 0% no-repeat padding-box',
        opacity: 1,
        // width: '40px',
        width: '2.083333333333333vw',
        // height: '40px',
        height: '2.083333333333333vw',
        padding: 'unset',

        // position: 'relative',
        // bottom: '40px',
        // right: '40px',
    },

    icon:{
        fontSize: '2.083333333333333vw',
    },

});
