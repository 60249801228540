import { NewCommentAttachActionTypes } from './new-comment.types';

export const toggleNewCommentAtttachPageHidden = () => ({
    type: NewCommentAttachActionTypes.TOGGLE_COMMENT_ATTACH_PAGE_HIDDEN
});

export const toggleCommentAnonymous = () => ({
    type: NewCommentAttachActionTypes.TOGGLE_COMMENT_ANONYMOUS
});

export const commentMessage = message => ({
    type: NewCommentAttachActionTypes.COMMENT_MESSAGE,
    payload: message,
});

export const onCommentAttachment = file => ({
    type: NewCommentAttachActionTypes.COMMENT_ATTACH,
    payload: file,
});