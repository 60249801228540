import { uploadClassImage }  from '../../crud/upload-class-image';
import { updateFields } from '../../firebase/firebase.utils'; 
import { Class } from '../../models/domain/class';

export const onUpdateClassImage = async(firebase, data, classId) => {


    console.log('onUpdateClassImage() > ()', classId, ': ', data);

    try {

        if(!data) {
            console.log('No image! Skkipping upload ...');
            return void 0;
        }

        const updata = await uploadClassImage(firebase, data, classId);
        console.log('onUpdateClassImage() > try{}', 'Classroom image uploaded: ', updata);

        const now = firebase.database.ServerValue.TIMESTAMP;
        const path = `${classId}`;

        await updateFields(firebase, Class.path(path), {
            imageUpdatedUTime: now,
            imageUrl: updata.url,
            images: {
                originalUrl: updata.url,
                thumbnailUrl: updata.url,
            },
            updatedUTime: now
        });

    } catch (e) {

        console.error('onUpdateClassImage() > catch{}', e);

    }

};
