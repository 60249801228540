import React from 'react';

import Input from '@material-ui/core/Input';

import { useAnonymousQuestionInputStyles } from './anonymous-question-input.styles';

const AnonymousQuestionInput = ({ onChange, question, strings, locale }) => {

    const classes = useAnonymousQuestionInputStyles();

    const direction = locale === 'he'? 'rtl' : 'auto';

    return (

        <div className={classes.inputHolder}>
            <Input
                multiline
                className={classes.input}
                fullWidth
                disableUnderline
                placeholder={strings.inputPlaceholder}
                inputProps={{
                    'aria-label': 'description',
                    maxLength: 1024,
                    dir: {direction},
                }}
                onChange={onChange}
                value={question}
            />
        </div>

    );
};

export default AnonymousQuestionInput;
