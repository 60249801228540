import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';

export const useClassHeaderStyles = makeStyles({

    title: {
        ...FONT,
        fontSize: '0.8333333333333334vw',
    }

});