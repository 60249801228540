import { ClassroomSettingsActionTypes } from './classroom-settings.types';

export const openClassromSettingsPage = onClass => ({
    type: ClassroomSettingsActionTypes.OPEN_CLASSROOM_SETTINGS_PAGE,
    payload: onClass,
});

export const closeClassromSettingsPage = () => ({
    type: ClassroomSettingsActionTypes.CLOSE_CLASSROOM_SETTINGS_PAGE,
});
