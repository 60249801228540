import React from 'react';

import Button from '@material-ui/core/Button';

import camera from '../../assets/camera.svg';
import { FilePick } from '../common/files-input/files.pick.component';

import { useImageInputAndDisplayAnonymousStyles, imageStyle } from './anonymous-image-input-and-disply.styles';

const AnonymousImageInputAndDisply = ({ file, imagePreviewUrl, onFile }) => {

    const classes = useImageInputAndDisplayAnonymousStyles();

    return (

        <div dir='ltr'>

            {
                file ?
                    (
                        <div className={classes.imageHolder}>
                            <img style={imageStyle} src={imagePreviewUrl} alt="img preview" />
                        </div>
                    ) :
                    (
                        <div className={classes.holder}>
                            <FilePick
                                accept="image/*"
                                onPick={onFile}
                                content={() =>
                                    (
                                        <Button className={classes.imageButton} component="span">
                                            <img src={camera} alt='button' />
                                        </Button>
                                    )
                                }
                            />
                        </div>
                    )
            }

        </div>
    );

};

export default AnonymousImageInputAndDisply;
