import { COMMON } from './common';

export const DELETE_COMMENT = {

    en: {
        title: 'Delete comment',
        deleteMessage: 'Are you sure you want to delete this comment?',
        cancelButton: COMMON['en'].cancel,
        deleteButton: 'Delete',
    },

    he: {
        title: 'מחק תגובה',
        deleteMessage: 'התגובה תימחק עבור כולם. האם אתה בטוח?',
        cancelButton: COMMON['he'].cancel,
        deleteButton: 'מחק תגובה',
    },

};
