import { uploadPostImage } from '../../crud/upload-post-image';
import { createPostAttachment } from '../../crud/create-post-attachment'
import { createPost } from '../../crud/create-new-question';
import { createPostFirestore } from '../../crud/create-new-question-firestore';
import { trackCoool } from '../../utils/cooladata.utils';
import { trackFirebase } from '../../utils/firebase-analytics.utils';
import { findUrls } from '../../utils/url.utils';
import { FIREBASESTORAGE } from '../../utils/const.utils';


export const onCreateQuestion = async(firebase, data) => {

    // withHandlers may have problems with async functions, so using IIFE instead

    console.log('onCreateQuestion() > ()', data);

    const anonymous = (data.anonymous === false)? null : true;

    const substrings = data.question.split('\n') || null;
    // console.log('Substrings', substrings);
    // var firebaseUrl = substrings.find(a => a.includes('https://firebasestorage.googleapis.com/v0/b/classme-150316.appspot.com/o/videos'));
    var firebaseUrl = substrings.find(a => a.includes(FIREBASESTORAGE));
    // console.log('Firebase Url', firebaseUrl);

    const urls = findUrls(data.question);
    // console.log('Urls', urls);
    const otherUrl = urls && urls[0];
    // console.log('Other Url', otherUrl);

    const url = firebaseUrl ? firebaseUrl : otherUrl;
    // console.log('Url', url);

    // const type = isURL(data.question)? 'WEB' : data.type;
    const type = url? 'WEB' : data.type;
    // console.log('TYPE', type);

    // const question = url? url : data.question;
    // console.log('Question', question);

    try {

        if(!data.file) {
            console.log('No file for the question! Skkipping upload and creating question...');

            const post = await createPost(firebase, {
                // idKey: attachment.post,
                author: data.user,
                body: data.question,
                classId: data.classId,
                createdUTime: firebase.database.ServerValue.TIMESTAMP,
                lastUpdatedUTime: firebase.database.ServerValue.TIMESTAMP,

                anonymous: anonymous,
                type: type,
                dueDateUTime: data.selectedDate,
                url: url,

            });

            const evetParams = {
                user_id: post.author,
                class_Id: post.classId,
                post_id: post.idKey,
                post_body: post.body,
                post_type: post.type,
                has_attachment: false
            }
            
            console.log('onCreateQuestion() > try{}', 'Question ctreated: ', post);

            trackCoool('post_created', evetParams);
            trackFirebase('post_created', evetParams);

            const postFirestore = await createPostFirestore({
                idKey: post.idKey,
                author: data.user,
                body: data.question,
                classId: data.classId,

                anonymous: anonymous,
                type: type,
                dueDateUTime: data.selectedDate,
                url: url,
            }); 

            console.log('onCreateQuestion() Firestore > try{}', 'Question ctreated: ', postFirestore);

            return void 0;
        }

        const updata = await uploadPostImage(firebase, data.file);
        console.log('onCreateQuestion() > try{}', 'Question image uploaded: ', updata);

        // const now = firebase.database.ServerValue.TIMESTAMP;

        const dataForAttachment = {author: data.user, ...updata}
        console.log('Data for creating attachment: ', dataForAttachment);

        const attachment = await createPostAttachment(firebase, dataForAttachment);
        console.log('onCreateQuestion() > try{}', 'Question attachment created: ', attachment);

        const post = await createPost(firebase, {
            idKey: attachment.post,
            author: data.user,
            body: data.question,
            classId: data.classId,
            createdUTime: firebase.database.ServerValue.TIMESTAMP,
            lastUpdatedUTime: firebase.database.ServerValue.TIMESTAMP,
            significantAttachmentId: attachment.idKey,

            anonymous: anonymous,
            type: type,
            dueDateUTime: data.selectedDate,
            url: url,
        });

        const evetParams = {
            user_id: post.author,
            class_Id: post.classId,
            post_id: post.idKey,
            post_body: post.body,
            post_type: post.type,
            has_attachment: true
        }
        
        console.log('onCreateQuestion() > try{}', 'Question ctreated: ', post);

        trackCoool('post_created', evetParams);
        trackFirebase('post_created', evetParams);

        const postFirestore = await createPostFirestore({
            idKey: attachment.post,
            author: data.user,
            body: data.question,
            classId: data.classId,
            significantAttachmentId: attachment.idKey,

            anonymous: anonymous,
            type: type,
            dueDateUTime: data.selectedDate,
            url: url,
        })

        console.log('onCreateQuestion() Firestore > try{}', 'Question ctreated: ', postFirestore);

    } catch (e) {

        console.warn('onCreateQuestion() > catch{}', e);

        trackCoool('post_created', {error: e});
        trackFirebase('post_created', {error: e});

    }

};
