import React from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

import camera from '../../assets/camera.svg';

import { FilePick } from '../common/files-input/files.pick.component';

import { EDIT_CLASSROOM } from '../../localized-strings/edit-classroom';

import { useEditClassroomStyles, imageStyle } from './edit-classroom-display.styles';


const EditClassroomDisplay = ({ file, onFile, imagePreviewUrl, oldImageUrl, onChange, 
    title, onCancel, disabled, onConfirm, locale }) => {

    // console.log('Edit Classroom Display props', file, imagePreviewUrl, oldImageUrl, title, disabled, locale);

    const classes = useEditClassroomStyles();

    const strings = EDIT_CLASSROOM[locale];

    const popUpdir = locale === 'he'? 'rtl' : 'ltr';
    const direction = locale === 'he'? 'rtl' : 'auto';
    

    return (

        <div dir={popUpdir} className={classes.editClassroom}>

            <Typography dir='auto' className={classes.title}>{strings.title}</Typography>

            <Divider className={classes.divider} />

            <div className={classes.inputsHolder}>

                <div dir='ltr'>
                    {
                        file ?
                            (
                                <div className={classes.imageHolder}>

                                    <img style={imageStyle} src={imagePreviewUrl} alt="img preview" />

                                    <FilePick
                                        accept="image/*"
                                        onPick={onFile}
                                        content={() =>
                                            (
                                                <div className={classes.iBbutton}>
                                                    <Button className={classes.imageButton} component="span">
                                                        <img src={camera} alt='button' />
                                                    </Button>
                                                </div>
                                            )
                                        }
                                    />

                                </div>
                            ) :
                            (
                                <div>
                                    {
                                        oldImageUrl ?
                                            (
                                                <div className={classes.imageHolder}>

                                                    <img style={imageStyle} src={oldImageUrl} alt="img preview" />

                                                    <FilePick
                                                        accept="image/*"
                                                        onPick={onFile}
                                                        content={() =>
                                                            (
                                                                <div className={classes.iBbutton}>
                                                                    <Button className={classes.imageButton} component="span">
                                                                        <img src={camera} alt='button' />
                                                                    </Button>
                                                                </div>
                                                            )
                                                        }
                                                    />

                                                </div>
                                            ) : (
                                                <div className={classes.holder}>
                                                    <FilePick
                                                        accept="image/*"
                                                        onPick={onFile}
                                                        content={() =>
                                                            (
                                                                <Button className={classes.imageButton} component="span">
                                                                    <img src={camera} alt='button' />
                                                                </Button>
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )
                                    }
                                </div>
                            )
                    }
                </div>

                <div className={classes.inputHolder}>
                    <Input
                        className={classes.input}
                        fullWidth
                        disableUnderline
                        placeholder={strings.writeTitle}
                        inputProps={{
                            'aria-label': 'first-name',
                            dir: {direction},
                            maxLength: 30,
                        }}
                        onChange={onChange}
                        value={title}
                        required
                    />
                </div>

                <div className={classes.caractersHolder}>
                    <Typography className={classes.caracters}>{`${title.length}/30`}</Typography>
                </div>

                <div className={classes.buttons}>

                    <div>
                        <Button className={classes.cancelButton} onClick={onCancel}>
                            {strings.cancelButton}
                        </Button>
                    </div>

                    <div>
                        <Button disabled={disabled} className={classes.saveButton} onClick={onConfirm}>
                            {strings.saveButton}
                        </Button>
                    </div>

                </div>

            </div>

        </div>

    )

};

export default EditClassroomDisplay;
