import { makeStyles } from '@material-ui/core/styles';
import { ListItem, withStyles } from "@material-ui/core";
import { FONT } from '../../utils/font.utils';

import cursor from '../../assets/cursor.svg';

const url = `url('${cursor}'), auto`

export const useClassroomsStyles = makeStyles({

    loading: {
        display: 'flex',
        // alignItems: 'flex-start',
        // justifyContent: 'center',
        // width: 'inherit',
        // paddingTop: '48px',
        // paddingTop: '4.444444444444445vh',
        marginTop: '4.444444444444445vh',
        
    },

    circular: {
        maxWidth: '3.125vw',
        maxHeight: '3.125vw',
    },

    noClassrooms: { 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    no: {
        display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        // width: 'inherit',
        // paddingTop: '48px',
        paddingTop: '4.444444444444445vh',
        maxWidth: '5.208333333333334vw',
        // height: '-webkit-fill-available',
    },

    noMessage: {
        ...FONT,
        fontSize: '0.8333333333333334vw',
    },

    addClassromButton: {
        display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        marginTop: '1.1111111111111112vh',
        marginBottom: '1.1111111111111112vh',
    },

    flex: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },

    classroomsComponent: {
        width: '100%',
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    classrooms: {
        width: 'inherit',
        overflowY: 'auto',

        // '&::-webkit-scrollbar': {
        //     // display: 'none',
        //     width: '6px',
        //     backgroundColor:'transparent',
        // },
        // '&::-webkit-scrollbar-thumb': {
        //     '&:hover': {
        //         backgroundColor: 'red'
        //     },
        //     backgroundColor: '#acacac'
        // }

        // div * {
        //     scrollbar-width: none; /* Firefox */
        //     -ms-overflow-style: none; /* IE 10+ */
        //     ::-webkit-scrollbar-track {
        //       -webkit-box-shadow: none !important;
        //       background-color: transparent;
        //     }
        //     ::-webkit-scrollbar {
        //       width: 3px !important;
        //       background-color: transparent;
        //     }
        //     ::-webkit-scrollbar-thumb {
        //       background-color: transparent;
        //     }
        //   }
    },

    list: {
        padding: 'unset',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

});

export const StyledClassroomListItem = withStyles({
    root: {
        display: 'inline-block',
        justifyItems: 'center',
        // width: '60px',
        width: '3.125vw',
        // height: '60px',
        height: '3.125vw',
        // marginBottom: '8px',
        marginBottom: '0.7407407407407407vh',

        // marginLeft: '12px', // 24

        // marginTop: '8px',
        marginTop: '0.7407407407407407vh',
        // borderRadius: '12px',
        borderRadius: '0.625vw',
        background: 'unset',
        padding: 'unset',
        boxSizing: 'unset',
        textAlign: 'unset',
        transition: 'unset',
        border: '2px solid transparent',
        '&$selected': {
            // borderRadius: '12px',
            borderRadius: '0.625vw',
            border: '2px solid #FFE708',
        },
        '&:hover': {
            // borderRadius: '12px',
            borderRadius: '0.625vw',
            border: '2px solid #FFE708',
        },
        opacity: 1,
        cursor: url,
    },
    selected: {},
})(ListItem);