import { makeStyles } from '@material-ui/core/styles';

import cursor from '../../assets/cursor.svg';

const url = `url('${cursor}'), auto`;

const font = {
    fontFamily: 'Varela Round, sans-serif, Regular',
    color: '#011E23',
    letterSpacing: 0,
    opacity: 1,
};

export const useReplyDisplayStylesR = makeStyles({
    
    replyDisplay: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    title: {
        ...font,
        fontSize: '24px',
        lineHeight: '29px',
        marginBottom: '24px',
        marginTop: '24px',
    },

    divider: {
        width: '100%',
    },

    inputsHolder: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '552px',
        paddingLeft: '44px',
        paddingRight: '44px',
        paddingBottom: '64px',
    },

    inputHolder: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '-webkit-fill-available',
        height: '372px',
        background: '#FCFCFC 0% 0% no-repeat padding-box',
        border: '1px solid #011E23',
        borderRadius: '12px',
        opacity: 1,
        marginBottom: '8px',
        overflow: 'auto',
        padding: '20px',
    },

    input: {
        ...font,
        fontSize: '17px',
        lineHeight: '21px',
        height: 'inherit',
        alignItems: 'flex-start',
    },

    caractersHolder: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '64px',
        width: '552px',
        justifyContent: 'space-between',
    },

    anonymous: {
        display: 'flex',
        alignItems: 'center',
    },
    
    anonymousButton: {
        background: '#FBFBFB 0% 0% no-repeat padding-box',
        borderRadius: '6px',
        opacity: 1,
        // marginRight: '12px',
        marginLeft: '12px',
        minWidth: 'unset',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#EEFBFC',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        cursor: url,
    },

    caracters: {
        ...font,
        fontSize: '12px',
        color: '#A9AAAB',
    },

    buttons: {
        width: 'inherit',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },

    cancelButton: {
        ...font,
        fontSize: '16px',
        color: '#011E23',
        opacity: 1,
        borderRadius: '12px',
        width: '132px',
        textTransform: 'unset',
    },

    sendButton: {
        ...font,
        fontSize: '16px',
        color: '#FFFFFF',
        background: '#26C6DA 0% 0% no-repeat padding-box',
        borderRadius: '12px',
        opacity: 1,
        textTransform: 'unset',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#26C6DA',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        width: '132px'
    },

});
