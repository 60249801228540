import { createUser } from '../../crud/create-new-user';
import { uploadUserImage } from '../../crud/upload-user-image';

import { trackCoool } from '../../utils/cooladata.utils';
import { trackFirebase } from '../../utils/firebase-analytics.utils';


export const onCreateNewUser = async(firebase, data) => {

    // withHandlers may have problems with async functions, so using IIFE instead

    console.log('onCreateNewUser() > ()', data);

    var info = {
        browserLanguage: navigator.language,
        browserPlatform: navigator.platform,
        nAgt: navigator.userAgent,
    };
    // console.log(info);
    const lan = info.browserLanguage;

    // const language = lan.substring(lan.indexOf('-') + 1);
    const language = lan.split("-")[0];
    // console.log(language)

    try {

        if(!data.file) {
            console.log('No image for the user! Skkipping upload and creating user...');

            const newUser = await createUser(firebase, {
                idKey: data.idKey,
                firstName: data.firstName,
                lastName: data.lastName,
                name: `${data.firstName || ''} ${data.lastName || ''}`.trim(),
                updatedUTime: firebase.database.ServerValue.TIMESTAMP,

                language: language,
                osVersion: info.browserPlatform,
                deviceModel: info.nAgt,
                version: 'v.09',
            });
    
            console.log('onCreateNewUser() > try{}', 'User ctreated', newUser);

            const eventParametars = {
                user_name: newUser.name,
                device_id: newUser.deviceModel,
                phone_number: newUser.idKey,
                user_id: newUser.idKey,
            };

            trackCoool('sign_up', eventParametars);
            trackFirebase('sign_up', eventParametars);

            return void 0;
        }

        const updata = await uploadUserImage(firebase, data.file, data.idKey);
        console.log('onCreateNewUser() > try{}', 'Image uploaded', updata);

        const url = updata.url;
        const updated = Date.parse(updata.snapshot.metadata.updated);
        // console.log(url, updated);

        const newUser = await createUser(firebase, {
            idKey: data.idKey,
            firstName: data.firstName,
            lastName: data.lastName,
            name: `${data.firstName || ''} ${data.lastName || ''}`.trim(),
            images: {
                originalUrl: url,
                thumbnailUrl: url,
            },
            imageUpdatedUTime: updated,
            imageUrl: url,
            updatedUTime: firebase.database.ServerValue.TIMESTAMP,

            language: language,
            osVersion: info.browserPlatform,
            deviceModel: info.nAgt,
            version: 'v.09',
        });
        
        console.log('onCreateNewUser() > try{}', 'User ctreated', newUser);

        const eventParametars = {
            user_name: newUser.name,
            device_id: newUser.deviceModel,
            phone_number: newUser.idKey,
            user_id: newUser.idKey,
            user_avatar: newUser.imageUrl,
        };

        trackCoool('sign_up', eventParametars);
        trackFirebase('sign_up', eventParametars);

    } catch (e) {

        console.warn('onCreateNewUser() > catch{}', e);

        trackCoool('sign_up', {error: e});
        trackFirebase('sign_up', {error: e});

    }

};
