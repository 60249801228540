import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

import moment from 'moment';

import NewQuestionDisplay from './new-question-display.component';
import AnonymousNewQuestionDisplay from './anonymous-new-question-display.component';

import { usePickedPhoto } from '../../utils/files/picked-photo.utils';
import { getCurrentUserId } from '../../firebase/user.utils';

import { NEW_QUESTION } from '../../localized-strings/new-question';

import { toggleNewQuestionPageHidden } from '../../redux/new-question/new-question.actions';

import { onCreateQuestion } from './on-create-new-question-handler';


const NewQuestion = ({ toggleNewQuestionPageHidden, match, locale }) => {

    const firebase = useFirebase()

    const [question, setQuestion] = useState('');
    const [file, setFile] = useState(null);
    const [anonymous, setAnonymous] = useState(false);
    const [type, setType] = useState('QUESTION');
    const [selectedDate, setSelectedDate] = useState(null);

    const imagePreviewUrl = usePickedPhoto(file);

    const strings = NEW_QUESTION[locale];

    const onChange = ev => {
        const { value } = ev.target;
        if(value[0] === ' '){
            setQuestion('');
        }else{
            setQuestion(value);
        }
    };

    const onFile = files => {
        const file = files && files[0];
        // console.log(file);
        setFile(file)
    };

    const onAnonymous = () => {
        setAnonymous(!anonymous);
    };

    const onType = ev => {
        setType(ev.target.value);
    };

    const onDateChange = date => {
        // TO UTC
        setSelectedDate(moment(new Date(date)).valueOf());
        
    };

    const onClose = () => {
        toggleNewQuestionPageHidden();
    }

    const onCreate = () => {

        const currentUserId = getCurrentUserId(firebase);
        // console.log('Curren User Id', currentUserId);

        const data = {
            question,
            file,
            user: currentUserId,
            classId: match.params.classId,
            anonymous,
            type,
            selectedDate,
        };

        onCreateQuestion(firebase, data);

        toggleNewQuestionPageHidden();

        setFile(null);
        setType('QUESTION');
        setSelectedDate(null);
        setQuestion('');
    };
    
    const disabled = question? false : true;
    const disabledWithdate = (selectedDate && question)? false : true;


    return (  
        <div>
            {
                anonymous ? (
                    <AnonymousNewQuestionDisplay
                        file={file}
                        imagePreviewUrl={imagePreviewUrl}
                        onFile={onFile}
                        type={type}
                        onType={onType}
                        selectedDate={selectedDate}
                        onDateChange={onDateChange}
                        onChange={onChange}
                        question={question}
                        onAnonymous={onAnonymous}
                        disabled={disabled}
                        disabledWithdate={disabledWithdate}
                        onCreate={onCreate}
                        onClose={onClose}
                        strings={strings}
                        locale={locale}
                    />
                ) : (
                    <NewQuestionDisplay
                        file={file}
                        imagePreviewUrl={imagePreviewUrl}
                        onFile={onFile}
                        type={type}
                        onType={onType}
                        selectedDate={selectedDate}
                        onDateChange={onDateChange}
                        onChange={onChange}
                        question={question}
                        onAnonymous={onAnonymous}
                        disabled={disabled}
                        disabledWithdate={disabledWithdate}
                        onCreate={onCreate}
                        onClose={onClose}
                        strings={strings}
                        locale={locale}
                    />
                )
            }
        </div>
    )
};

const mapDispatchToProps = dispatch => ({
    toggleNewQuestionPageHidden: () => dispatch(toggleNewQuestionPageHidden())
});

export default withRouter(connect(null, mapDispatchToProps)(NewQuestion));
