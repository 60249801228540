import { useState } from 'react';


 export const usePickedPhoto = file => {

    const [imagePreviewUrl, setImagePreviewUrl] = useState('');

    if (file) {

        const reader = new FileReader();
        // const encodedFile = reader.readAsDataURL(originalFile);
        reader.readAsDataURL(file);

        reader.onload = () => {
            setImagePreviewUrl(reader.result)
        };

        reader.onerror = error => console.warn('reader', error);
       
    }

    return imagePreviewUrl

};