import { User } from './user';
import { Class } from './class';
import { Attachment } from './attachment';
import { No } from '../models-core';
// import {nowTimestamp$} from '../../utils/date.utils';
import { empty } from '../../utils/string.utils';


export class Post {

    static name() {
        return 'posts';
    }

    static path(idKey) {

        const name = Post.name();
        return empty(idKey) ? `/${name}` : `/${name}/${idKey}`;
    }


    static field(idKey, name) {
        return `${Post.path(idKey)}/${name}`;
    }


    static empty() {

        return {

            idKey: No.Id,
            author: No.String,
            body: No.String,
            classId: No.Id,
            // commentsCount:    No.Count,
            // createdUTime: No.Timestamp,
            // dueDateUTime: No.Timestamp,
            // lastUpdatedUTime: No.Timestamp,
            // significantAttachmentId: No.Id,
            // type: 'QUESTION',
            anonymous: {},
        };

    }


    static fromEmpty(merge) {
        return { ...Post.empty(), ...merge };
    }


    static fromNew(merge) {

        // const now = nowTimestamp$();
        // const now = firebase.database.ServerValue.TIMESTAMP;

        // return Post.fromEmpty({
        //     createdUTime: now,
        //     lastUpdatedUTime: now,
        //     ...merge,
        // });

        return Post.fromEmpty(merge);

    }


    static populates() {
        return [
            {
                child: 'author',
                childAlias: 'authorItem',
                root: User.path(),
            },
            {
                child: 'classId',
                childAlias: 'classItem',
                root: Class.path(),
            },
            {
                child: 'significantAttachmentId',
                childAlias: 'attachmentItem',
                root: Attachment.path(),
            },
        ];
    }

}


export class PostByClass extends Post {

    // static name() {
    //     return 'postsByClass';
    // }


    static path(classId, postId) {
        return empty(classId || postId) ? '/postsByClass/unknown/unknown' : `/postsByClass/${classId}/${postId}`;
    }


    // static field(classId, postId, name) {
    //     return `${PostByClass.path(classId, postId)}/${name}`;
    // }

}


