import { COMMON } from './common';

export const JOIN = {

    en: {
        title: 'Join a UniMastery Channel',
        joinMessage: 'You have been invited to join this channel!',
        cancelButton: COMMON['en'].cancel,
        joinButton: 'Join',
    },

    he: {
        title: 'הצטרף לערוץ של UniMastery',
        joinMessage: 'קיבלת הזמנה להצטרפות לערוץ של UniMastery!',
        cancelButton: COMMON['he'].cancel,
        joinButton: 'הצטרף',
    },

};
