import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import LeaveClassroom from '../../components/leave-classrom/leave-classroom.component';
import CustomModal from '../../components/common/custom-modal/custom-modal.component';

import { selectVisible, selectOnClass } from '../../redux/leave-classroom/leave-classroom.selector';
import { selectLocale } from '../../redux/localization/localization.selector';
import { closeLeaveClassroomPage } from '../../redux/leave-classroom/leave-classroom.actions';


const LeaveClassroomPage = ({ classroom, visible, closeLeaveClassroomPage, locale }) => {

    // console.log('Class Settings Page props', classId, visible, closeClassromSettingsPage, selectVisible)

    return (
        <div>
            <CustomModal 
                open={visible}
                onClose={closeLeaveClassroomPage}
                content={<LeaveClassroom classroom={classroom} locale={locale} />}
            />
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    visible: selectVisible,
    classroom: selectOnClass,
    locale: selectLocale,
});

const mapDispatchToProps = dispatch => ({
    closeLeaveClassroomPage: () => dispatch(closeLeaveClassroomPage())
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveClassroomPage);
