import { updateFields } from '../../firebase/firebase.utils'; 
import { User } from '../../models/domain/user';
import { getCurrentUserId } from '../../firebase/user.utils';
import { trackCoool } from '../../utils/cooladata.utils';
import { trackFirebase } from '../../utils/firebase-analytics.utils';

export const onUpdateUserName = async(firebase, data) => {


    console.log('onUpdateUserName() > ()', data);
    
    const currentUserId = getCurrentUserId(firebase);
    // console.log('Curren User Id', currentUserId);

    try {

        if(data === undefined || Object.keys(data).length === 0) {
            console.log('No Data for Name! Skkipping updating ...');
            return void 0;
        }

        const now = firebase.database.ServerValue.TIMESTAMP;
        const path = `${currentUserId}`;

        await updateFields(firebase, User.path(path), {
            firstName: data.firstName,
            lastName: data.lastName,
            name: `${data.firstName || ''} ${data.lastName || ''}`.trim(),
            updatedUTime: now
        });

        const eventParametars = {
            user_id: currentUserId,
            new_user_first_name: data.firstName,
            new_user_last_name: data.lastName,
        }

        trackCoool('edit_user_details', eventParametars);
        trackFirebase('edit_user_details', eventParametars);

    } catch (e) {

        console.error('onUpdateUserName() > catch{}', e);

        trackCoool('edit_user_details', {user_id: currentUserId, error: e});
        trackFirebase('edit_user_details', {user_id: currentUserId, error: e});

    }

};
