import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/functions';

import { firebaseConfig } from '../config/keys';

import { empty } from '../utils/string.utils';


export const createUserProfileDocument = async ( userAuth, additionalData ) => {
    
    if(!userAuth) return;

    // const userRef = firestore.doc(`users/${userAuth.uid}`);
    // OLD STRUCTURE WITH PHONE NUMBER
    // const userRef = database.ref(`users/${userAuth.phoneNumber}`);

    // RESTRUCTURE WITH UID
    // console.log('USER AUTH', userAuth);
    const userRef = database.ref(`users/${userAuth.uid}`);
    // const snapShot = await userRef.once('value');

    // console.log(snapShot)

    // if(!snapShot.exists) {
    //     const {displayName, email} = userAuth;
    //     const createdAt = new Date();

    //     try {
    //         await userRef.set({
    //             displayName,
    //             email,
    //             createdAt,
    //             ...additionalData
    //         })
    //     } catch (error) {
    //         console.log('error creating user', error.message)
    //     }
        
    //}

    return userRef;

};





// var userId = firebase.auth().currentUser.uid;
// return firebase.database().ref('/users/' + userId).once('value').then(function(snapshot) {
//   var username = (snapshot.val() && snapshot.val().username) || 'Anonymous';
//   // ...
// });

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const database = firebase.database();
export const firestore = firebase.firestore();
export const analytics = firebase.analytics();
export const functions = firebase.functions();

export const newIdKey = (firebase => firebase.push().getKey());

export const updateDomain = (firebase, domain, data) => {

        const idKey = (

            empty(data.idKey)
                ? newIdKey(firebase)
                : data.idKey

        );

        const path = domain.path(idKey);


        console.log('updateDomain()', 'updating', path, 'with', data, '...');

        const promise = firebase.update(path, {...data, idKey});

        promise.then(
            () => console.log('updateDomain()', 'updated', path, 'with', data, '.'),
        ).catch(
            e => console.error('updateDomain()', 'updating', path, 'with', data, 'had an error', e),
        );

        return promise;

};

export const updatePostsCollection = (data) => {

        // const idKey = (

        //     empty(data.idKey)
        //         ? newIdKey(firebase)
        //         : data.idKey

        // );

        // const path = domain.path(idKey);


        console.log('updatePostsCollection()', 'updating', `posts/${data.idKey}`, 'with', data, '...');

        const promise = firestore.collection('posts').doc(`${data.idKey}`).set({data}, { merge: true });

        promise.then(
            () => console.log('updatePostsCollection()', 'updated', `posts/${data.idKey}`, 'with', data, '.'),
        ).catch(
            e => console.error('updatePostsCollection()', 'updating', `posts/${data.idKey}`, 'with', data, 'had an error', e),
        );

        return promise;

};

export const updateFields = (firebase, path, data) => {

        console.log('updateFields()', 'updating', path, 'with', data, '...');

        const promise = firebase.update(path, data);

        promise.then(
            () => console.log('updateFields()', 'updated', path, 'with', data, '.'),
        ).catch(
            e => console.error('updateFields()', 'problem', e, 'updating', path, 'with', data, '!'),
        );

        return promise;

};


// const provider = new firebase.auth.GoogleAuthProvider();
// provider.setCustomParameters({promt: 'select_account'});

// export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;
