import { makeStyles } from '@material-ui/core/styles';

import pattern from '../../assets/pattern.svg';

const url = `url('${pattern}')`;

export const useSignInStyles = makeStyles({
    
    signInPage: {
        margin: 'auto', 
        display: 'flex', 
        height: '100vh',
        background: url,
        backgroundColor: '#f1f5f9',
    },

    image: {
        objectFit: 'cover',
        height: '100%'
    },

    login: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    
});