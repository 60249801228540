import { makeStyles } from '@material-ui/core/styles';

export const useLoggedOutLinksStyle = makeStyles({

    loggedOutLinks: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    grow: {
        flexGrow: 1,
    },

    loginButton: {
        fontFamily: 'Varela Round, sans-serif, Regular',
        color: '#26C6DA',
        textTransform: 'capitalize',
        fontSize: '18px',
        fontWeight: 600,
    }

});