import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';

export const useClassDispalyStylesR = makeStyles(theme => ({

    image: {
        // width: '60px',
        width: '3.125vw',
        // height: '60px',
        height: '3.125vw',
        // borderRadius: '12px',
        borderRadius: '0.625vw',
        objectFit: 'cover', 
    },

    // TO DO IF HAVE

    popover: {
        pointerEvents: 'none',
    },

    paper: {
        background: '#011E23',
        boxShadow: '0px 3px 6px #014A4C29',
        padding: theme.spacing(1),
        marginLeft: '-12px',
    },

    title: {
        ...FONT,
        color: '#FFFFFF',
        fontSize: '12px',
    },

    leave: {
        color: '#DA2626',
    },

}));