import React from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import { JOIN } from '../../localized-strings/join';

import { useJoinStyles } from './join-styles';
import { useJoinStylesR } from './join-styles-r';


const Join = ({ classroom, onCancel, onJoin, locale }) => {

    // console.log('Leave Classsrom props', classroom, locale);
    
    const classesL = useJoinStyles();
    const classesR = useJoinStylesR();
    const classes = locale === 'he'? classesR : classesL;

    const images = classroom.images;
    const image = images.originalUrl || images.thumbnailUrl || classroom.imageUrl || null;

    const strings = JOIN[locale];
    // const strings = JOIN['he'];
    // console.log(strings);


    return (

        <div className={classes.join}>

            <Typography dir='auto' className={classes.title}>{strings.title}</Typography>

            <Divider className={classes.divider} />

            <div className={classes.joinHolder}>

                <Typography dir='auto' className={classes.joinMessage}>
                    {strings.joinMessage}
                </Typography>

                <div className={classes.classroomHolder}>
                    <div className={classes.classroom}>
                        <img className={classes.classroomImage} src={image} alt='' />
                        <Typography dir='auto' className={classes.classroomTitle}>{classroom.title}</Typography>
                    </div>
                </div>

                <div className={classes.buttons}>
                    <div>
                        <Button className={classes.cancelButton} onClick={onCancel}>
                            {strings.cancelButton}
                        </Button>
                    </div>

                    <div>
                        <Button className={classes.joinButton} onClick={onJoin}>
                            {strings.joinButton}
                        </Button>
                    </div>
                </div>

            </div>

        </div>
    )
};

export default Join;
