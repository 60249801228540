import { makeStyles } from '@material-ui/core/styles';

import { FONT } from '../../utils/font.utils'

export const useClassChatStyles = makeStyles({

    select: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
    },

    info: {
        ...FONT,
        // fontSize: '20px',
        fontSize: '1.0416666666666665vw',
        marginTop: '5.37037037037037vh',
    },

    loading: {
        height: '100%',
        display: 'flex',
        width: 'inherit',
        justifyContent: 'center',
        marginTop: '5.37037037037037vh',
    },

    circular: {
        height: '4.166666666666666vw',
        width: '4.166666666666666vw',
    },

    classChat: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        paddingRight: '0.625vw',
        paddingLeft: '0.625vw',
        // marginTop: '5.37037037037037vh',
        marginTop: '3.3333333333333335vw',
    },
    
});