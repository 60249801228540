import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CustomModal from '../../components/common/custom-modal/custom-modal.component';
import ReplyToComment from '../../components/comment-options/reply-to-comment.component';

import { closeReplyToCommentPage } from '../../redux/reply-to-comment/reply-to-comment.actions';
import { selectVisible, selectOnComment } from '../../redux/reply-to-comment/reply-to-comment.selector';
import { selectLocale } from '../../redux/localization/localization.selector';


const ReplyToCommentPage = ({ closeReplyToCommentPage, visible, onComment,locale }) => {

    // console.log(' ReplyTo Page props',  visible, onComment, closeReplyToCommentPage, locale);

    return (
        <div>
            <CustomModal open={visible} onClose={closeReplyToCommentPage} content={<ReplyToComment comment={onComment} locale={locale} />}/>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    visible: selectVisible,
    onComment: selectOnComment,
    locale: selectLocale,
})


const mapDispatchToProps = dispatch => ({
    closeReplyToCommentPage: () => dispatch(closeReplyToCommentPage())
});

export default connect(mapStateToProps, mapDispatchToProps)(ReplyToCommentPage);
