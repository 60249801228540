export const formatBytes = (a,b) => {

    if(0 === a ) {
        return "0 Bytes"
    };

    const c = 1024;
    const d = b || 2;
    const e = ["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"];
    const f = Math.floor(Math.log(a)/Math.log(c));

    return parseFloat((a/Math.pow(c,f)).toFixed(d))+" "+e[f]

};
