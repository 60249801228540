import React, { useState, useMemo } from 'react';
// import { isEmpty, isLoaded } from 'react-redux-firebase'

import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// import Class from '../../components/classes/class.component';
import ClassP from './classP.component';
// import NewClassroomPage from '../../pages/new-classroom-page/new-classroom-page.component';
import EditClassroomPage from '../../pages/edit-classroom-page/edit-classroom-page.component';
import LeaveClassroomPage from '../../pages/leave-classroom-page/leave-classrom-page.component';
import InviteClassmatePage from '../../pages/invite-classmate-page/invite-classmate-page,component';

import { object } from '../../utils/object.utils';
import { string } from '../../utils/string.utils';

import { CLASSROOMS } from '../../localized-strings/classrooms';

import { useClassroomsStyles, StyledClassroomListItem } from './classrooms.styles';


const byTitle = (

    (a, b) => string(object(a).title).localeCompare(string(object(b).title))

);

const ClassroomsP = ({ currentUserClasses, loading, edit, history, id, locale }) => {

    // console.log('ClassroomsP props', currentUserClasses, loading, edit, history, id, locale);

    const classes = useClassroomsStyles();

    const [selectedIndex, setSelectedIndex] = useState();

    const classrooms = useMemo(() => {
        return currentUserClasses
    }, [currentUserClasses]);

    const strings = CLASSROOMS[locale];
    
    const onClick = (ev, index) => {
        setSelectedIndex(index);
        history.push(`/classes/${index}/posts/:postId`)
    };

    if(loading) {
        return (
            <div className={classes.loading}>
                <CircularProgress className={classes.circular} />
            </div>
        );
    }

    if(classrooms.length === 0) {
        return (
            <div className={classes.noClassrooms}>
                <div className={classes.no}>
                    <Typography align='center' dir='auto' className={classes.noMessage}>{strings.noMessage}</Typography>
                </div>

                {/* Disabled for NOW */}
                {/* <div className={classes.addClassromButton}>
                    <NewClassroomPage />
                </div> */}
            </div>
        );
    }

    return (
        <div className={classes.flex}>

            {/* must be placed somewhere */}
            <EditClassroomPage />
            <LeaveClassroomPage />
            <InviteClassmatePage />

            <div className={classes.classroomsComponent}>

                <div className={classes.classrooms}>
                    <List className={classes.list}>
                        {
                            classrooms && classrooms.sort(byTitle).map((classroom, index) => {
                            // classrooms && classrooms.sort(byTitle).slice(0, 5).map((classroom, index) => {
                                return (
                                    <StyledClassroomListItem
                                        button
                                        key={classroom.idKey || index}
                                        selected={selectedIndex === classroom.idKey}
                                        onClick={event => onClick(event, classroom.idKey)}
                                    >
                                        <ClassP classroom={classroom} edit={edit} id={id} locale={locale} />
                                    </StyledClassroomListItem>
                                )
                            })
                        }
                    </List>
                </div>

                {/* <div className={classes.addClassromButton}>
                    <NewClassroomPage />
                </div> */}
                
            </div>

        </div>
    )
}

export default ClassroomsP;
