export const getCurrentUserId = (firebase) => {

    // OLD STRUCTURE WITH PHONE NUMBER
    // const currentUserId = firebase.auth().currentUser.phoneNumber;

    // RESTRUCTURE WITH UID
    const currentUserId = firebase.auth().currentUser.uid;

    return currentUserId;

};
