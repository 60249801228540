import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

// import { ReactComponent as Logo} from '../../assets/logo.svg';
import LogoHe from '../../assets/LogoHeb.png';
import LogoEn from '../../assets/LogoEn.png';

import LanguageSelect from './language-select.component';
import LoggedInLinks from './logged-In-links.component';
import LoggedOutLinks from './logged-out-links.component';
import { selectCurrentUser, selectCurrentUserId } from '../../redux/user/user.selector';

import { trackCoool } from '../../utils/cooladata.utils';
import { trackFirebase } from '../../utils/firebase-analytics.utils';

import { selectLocale } from '../../redux/localization/localization.selector';

import { useNavbarStyles } from './navbar.styles';


const Navbar = ({ locale, currentUserId, currentUser }) => {

    const classes = useNavbarStyles();

    // console.log('Navbar Props', locale, currentUserId, currentUser);

    useEffect(() => {

        if(currentUserId){
            trackCoool('app_open', {user_id: currentUserId})
            trackFirebase('app_open', {user_id: currentUserId});
        }else{
            trackCoool('app_open');
            trackFirebase('app_open');
        }

        const interval = setInterval(() => {
            if(currentUserId){
                trackCoool('keep_alive', {user_id: currentUserId})
                trackFirebase('keep_alive', {user_id: currentUserId});
            }else{
                trackCoool('keep_alive', );
                trackFirebase('keep_alive', );
            }
        }, 60000);
        return () => clearInterval(interval);
    }, [currentUserId]);

    return (
        <div className={classes.navbar}>
            <AppBar position="static" className={classes.appbar}>
                <Toolbar className={classes.toolbar} >
                    {/* <Logo /> */}
                    {
                        locale === 'he'? (
                            <img className={classes.logoHe} src={LogoHe} alt='Add Question' />
                        ):(
                            <img className={classes.logoEn} src={LogoEn} alt='Add Question' />
                        )
                    }
                    <LanguageSelect />
                    {
                        currentUser?
                        <LoggedInLinks locale={locale} profile={currentUser}/>
                        :
                        <LoggedOutLinks />
                    }
                </Toolbar>
            </AppBar>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    currentUserId: selectCurrentUserId,
    locale: selectLocale,
});

export default connect(mapStateToProps)(Navbar);
