
const noop = ((eventName, options) => { // eslint-disable-line no-unused-vars
    // TODO: @azder: console.warn the arguments, then do nothing
});


const createTracker = (

    () => {


        if (!window.cooladata) {
            // TODO: @azder: log the exception
            return noop;
        }

        if (!window.cooladata.trackEvent) {
            // TODO: @azder: log the exception
            return noop;
        }


        // if all good, this will be the function used, instead of noop

        // noinspection JSUnresolvedFunction
        return (
            (eventName, options) => window.cooladata.trackEvent(eventName, options)
        );

    }

);


export const trackCoool = (createTracker());
