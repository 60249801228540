import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, useFirebase } from 'react-redux-firebase';

import EditClassroomDisplay from './edit-classroom-display.component';

import { usePickedPhoto } from '../../utils/files/picked-photo.utils';
import { object } from '../../utils/object.utils';

import { onUpdateClassImage } from './on-update-class-image-handler';
import { onUpdateClassTitle } from './on-update-class-title';


const EditClassroom = ({ classId, closeClassromSettingsPage, classroom, locale }) => {

    // console.log('Edit Classroom props ...', classId, classroom, locale);
    
    const classroomTitle = classroom.title;
    const images = object(classroom.images);
    const image1 = images.thumbnailUrl || images.originalUrl || null;
    const image2 = classroom.imageUrl || null;
    const combineImages = image1? image1 : image2;
    const image = combineImages? combineImages : null;

    const [file, setFile] = useState(null);
    const [title, setTitle] = useState(classroomTitle);

    const imagePreviewUrl = usePickedPhoto(file);

    const firebase = useFirebase();

    const onFile = files => {
        const file = files && files[0];
        setFile(file)
    };

    const onChange = ev => {

        const { value } = ev.target;

        if (value[0] === ' ') {
            setTitle('');
        } else {
            setTitle(value);
        }

    };

    const fileForEdit = () => {
        if (file) {
            onUpdateClassImage(firebase, file, classId);
        } else {
            return null;
        }
    };

    const onConfirm = () => {

        fileForEdit();

        onUpdateClassTitle(firebase, title, classId);

        closeClassromSettingsPage();

    };

    const disabled = (!title) ? true : false;    

    return (
        
        <EditClassroomDisplay
            file={file}
            onFile={onFile}
            imagePreviewUrl={imagePreviewUrl}
            oldImageUrl={image}
            onChange={onChange}
            title={title}
            onCancel={closeClassromSettingsPage}
            disabled={disabled}
            onConfirm={onConfirm}
            locale={locale}
        />

    )
};

const mapStateToProps = (state, ownProps) => {

    // console.log('Edit Classroom state', state);
    
    const classId = ownProps.classId;
    const classes = state.firebase.data.classes;
    const classroom = classes? classes[classId] : null;
    
    return {
        classroom: classroom,
    }
};

export default compose(
    connect(mapStateToProps),
    firebaseConnect(ownProps => [
        `classes/${ownProps.classId}`
    ]),       
)(EditClassroom);
