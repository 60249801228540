import { COMMON } from './common';

export const NEW_COMMENT = {

    en: {
        inputPlaceholder: 'Type a message...',
        title: 'Upload a file',
        titleImage: 'Upload a image',
        addButton: '+ Add file',
        addButtonImage: '+ Add image',
        pageInputPlaceholder: COMMON['en'].addMessage,
        writeAnonymously: COMMON['en'].writeAnonymously,
        cancelButton: COMMON['en'].cancel,
        uploadButton: 'Upload',
    },

    he: {
        inputPlaceholder: 'הקלד תגובה...',
        title: 'העלה קובץ',
        titleImage: 'העלה תמונה',
        addButton: '+ הוסף קובץ',
        addButtonImage: '+ הוסף תמונה',
        pageInputPlaceholder: COMMON['he'].addMessage,
        writeAnonymously: COMMON['he'].writeAnonymously,
        cancelButton: COMMON['he'].cancel,
        uploadButton: 'העלה',
    },

};
