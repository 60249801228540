import { NewQuestionActionTypes } from './new-question.types';

const INITIAL_STATE = {
    newQuestionHidden: false,
};

const newQuestionReducer = (state = INITIAL_STATE, action) => {

    if(action.type === NewQuestionActionTypes.TOGGLE_QUESTION_PAGE_HIDDEN){
        return {
            ...state,
            newQuestionHidden: !state.newQuestionHidden
        }
    };

    return state;
};

export default newQuestionReducer;