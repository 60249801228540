export const INVITE_CLASSMATE = {

    en: {
        title: 'Share with a Friend',
        inviteMessage: 'Do you want to share this channel with a friend?',
        linkMessage: 'The invitation link is below! Just copy the link and send it to your friend.',
        doneButton: 'Done',
    },

    he: {
        title: 'שתף את הערוץ עם חבר/ה',
        inviteMessage: 'האם לשתף את הערוץ עם חבר/ה?',
        linkMessage: 'הקישור לשיתוף מוצג למטה. פשוט העתק את הקישור ושלח אותו לחבר/ה. ',
        doneButton: 'בוצע',
    },

};
