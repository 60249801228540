import React from 'react';

import Input from '@material-ui/core/Input';

import Smileyicon from '../../assets/smiley.svg';

import NewClassCommentAttachPage from '../../pages/new-class-comment-page/new-class-comment-attach-page.component';
import EmojiPoper from '../common/emoji/emoji-poper.component';

import { newClassCommentInputDisplayStyles } from './new-class-comment-input-display.styles';


const NewClassCommentInputDisplay = ({onChange, comment, onKeyPress, onEmojiClick, strings, locale}) => {

    // console.log('newClassCommentInput props',  onChange, comment, onKeyPress, onEmojiClick, strings, locale);

    const classes = newClassCommentInputDisplayStyles();

    const direction = locale === 'he'? 'rtl' : 'auto';

    return (

        <div className={classes.newCommentInputDisplay}>

            <NewClassCommentAttachPage />

            <Input
                id='comment-input'
                className={classes.input}
                // multiline
                fullWidth
                disableUnderline
                placeholder={strings.inputPlaceholder}
                inputProps={{
                    'aria-label': 'description',
                    maxLength: 1024,
                    // dir: 'auto',
                    dir: { direction }
                }}
                onChange={onChange}
                value={comment}
                onKeyPress={onKeyPress}
            />

            <EmojiPoper icon={Smileyicon} onEmojiClick={onEmojiClick} />

        </div>

    )
}

export default NewClassCommentInputDisplay;
