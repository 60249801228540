import { COMMON } from './common';

export const LOGOUT = {

    en: {
        title: COMMON['en'].logout,
        logoutMessage: 'Are you sure you want to log out from UniMastery?',
        cancelButton: COMMON['en'].cancel,
        logoutButton: 'Log out',
    },

    he: {
        title: COMMON['he'].logout,
        logoutMessage: 'האם להתנתק מ UniMastery?',
        cancelButton: COMMON['he'].cancel,
        logoutButton: 'התנתק',
    },

};
