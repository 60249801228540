import { InviteClassmateActionTypes } from './invite-classmate.types';

const INITIAL_STATE = {
    visible: false,
    onClass: null
};

const inviteClassmatePageReducer = (state = INITIAL_STATE, action) => {

    if(action.type === InviteClassmateActionTypes.OPEN_INVITE_CLASSMATE_PAGE){
        return {
            ...state,
            visible: true,
            onClass: action.payload,
        }
    }

    if(action.type === InviteClassmateActionTypes.CLOSE_INVITE_CLASSMATE_PAGE){
        return {
            ...state,
            visible: false,
        }
    };

    return state;
};

export default inviteClassmatePageReducer;
