import React from 'react';

import Dialog from '@material-ui/core/Dialog';

// import Modal from '@material-ui/core/Modal';
// import Backdrop from '@material-ui/core/Backdrop';
// import Fade from '@material-ui/core/Fade';

import { useCustomModalStyles } from './custom-modal.styles';


const CustomModal = ({open, onClose, content}) => {

    const classes = useCustomModalStyles();

    // return (
    //     <div>
    //         <Modal
    //             // aria-labelledby="transition-modal-title"
    //             // aria-describedby="transition-modal-description"
    //             className={classes.modal}
    //             open={open}
    //             onClose={onClose}
    //             closeAfterTransition
    //             // BackdropComponent={Backdrop}
    //             BackdropProps={{
    //                 timeout: 500,
    //             }}
    //         >
    //             <Fade in={open}>
    //                 <div className={classes.paper}>
    //                    {content}
    //                 </div>
    //             </Fade>
    //         </Modal>
    //     </div>
    // );

    return(
        <Dialog open={open} classes={{paper: classes.paper}} onClose={onClose}>            
            {content}
        </Dialog>
    )

};


export default CustomModal;