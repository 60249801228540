import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase';

import CircularProgress from '@material-ui/core/CircularProgress';

import Join from '../../components/join/join.component';

import { onJoinClassroom } from './on-join-classrom.handler';

import { useJoinPageStyles } from './join-page.styles';

const JoinPage = ({ currentUser, match, history, locale }) => {

    // console.log('Join Page props', currentUser, match, history);

    const classes = useJoinPageStyles();

    const classId = match.params.classId;

    useFirebaseConnect([`classes/${classId}`], [classId]); // sync from firebase into redux

    const classroom = useSelector(state => {
        // console.log('State', state)
        return state.firebase.data.classes && state.firebase.data.classes[classId];
    });
    // console.log('Classroom', classroom);

    const firebase = useFirebase();

    const onCancel = () => {
        history.push('/');
    };

    const onJoin = () => {

        const data = {
            user: currentUser.idKey,
            classId: classId,
        };

        onJoinClassroom(firebase, data);

        history.push('/classes/:classId/posts/:postId');

    };

    if(classroom === undefined || Object.keys(classroom).length === 0){
        return (
            <div className={classes.joinPage}>
                <CircularProgress size={60} />
            </div>
        )
    };

    return (
        <div className={classes.joinPage}>
            <Join 
                classroom={classroom} 
                onCancel={onCancel}
                onJoin={onJoin}
                locale={locale}
            />
        </div>
    );
    
};

export default withRouter(JoinPage);
