import { COMMON } from './common';

export const CLASSMATES = {

    en: {
        selectMessage: COMMON['en'].selectClassMessage,
        noMessage: 'No Members',
    },

    he: {
        selectMessage: COMMON['he'].selectClassMessage,
        noMessage: 'אין משתתפים',
    }
};
