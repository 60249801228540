import { makeStyles } from '@material-ui/core/styles';

import cursor from '../../assets/cursor.svg';

const url = `url('${cursor}'), auto`;


export const useImageInputAndDisplayStyles = makeStyles({

    imageHolder: {
        marginBottom: '64px',
        marginTop: '64px',
        width: '176px',
        height: '172px',
        borderRadius: '12px',
        opacity: 1,
    },

    holder: {
        marginBottom: '64px',
        marginTop: '64px',
        width: '176px',
        height: '172px',
        background: '#A9AAAB 0% 0% no-repeat padding-box',
        borderRadius: '12px',
        opacity: 1,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },

    imageButton: {
        width: '40px',
        height: '40px',
        background: '#FBFBFB 0% 0% no-repeat padding-box',
        borderRadius: '6px',
        opacity: 1,
        marginBottom: '16px',
        marginRight: '12px',
        minWidth: 'unset',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#EEFBFC',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        cursor: url,
    },

});

export const imageStyle = {
    objectFit: 'cover',
    width: '176px',
    height: '172px',
    borderRadius: '12px',
};
