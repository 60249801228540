import { createSelector } from 'reselect';

const selecNewCommentAttach = state => state.newCommentAttachPage;

export const selectNewCommentAttachPageState = createSelector(
    [selecNewCommentAttach],
    newCommentAttachPage => newCommentAttachPage.newCommentAttachHidden
);

export const selectCommentAnonymouseState = createSelector(
    [selecNewCommentAttach],
    newCommentAttachPage => newCommentAttachPage.commentAnonymous
);

export const selectCommentMessage = createSelector(
    [selecNewCommentAttach],
    newCommentAttachPage => newCommentAttachPage.message
);

export const selectCommentAttachment = createSelector(
    [selecNewCommentAttach],
    newCommentAttachPage => newCommentAttachPage.file
);