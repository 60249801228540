import { No } from '../models-core';
import { empty } from '../../utils/string.utils';


export class ClassComment {

    static name() {
        return 'classChat';
    }

    static path(classId, idKey) {

        const name = ClassComment.name();
        return empty(classId || idKey) ? `/${name}/unknown/unknown` : `/${name}/${classId}/${idKey}`;
    }


    static field(idKey, name) {
        return `${ClassComment.path(idKey)}/${name}`;
    }


    static empty() {

        return {
            // attachments: {},
            idKey: No.Id,
            author: No.String,
            text: No.String,
            class: No.Id,
            // createdUTime: No.Timestamp,
            // lastUpdatedUTime: No.Timestamp,
        };

    }


    static fromEmpty(merge) {
        return { ...ClassComment.empty(), ...merge };
    }


    static fromNew(merge) {
        return ClassComment.fromEmpty(merge);
    }

}
