import { newIdKey, updateDomain } from '../firebase/firebase.utils';
import { Attachment } from '../models/domain/attachment';
import { attachmentType } from '../utils/string.utils';


export const createPostAttachment = (

    async (firebase, data, idKey) => {

        if (!data || !data.contentType || !data.size || !data.url || !data.folder || !data.author) {
            console.warn('createPostAttachment()', 'no valid creation data', data);
            return void 0;
        }

        const attaId = data.folder;
        const postId = idKey || newIdKey(firebase);

        // const now = firebase.database.ServerValue.TIMESTAMP;

        const attachment = Attachment.fromNew({

            idKey: attaId,

            author: data.author,
            ownerId: postId,
            post: postId,
            createdTime: firebase.database.ServerValue.TIMESTAMP,
            fileType: attachmentType(data.contentType),
            images: {
                originalUrl: data.url,
                thumbnailUrl: data.url,
            },
            lastUpdatedUTime: firebase.database.ServerValue.TIMESTAMP,
            size: data.size,
            url: data.url,

        });

        await updateDomain(firebase, Attachment, attachment);

        return attachment;

    }

);
