import { COMMON } from './common';

export const COMMENTS = {

    en: {
        noMessage: 'No Comments in this session',
        deletedCommentMessage: 'This message was deleted',
        replyOption: 'Reply',
        editOption: COMMON['en'].edit,
        deleteOption: 'Delete',
    },

    he: {
        noMessage: 'עדיין אין תגובות',
        deletedCommentMessage: 'הודעה נמחקה',
        replyOption: 'השב לתגובה',
        editOption: COMMON['he'].edit,
        deleteOption: 'מחק תגובה',
    },
    
};
