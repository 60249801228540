import React from 'react';
import { connect } from 'react-redux';
import { useFirebase } from 'react-redux-firebase'
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import Button from '@material-ui/core/Button';

import CustomModal from '../../components/common/custom-modal/custom-modal.component';
import NewQuestion from '../../components/new-question/new-question.component';

import { getCurrentUserId } from '../../firebase/user.utils';
import { trackCoool } from '../../utils/cooladata.utils';
import { trackFirebase } from '../../utils/firebase-analytics.utils';

import { NEW_QUESTION } from '../../localized-strings/new-question';

import { toggleNewQuestionPageHidden } from '../../redux/new-question/new-question.actions';
import { selectNewQuestionPageState } from '../../redux/new-question/new-question.selector';
import { selectLocale } from '../../redux/localization/localization.selector';

import { useNewQuestionPageStyles } from './new-question-page.styles';


const NewQuestionPage = ({ newQuestionHidden, toggleNewQuestionPageHidden, match, locale, first }) => {

    // console.log('New Question Page props', match, locale);

    const classes = useNewQuestionPageStyles();

    const firebase = useFirebase();

    const strings = NEW_QUESTION[locale];

    const onToggle = () => {
        // must be first - toggler

        const currentUserId = getCurrentUserId(firebase);
        // console.log('Curren User Id', currentUserId);
        const classId = match.params.classId;

        if(!newQuestionHidden){
            trackCoool('post_create_initiated', {user_id: currentUserId, class_Id: classId});
            trackFirebase('post_create_initiated', {user_id: currentUserId, class_Id: classId});
        }
        toggleNewQuestionPageHidden();

    };

    const buttonLabel = first ?  strings.newQuestionFirstButton : strings.newQuestionButton; // '+ Add the first question' : 'Add New Question';


    return (
        <div>
            {
                (match.params.classId === ':classId')? (
                    null
                ):(
                    <div>
                        <Button className={classes.newQuestionButton} onClick={onToggle} variant='outlined'>
                            {buttonLabel}
                        </Button>
                        <CustomModal open={newQuestionHidden} onClose={onToggle} content={<NewQuestion locale={locale} />}/>
                    </div>
                )
            }
        </div>
        
    );
};

const mapStateToProps = createStructuredSelector({
    newQuestionHidden: selectNewQuestionPageState,
    locale: selectLocale,
});


const mapDispatchToProps = dispatch => ({
    toggleNewQuestionPageHidden: () => dispatch(toggleNewQuestionPageHidden())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewQuestionPage));
