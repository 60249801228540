import { updateFields } from '../../firebase/firebase.utils';

export const onUpdateVotes = async(firebase, data) => {

    console.log('onUpdateVotes() > ()', data);

    try {

        if(!data.postId){
            console.warn('No post info! Aborting updating ...');
            return void 0;
        }

        const upData = {
            [data.user]: data.vote
        }

        // console.log('Data for update', upData);

        await updateFields(firebase, `votes/${data.postId}`, upData);

    } catch (e) {

        console.error('onUpdateVotes() > catch{}', e);

    }
};
