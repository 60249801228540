import { updateFields } from '../../firebase/firebase.utils'; 
import { Post, PostByClass } from '../../models/domain/post';

export const onUpdateQuestionDeleteImage = async(firebase, classId, postId) => {


    console.log('onUpdateQuestionDeleteImage() > ()', classId, postId);

    try {

        if(!classId || !postId){
            console.warn('No Ids! Aborting updating ...');
            return void 0;
        }
       
        const now = firebase.database.ServerValue.TIMESTAMP;
        const path = `${postId}`;

        const upData = {
            significantAttachmentId: null,
            lastUpdatedUTime: now
        }

        await updateFields(firebase, Post.path(path), upData);

        await updateFields(firebase, PostByClass.path(classId, postId), upData);



    } catch (e) {

        console.error('onUpdateQuestionDeleteImage() > catch{}', e);

    }

};