import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, isLoaded, isEmpty} from 'react-redux-firebase';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Author from '../common/author/author.component';

import { allKeys } from '../../utils/object.utils';

import { CLASSMATES } from '../../localized-strings/classmates';

import { useClassmatesStyles, authorImageStyleL, authorNameStyle } from './classmates.styles';
import { authorImageStyleR } from './classmates-r.styles';

const Classmates = ({clasz, classId, locale }) => {

    // console.log('Classmates props', clasz, classId, locale);

    const classes = useClassmatesStyles();

    const authorImageStyle = locale === 'he'? authorImageStyleR : authorImageStyleL;

    const strings = CLASSMATES[locale];


    if(classId === ':classId') {
        return (
            <div className={classes.select}>
                <Typography dir='auto' className={classes.info}>{strings.selectMessage}</Typography>
            </div>
        )
    }

    if (!isLoaded(clasz)) {
        return (
            <div className={classes.loading}>
                <CircularProgress className={classes.circular} />
            </div>
        )
    }

    if (isEmpty(clasz)) {
        return (
            <div className={classes.noClasses}>
                {strings.noMessage}
            </div>
        )
    }

    // console.log('Class', clasz);
    const users = clasz.users || null;
    // console.log(users);
    // const classmates = idsOf(clasz.users);
    const classmates = allKeys(users);
    // console.log('Classmates', classmates);
    

    return(
        <div className={classes.classmates}>
            {
                classmates && classmates.map(classmate => {
                    return (
                        <div className={classes.classmate} key={classmate}>
                            <Author author={classmate} authorImageStyle={authorImageStyle} authorNameStyle={authorNameStyle}/>
                        </div>
                    )   
                })
            }
        </div>
    )
    
};

const mapStateToProps = (state, ownProps) => {

    // console.log('Classmates state', state);
    
    // const classId = ownProps.match.params.classId;
    const classId = ownProps.classId;
    const classes = state.firebase.data.classes;
    const clasz = classes? classes[classId] : null;
    const locale = state.localization && state.localization.locale;
    
    return {
        clasz: clasz,
        locale,
    }
};

export default compose(
    connect(mapStateToProps),
    firebaseConnect(ownProps => [
        // `classes/${ownProps.match.params.classId}`
        `classes/${ownProps.classId}`
    ]),       
)(Classmates);
