import { NewCommentAttachActionTypes } from './new-comment.types';

const INITIAL_STATE = {
    newCommentAttachHidden: false,
    commentAnonymous: false,
    message: '',
    file: null,
};

const newCommentAttachReducer = (state = INITIAL_STATE, action) => {

    if(action.type === NewCommentAttachActionTypes.TOGGLE_COMMENT_ATTACH_PAGE_HIDDEN){
        return {
            ...state,
            newCommentAttachHidden: !state.newCommentAttachHidden
        }
    };

    if(action.type === NewCommentAttachActionTypes.TOGGLE_COMMENT_ANONYMOUS){
        return {
            ...state,
            commentAnonymous: !state.commentAnonymous
        }
    };

    if(action.type === NewCommentAttachActionTypes.COMMENT_MESSAGE){
        return {
            ...state,
            message: action.payload,
        }
    };

    if(action.type === NewCommentAttachActionTypes.COMMENT_ATTACH){
        return {
            ...state,
            file: action.payload,
        }
    };

    return state;
};

export default newCommentAttachReducer;