import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';

export const useCommentStylesR = makeStyles({

    comment: {
        display: 'flex',
        // marginTop: '26px',
        marginTop: '2.4074074074074074vh',
        // marginBottom: '26px',
        marginBottom: '2.4074074074074074vh',
        alignItems: 'flex-start',
    },

    anonymousImageHolder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // width: '48px',
        width: '2.5vw',
        // height: '48px',
        height: '2.5vw',
        // marginRight: '12px',
        // marginRight: '0.625vw',
        marginLeft: '0.625vw',
        // borderRadius: '12px',
        borderRadius: '0.625vw',
        background: '#011E23 0% 0% no-repeat padding-box',
        opacity: 1,
    },

    content: {
        width: '100%',
    },

    flexSpaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },

    authorDate: {
        display: 'flex',
        alignItems: 'center',
    },

    anonymous: {
        ...FONT,
        // fontSize: '16px',
        fontSize: '0.8333333333333334vw',
        color: '#26C6DA',
    },

    date: {
        ...FONT,
        // fontSize: '16px',
        fontSize: '0.8333333333333334vw',
        // marginLeft: '16px'
        // marginLeft: '0.8333333333333334vw'
        marginRight: '0.8333333333333334vw'
    },

    editedDate: {
        ...FONT,
        // fontSize: '14px',
        fontSize: '0.7291666666666666vw',
        color: '#046D7F',
        // marginLeft: '8px',
        // marginLeft: '0.4166666666666667vw',
        marginRight: '0.4166666666666667vw',
    },

    body: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #E3E3E329',
        opacity: 1,
        // paddingLeft: '20px',
        paddingLeft: '1.0416666666666665vw',
        // paddingRight: '20px',
        paddingRight: '1.0416666666666665vw',
        // paddingTop: '12px',
        paddingTop: '1.1111111111111112vh',
        // paddingBottom: '12px',
        paddingBottom: '1.1111111111111112vh',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },

    text: {
        ...FONT,
        // fontSize: '17px',
        fontSize: '0.8854166666666666vw',
        // lineHeight: '27px',
        lineHeight: '2.5vh',
        whiteSpace: 'pre-wrap',
    },

    deletedbody: {
        background: '#F8E9E9 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #E3E3E329',
        // borderRadius: '8px',
        borderRadius: '0.4166666666666667vw',
        opacity: 1,
        // paddingLeft: '20px',
        paddingLeft: '1.0416666666666665vw',
        // paddingRight: '20px',
        paddingRight: '1.0416666666666665vw',
        // paddingTop: '12px',
        paddingTop: '1.1111111111111112vh',
        // paddingBottom: '12px',
        paddingBottom: '1.1111111111111112vh',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    deletedtext: {
        ...FONT,
        // fontSize: '17px',
        fontSize: '0.8854166666666666vw',
        // lineHeight: '27px',
        color: '#DA2626',
    },

    deletedErrorIcon: {
        color: '#DA2626',
        height: '1.25vw',
    },

});

export const authorImageStyleR = {
    // width: '48px',
    width: '2.5vw',
    // height: '48px',
    height: '2.5vw',
    objectFit: 'cover',
    // marginRight: '12px',
    // marginRight: '0.625vw',
    marginLeft: '0.625vw',
    // borderRadius: '12px',
    borderRadius: '0.625vw',
    opacity: 1,
};
