import { ProfileActionTypes } from './profile.types';

const INITIAL_STATE = {
    visible: false,
};

const profilePageReducer = (state = INITIAL_STATE, action) => {

    if(action.type === ProfileActionTypes.OPEN_PROFILE_PAGE){
        return {
            ...state,
            visible: true,
        }
    }

    if(action.type === ProfileActionTypes.CLOSE_PROFILE_PAGE){
        return {
            ...state,
            visible: false,
        }
    };

    return state;
};

export default profilePageReducer;