import React from 'react';

import Typography from '@material-ui/core/Typography';

import Drawer from '@material-ui/core/Drawer';

import logo from '../../assets/uniMastery.png'

import { matchYoutubeUrl, videoId, matchFirebaseUrl } from '../../utils/url.utils';

import { useDrawerStyles } from './drawer.styles';


const TemporaryDrawer = ({ opend, toggleDrawer, body, url, locale, strings }) => {
// const TemporaryDrawer = ({ opend, toggleDrawer, body, locale, strings }) => {

    const classes = useDrawerStyles();

    const direction = locale === 'he' ? 'left' : 'right';

    // const url = 'google.com'

    // const youtube = matchYoutubeUrl(body);
    const youtube = matchYoutubeUrl(url);
    // const video = videoId(body);
    const video = videoId(url);

    const firebase = matchFirebaseUrl(url);

    // const zoomVideo = zoomVideoId(body);
    // console.log(zoomVideo);

    // console.log('YouTube', youtube, video);
    // const src = youtube ? `https://www.youtube.com/embed/${video}?autoplay=1` : ` https://openu.zoom.us/rec/play/${body}`;


    return (
        <div>
            <Drawer className={classes.root} anchor={direction} open={opend} onClose={toggleDrawer}>
                {/* {
                    youtube ? (
                        <div className={classes.iframeContainer}>
                            <iframe
                                className={classes.iframe}
                                width="560"
                                height="315"
                                // src={`${body}?autoplay=1`}
                                src={`https://www.youtube.com/embed/${video}?autoplay=1&modestbranding=1&fs=0&rel=0`}
                                // src={src}
                                // title={body}
                                title='YouTube video player'
                                loading='lazy'
                                // allow='autoplay'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                frameBorder='0'
                                allowFullScreen
                            />
                            <div className={classes.bannerOverlay}>
                                <img className={classes.logo} src={logo} alt='uni' />
                            </div>
                            <div className={classes.over}></div>
                        </div>
                    ) : (
                        <Typography dir='auto' className={classes.messages}>{strings.urlMessage}</Typography>
                    )
                } */}
                {
                    !(youtube || firebase) ? (
                        <Typography dir='auto' className={classes.messages}>{strings.urlMessage}</Typography>
                    ):(
                        <>
                            {
                                youtube ? (
                                    <div className={classes.iframeContainer}>
                                        <iframe
                                            className={classes.iframe}
                                            width="560"
                                            height="315"
                                            // src={`${body}?autoplay=1`}
                                            src={`https://www.youtube.com/embed/${video}?autoplay=1&modestbranding=1&fs=0&rel=0`}
                                            // src={src}
                                            // title={body}
                                            title='YouTube video player'
                                            loading='lazy'
                                            // allow='autoplay'
                                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                            frameBorder='0'
                                            allowFullScreen
                                        />
                                        <div className={classes.bannerOverlay}>
                                            <img className={classes.logo} src={logo} alt='uni' />
                                        </div>
                                        <div className={classes.over}></div>
                                    </div>
                                ) : (
                                    <div onContextMenu={e => e.preventDefault()} className={classes.iframeContainer}>
                                    <video 
                                        className={classes.iframe}
                                        width="560"
                                        height="315"
                                        controls
                                        autoPlay
                                        onContextMenu={e => e.preventDefault()}
                                        controlsList='nodownload'>
                                        {/* <source src="movie.mp4" type="video/mp4" /> */}
                                        <source src={url} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    </div>
                                )
                            }
                        </>
                    )
                }
            </Drawer>
        </div>
    );
};

export default TemporaryDrawer;
