import { ClassComment } from '../models/domain/class-chat';
import { updateFields, newIdKey } from '../firebase/firebase.utils';


export const createClassComment = (

    async (firebase, data) => {

        if (!data.class) {
            console.error('createClassComment()', 'No classId in', data, 'Aborting creation process');
            return void 0;
        };

        // if (!data.attachments) {
        //     console.error('createMessage()', 'No Messge or Attachment ...', data, 'Aborting creation process');
        //     return void 0;
        // };

        if (!data.idKey) {
            console.warn('createMessage()', 'No commentId', data, 'Creating new ...');
        };

        const commentId = data.idKey || newIdKey(firebase);
        const comment = ClassComment.fromNew({ ...data, idKey: commentId });

        await updateFields(firebase, ClassComment.path(data.class, commentId), comment);

        return comment;

    }

);