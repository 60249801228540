import { COMMON } from './common';

export const LEAVE_CLASSROOM = {

    en: {
        title: 'Leave Channel',
        leaveMessage: 'Are you sure you want to leave this channel?',
        cancelButton: COMMON['en'].cancel,
        leaveButton: 'Leave'
    },

    he: {
        title: 'צא מהערוץ',
        leaveMessage: 'אם תצא מערוץ זו לא תוכל עוד לצפות בתוכן שלה ולהגיב',
        cancelButton: COMMON['he'].cancel,
        leaveButton: 'צא מהערוץ'
    },

};
