import { EditCommentActionTypes } from './edit-comment-types';

const INITIAL_STATE = {
    visible: false,
    onComment: null
};

const editCommentReducer = (state = INITIAL_STATE, action) => {

    if(action.type === EditCommentActionTypes.OPEN_EDIT_COMMENT_PAGE){
        return {
            ...state,
            visible: true,
            onComment: action.payload,
        }
    }

    if(action.type === EditCommentActionTypes.CLOSE_EDIT_COMMENT_PAGE){
        return {
            ...state,
            visible: false,
            // onComment: null
        }
    };

    return state;
};

export default editCommentReducer;