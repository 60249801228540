import { newIdKey } from '../firebase/firebase.utils';
import { object } from '../utils/object.utils';
import { No } from '../models/models-core';



export const uploadClassImage = async (firebase, image, classId) => {
        
        if (!image) {

            console.warn('uploadClassImage', 'no valid image', image);

            return {
                contentType: No.ContentType,
                size:        No.Number,
                url:         No.Url,
            };
        }

        const folder = classId || newIdKey(firebase);
        const uresult = await firebase.uploadFile(`classImage/${folder}/origin`, image);

        const snapshot = object(uresult.uploadTaskSnapshot);
        const metadata = object(snapshot.metadata);

        console.log('uploadClassImage()', {snapshot, metadata, uresult}, '...');

        // const urrl = await snapshot.ref.getDownloadURL();

        // console.log('URAAAL', urrl);

        return {
            contentType: metadata.contentType,
            size:        metadata.size,
            url:         snapshot.ref && await snapshot.ref.getDownloadURL(),
            snapshot,
            metadata,
            uresult,
        };

}
