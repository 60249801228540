import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CustomModal from '../../components/common/custom-modal/custom-modal.component';
import DeleteComment from '../../components/comment-options/delete-comment.component';

import { closeDeleteCommentPage } from '../../redux/delete-comment/delete-comment.actions';
import { selectVisible, selectOnComment } from '../../redux/delete-comment/delete-comment.selector';
import { selectLocale } from '../../redux/localization/localization.selector';


const DeleteCommentPage = ({  visible, onComment, closeDeleteCommentPage, locale }) => {

    // console.log('DeleteCommentPage', visible, onComment, locale);

    
    return (
        <div>
            <CustomModal open={visible} onClose={closeDeleteCommentPage} content={<DeleteComment comment={onComment} locale={locale} />}/>
        </div>
    );

};

const mapStateToProps = createStructuredSelector({
    visible: selectVisible,
    onComment: selectOnComment,
    locale: selectLocale,
});


const mapDispatchToProps = dispatch => ({
    closeDeleteCommentPage: () => dispatch(closeDeleteCommentPage())
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCommentPage);

