import { uploadUserImage }  from '../../crud/upload-user-image';
import { updateFields } from '../../firebase/firebase.utils'; 
import { User } from '../../models/domain/user';
import { getCurrentUserId } from '../../firebase/user.utils';
import { trackCoool } from '../../utils/cooladata.utils';
import { trackFirebase } from '../../utils/firebase-analytics.utils';

export const onUpdateUserImage = async(firebase, data) => {


    console.log('onUpdateUserImage() > ()', data);
    
    const currentUserId = getCurrentUserId(firebase);
    // console.log('Curren User Id', currentUserId);

    try {

        if(!data) {
            console.log('No image! Skkipping upload ...');
            return void 0;
        }

        const updata = await uploadUserImage(firebase, data, currentUserId);
        console.log('onUpdateUserImage() > try{}', 'User image uploaded: ', updata);

        const now = firebase.database.ServerValue.TIMESTAMP;
        const path = `${currentUserId}`;

        await updateFields(firebase, User.path(path), {
            imageUpdatedUTime: now,
            imageUrl: updata.url,
            images: {
                originalUrl: updata.url,
                thumbnailUrl: updata.url,
            },
            updatedUTime: now
        });

        trackCoool('edit_user_details', {user_id: currentUserId, new_user_image: updata.url});
        trackFirebase('edit_user_details', {user_id: currentUserId, new_user_image: updata.url});

    } catch (e) {

        console.error('onUpdateUserImage() > catch{}', e);

        trackCoool('edit_user_details', {user_id: currentUserId, error: e});
        trackFirebase('edit_user_details', {user_id: currentUserId, error: e});

    }

};
