import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';

export const useNewQuestionPageStyles = makeStyles({
    
    newQuestionButton: {
        ...FONT,
        // margin: theme.spacing(2),
        margin: '0.8333333333333334vw',
        border: '1px solid #011E23',
        borderRadius: 'unset',
        fontSize: '0.625vw',
        textTransform: 'capitalize',
        // padding: 5px 16px;
        padding: '0.46296296296296297vh 0.8333333333333334vw',
    },
    
});