import { createSelector } from 'reselect';

export const selectReplyToComment = state => state.replyToCommentPage;

export const selectVisible = createSelector(
    [selectReplyToComment],
    replyToCommentPage => replyToCommentPage.visible
);

export const selectOnComment = createSelector(
    [selectReplyToComment],
    replyToCommentPage => replyToCommentPage.onComment
);

