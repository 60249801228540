import React from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

import CloseIcon from '@material-ui/icons/Close';

import { FilePick } from '../common/files-input/files.pick.component';
import PreviwAttachment from '../common/previw-attachment/previw-attachment.component';

import { useNewClassCommentAttachDispalyStyles } from './new-class-comment-attach-display.styles';

const NewClassCommentAttachDisplay = ({strings, file, onReset, onFile, onChange, comment, onToggle, onSend, locale}) => {

    // console.log('New Class Comment Attach Display props', strings, locale);

    const classes = useNewClassCommentAttachDispalyStyles();

    const direction = locale === 'he'? 'rtl' : 'ltr';

    const disabled = file? false : true;

    return (
        <div dir={direction} className={classes.newClassCommmentAttachDisplay}>

            <Typography dir='auto' className={classes.title}>{strings.titleImage}</Typography>

            <Divider className={classes.divider} />

            <div className={classes.inputsHolder}>

                <div className={classes.fileHolder}>
                    {
                        file ? (
                            <div className={classes.fileContent}>
                                <PreviwAttachment  file={file} />
                                <div className={classes.iconButtonHolder}>
                                    <IconButton size='small' className={classes.iconButton} onClick={onReset}>
                                        <CloseIcon style={{fontSize: '16px'}} />
                                    </IconButton>
                                </div>
                            </div>
                        ) : (null)
                    }
                </div>

                <div className={classes.addFile}>
                    <FilePick
                        accept="image/*"
                        onPick={onFile}
                        content={() =>
                            (
                                <Button className={classes.addButton} component="span">
                                    {strings.addButtonImage}
                                </Button>
                            )
                        }

                    />
                </div>

                <div className={classes.inputHolder}>
                    <Input
                        multiline
                        className={classes.input}
                        fullWidth
                        disableUnderline
                        placeholder={strings.pageInputPlaceholder}
                        inputProps={{
                            'aria-label': 'description',
                            maxLength: 1024,
                            dir: {direction},
                        }}
                        onChange={onChange}
                        value={comment}
                    />
                </div>

                <div className={classes.buttons}>

                    <div>
                        <Button className={classes.cancelButton} onClick={onToggle}>
                            {strings.cancelButton}
                        </Button>
                    </div>

                    <div>
                        <Button disabled={disabled} className={classes.uploadButton} onClick={onSend}>
                            {strings.uploadButton}
                        </Button>
                    </div>

                </div>

            </div>
            
        </div>
    )

};

export default NewClassCommentAttachDisplay;
