import React from 'react';
import { connect } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

import { database } from '../../firebase/firebase.utils';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { setCurrentUserId } from '../../redux/user/user.actions';

import { trackCoool } from '../../utils/cooladata.utils';
import { trackFirebase } from '../../utils/firebase-analytics.utils';

const SignIn = ({ setCurrentUserId }) => {

    const firebase = useFirebase();

    // This is our firebaseui configuration object
    const uiConfig = {
        signInFlow: 'popup',
        // signInFlow: firebase.auth().isSignInWithEmailLink(window.location.href) ? 'redirect' : 'popup',
        callbacks: {
            // Avoid redirects after sign-in.
            // signInSuccessWithAuthResult: () => false
            signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                // console.log('CALLBACK', authResult, redirectUrl);

                // const userId = authResult.user.phoneNumber;

                const userId = authResult.user.uid;
                setCurrentUserId(userId);

                // const userRef = database.ref(`users/${authResult.user.phoneNumber}`);
                const userRef = database.ref(`users/${authResult.user.uid}`);
                // console.log('Uers Ref', userRef);

                userRef.once('value').then(snapshot => {
                    // console.log(snapshot.val());

                    const userId = authResult.user.uid;
                    const userFName = snapshot.val() ? snapshot.val().firstName : `first login ${userId}`;
                    const userLName = snapshot.val() ? snapshot.val().lastName : `first login ${userId}`;

                    const loginEventParams = {
                        // user_id: authResult.user.phoneNumber, 
                        user_id: userId,
                        user_name: userFName + ' ' + userLName
                    };

                    trackCoool('login', loginEventParams);
                    trackFirebase('login', loginEventParams);

                });

                return false;
            },

            signInFailure: function (error) {

                console.error('signInFailure', error)
                const logiinErrorEventParams = { failure_reason: error.code, user_credential: error.credential };

                trackCoool('login_failed', logiinErrorEventParams);
                trackFirebase('login_failed', logiinErrorEventParams);

            },

        },
        signInSuccessUrl: '/classes/:classId/posts/:postId',
        signInOptions: [

            // DISABLED email login

            // {
            //     provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            //     signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
            // },

            {
                provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                recaptchaParameters: {
                    type: 'image', // 'audio'
                    size: 'normal', // 'invisible' or 'compact'
                    badge: 'bottomleft' //' bottomright' or 'inline' applies to invisible.
                },

                // defaultCountry: 'IL', // Set default country to the United Kingdom (+44).
                defaultCountry: 'IL', // Set default country to the United Kingdom (+44).

                // For prefilling the national number, set defaultNationNumber.
                // This will only be observed if only phone Auth provider is used since
                // for multiple providers, the NASCAR screen will always render first
                // with a 'sign in with phone number' button.

                // defaultNationalNumber: '5xxxxxxxxx',

                // You can also pass the full phone number string instead of the
                // 'defaultCountry' and 'defaultNationalNumber'. However, in this case,
                // the first country ID that matches the country code will be used to
                // populate the country selector. So for countries that share the same
                // country code, the selected country may not be the expected one.
                // In that case, pass the 'defaultCountry' instead to ensure the exact
                // country is selected. The 'defaultCountry' and 'defaultNationaNumber'
                // will always have higher priority than 'loginHint' which will be ignored
                // in their favor. In this case, the default country will be 'GB' even
                // though 'loginHint' specified the country code as '+1'.

                // loginHint: '+11234567890'
            }

        ],
        credentialHelper: 'none',
    }

    return (
        <div>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        </div>
    )
};

const mapDispatchToProps = dispatch => ({
    setCurrentUserId: userId => dispatch(setCurrentUserId(userId))
});


export default connect(null, mapDispatchToProps)(SignIn);
