import { uploadCommentFile } from '../../crud/upload-comment-file';
import { createCommentAttachment } from '../../crud/create-comment-attachmet';
import { updateFields } from '../../firebase/firebase.utils'; 
import { Comment } from '../../models/domain/comment';
import { getCurrentUserId } from '../../firebase/user.utils';


export const onUpdateCommentFile = async(firebase, file, commentId, postId) => {


    console.log('onUpdateCommentFile() > ()', file, commentId, postId);


    try {

        const updata = await uploadCommentFile(firebase, file);
        console.log('onUpdateCommentFile() > try{}', 'Comment file uploaded: ', updata);

        const currentUserId = getCurrentUserId(firebase);
        // console.log('Curren User Id', currentUserId);

        const dataForAttachment = {
            author: currentUserId,
            post: postId,
            ...updata
        }
        console.log('Data for creating attachment: ', dataForAttachment);

        const attachment = await createCommentAttachment(firebase, dataForAttachment, commentId);
        console.log('onUpdateCommentFile() > try{}', 'Comment attachment created: ', attachment);

        const now = firebase.database.ServerValue.TIMESTAMP;
        const path = `${commentId}`;

        const upData = {
            lastUpdatedUTime: now,
            isEdited: now,
            significantAttachmentId: attachment.idKey,
            attachments: {
                [attachment.idKey]: attachment.fileType,
            },
        };

        console.log('onUpdateCommentFile() > try{}', 'Updating comment with: ',upData);

        await updateFields(firebase, Comment.path(path), upData);

    } catch (e) {

        console.error('onUpdateCommentFile() > catch{}', e);

    }

};
