import { COMMON } from './common';

export const NEW_QUESTION = {

    en: {
        newQuestionButton: 'Add New Session',
        newQuestionFirstButton: '+ Add the first session',
        title: 'Add New Session',
        selectLabel: 'Select session type',
        selectQuestion: 'Question',
        selectHomework: 'Homework',
        selectExam: 'Exam',
        selectTask: 'Task',
        dateLabel: 'Pick a date',
        inputPlaceholder: 'Describe your session',
        writeAnonymously: COMMON['en'].writeAnonymously,
        cancelButton: COMMON['en'].cancel,
        addButton: COMMON['en'].add,
    },

    he: {
        newQuestionButton: 'צור מפגש חדש',
        newQuestionFirstButton: '+ התחל על ידי יצירת המפגש הראשון',
        title: 'צור מפגש חדש',
        selectLabel: 'בחר סוג מפגש',
        selectQuestion: 'שאלה',
        selectHomework: 'שיעורי בית',
        selectExam: 'בחינה',
        selectTask: 'משימה',
        dateLabel: 'בחר תאריך',
        inputPlaceholder: 'תאר את המפגש שלך',
        writeAnonymously: COMMON['he'].writeAnonymously,
        cancelButton: COMMON['he'].cancel,
        addButton: COMMON['he'].add,
    },

};
