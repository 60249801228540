import React from 'react';

import Typography from '@material-ui/core/Typography';
import Author from '../common/author/author.component';
import Attachment from '../common/attachment/attachment.component';
import Reply from './reply.component';

import anonymousImage from '../../assets/anonymousBlack.svg';

import moment from 'moment';
import Linkify from 'react-linkify';

import CommentOptionsButton from '../comment-options/comment-options-button.component';

import {
    useCommentStyles,
    authorImageStyleL,
    authorNoimage,
    authorNameStyle,
    anonymousNameStyle,
    authorNoName,
    attImageStyle,
    linkText,
    anonymousIcon,
} from './comment.styles';

import { useCommentStylesR, authorImageStyleR } from './comment-r.styles';


const NormalComment = ({ comment, attId, type, author, id, view, edit, strings, locale }) => {

    // console.log('Normal Comment props', comment, attId, type, author, id, view, edit);

    const classesL = useCommentStyles();
    const classesR = useCommentStylesR();
    const classes = locale === 'he'? classesR : classesL;

    const authorImageStyle = locale === 'he'? authorImageStyleR : authorImageStyleL;

    const anonymous = comment.value.anonymous || null;
    const time = moment(comment.value.createdTime).calendar();
    const isEdited = comment.value.isEdited || null;

    const putPlaceholder = (type) => {
        if(type === 'IMAGE'){
            // return {height: '225px'}
            return {height: '11.71875vw'}
        };
    };

    const style = putPlaceholder(type[0]);

    const componentDecorator = (href, text, key) => (
        <a href={href} key={key} target='_blank' rel='noopener noreferrer' style={linkText}>
          {text}
        </a>
    );

    return(
        <div className={classes.comment}>
            <div>
                {
                    anonymous? (
                        <div className={classes.anonymousImageHolder}>
                            {/* <img src={anonymousImage} width='32' height='32' alt='' /> */}
                            <img src={anonymousImage} style={anonymousIcon} alt='' />
                        </div>
                    ):(
                        <Author author={author} authorImageStyle={authorImageStyle} authorNameStyle={authorNoName} />
                    )
                }
            </div>
            <div className={classes.content}>
                <div className={classes.flexSpaceBetween}>
                    <div>
                        {
                            anonymous? (
                                <div>
                                    {
                                        view?(
                                            <div className={classes.authorDate}>
                                                <Typography className={classes.anonymous}>Anonymous</Typography>
                                                {/* <Typography className={classes.anonymous} style={{marginLeft: '8px'}}>(</Typography> */}
                                                <Typography className={classes.anonymous} style={{marginLeft: '0.4166666666666667vw'}}>
                                                    (
                                                </Typography>
                                                <Author author={author} authorImageStyle={authorNoimage} authorNameStyle={anonymousNameStyle} />
                                                <Typography className={classes.anonymous}>)</Typography>
                                                <Typography className={classes.date}>{time}</Typography>
                                                <div>
                                                    {
                                                        isEdited? (
                                                            <Typography className={classes.editedDate}>{moment(isEdited).calendar()}</Typography>
                                                        ): null
                                                    }
                                                </div>
                                            </div>
                                        ):(
                                            <div className={classes.authorDate}>
                                                <Typography className={classes.anonymous}>Anonymous</Typography>
                                                <Typography className={classes.date}>{time}</Typography>
                                                <div>
                                                    {
                                                        isEdited? (
                                                            <Typography className={classes.editedDate}>{moment(isEdited).calendar()}</Typography>
                                                        ): null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            ):(
                                <div className={classes.authorDate}>
                                    <Author author={author} authorImageStyle={authorNoimage} authorNameStyle={authorNameStyle} />
                                    <Typography className={classes.date}>{time}</Typography>
                                    <div>
                                        {
                                            isEdited? (
                                                <Typography className={classes.editedDate}>
                                                    (edited {moment(isEdited).calendar()})
                                                </Typography>     
                                            ): null
                                        }
                                    </div>
                                </div>
                            )
                        }
                        
                    </div>
                </div>
                <div className={classes.body}>
                    <div>
                        <Reply reply={comment.value.replyTo || null} view={view} locale={locale}/>
                        {
                            (attId[0] && comment.value.text)? (
                                <div dir='ltr' style={{marginBottom: '1.8518518518518519vh'}}>
                                    <div dir='ltr' style={style}>
                                        <Attachment attId={attId[0]} attImageStyle={attImageStyle} download={true} withLitebox={true} />
                                    </div>
                                </div>
                            ):(
                                <div dir='ltr' style={style}>
                                    <Attachment attId={attId[0]} attImageStyle={attImageStyle} download={true} withLitebox={true} />
                                </div>
                            )
                        }
                        <Linkify componentDecorator={componentDecorator}>
                            <Typography dir='auto' className={classes.text}>{comment.value.text}</Typography>
                        </Linkify>
                    </div>
                    <CommentOptionsButton id={id} comment={comment} strings={strings} edit={edit} />
                </div>
            </div>
        </div>
    )
}

export default NormalComment;
