import { updateFields } from '../../firebase/firebase.utils'
import { Class } from '../../models/domain/class';
import { User } from '../../models/domain/user';
// import { trackCoool } from '../../utils/cooladata.utils';
// import { trackFirebase } from '../../utils/firebase-analytics.utils';


export const onLeaveClassroom = async(firebase, data) => {

    // withHandlers may have problems with async functions, so using IIFE instead

    console.log('onLeaveClassroom() > ()', data);

    try {

        const pathForClass = `${data.classId}/users`;
        const userForleave = {[data.user]: null};
        
        console.log('onLeaveClassroom() > try{}', 'Remove user from class:', pathForClass);

        await updateFields(firebase, Class.path(pathForClass), userForleave);

        console.log('onLeaveClassroom() > try{}', 'Remove user from class SUCCESS !!!');

        const classForLeave = {[data.classId]: null};
        const pathForUserClassesS = `${data.user}/classesStudent`;

        console.log('onLeaveClassroom() > try{}', 'Remove class from classesStudent:', pathForUserClassesS);

        await updateFields(firebase, User.path(pathForUserClassesS), classForLeave);

        console.log('onLeaveClassroom() > try{}', 'Remove class from classesStudent SUCCESS !!!');


        const pathForUserClassesT = `${data.user}/classesTeacher`;

        console.log('onLeaveClassroom() > try{}', 'Remove class from classesTeacher:', pathForUserClassesT);

        await updateFields(firebase, User.path(pathForUserClassesT), classForLeave);

        console.log('onLeaveClassroom() > try{}', 'Remove class from classesTeacher SUCCESS !!!');

        // const eventParametars = {
        //     user_id: firebase.auth().currentUser.phoneNumber,
        //     class_Id: clasz.idKey,
        //     organization_id: clasz.organization,
        //     class_title: clasz.title
        // };

        // trackCoool('class_created', eventParametars);
        // trackFirebase('class_created', eventParametars);

        
    }catch (e) {
        console.warn('onLeaveClassroom() > catch{}', e);
    
        // trackCoool('class_created', {error: e});
        // trackFirebase('class_created', {error: e});
    }
    
};
