import { newIdKey } from '../firebase/firebase.utils';
import { object } from '../utils/object.utils';
import { No } from '../models/models-core';



export const uploadPostImage = async (firebase, image) => {
        
        if (!image) {

            console.warn('uploadPostFile', 'no valid image', image);

            return {
                contentType: No.ContentType,
                size:        No.Number,
                url:         No.Url,
            };
        }

        const folder = newIdKey(firebase);
        const uresult = await firebase.uploadFile(`postAttachments/${folder}/origin`, image);

        const snapshot = object(uresult.uploadTaskSnapshot);
        const metadata = object(snapshot.metadata);

        console.log('uploadPostFile', {snapshot, metadata, uresult, folder}, '...');


        return {
            contentType: metadata.contentType,
            size:        metadata.size,
            url:         snapshot.ref && await snapshot.ref.getDownloadURL(),
            snapshot,
            metadata,
            uresult,
            folder
        };

}
