import { createSelector } from 'reselect';

export const selectDeleteComment = state => state.deleteCommentPage;

export const selectDeleteCommentPageState = createSelector(
    [selectDeleteComment],
    deleteCommentPage => deleteCommentPage.deleteCommentHidden
);

export const selectVisible = createSelector(
    [selectDeleteComment],
    deleteCommentPage => deleteCommentPage.visible
);

export const selectOnComment = createSelector(
    [selectDeleteComment],
    deleteCommentPage => deleteCommentPage.onComment
);

