import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';

import Post from './post.component';

import { POSTS } from '../../localized-strings/posts';

import { StyledPostListItem } from './posts.styles';
import { usePostsStyles } from './posts.styles';

const byTime = (
    (a, b) => {
        // const atime = (a.value.lastUpdatedUTime || a.value.createdUTime);
        const atime = (a.value.createdUTime || a.value.lastUpdatedUTime);
        // const btime = (b.value.lastUpdatedUTime || b.value.createdUTime);
        const btime = (b.value.createdUTime || b.value.lastUpdatedUTime);
        return atime > btime ? -1 : (atime < btime ? 1 : 0);
    }
);

const Posts = ({ posts, classId, history, match, locale }) => {

    // console.log('Posts props', posts, classId, history, match, locale);

    const classes = usePostsStyles();

    const sortedPosts = posts && posts.sort(byTime);
    const firstPostId = sortedPosts && sortedPosts[0].key;

    const postId = match.params.postId;

    const [selectedIndex, setSelectedIndex] = useState();

    const topRef = useRef();

    const strings = POSTS[locale];

    useEffect(() => {

        // console.log('Ref', topRef.current)

        if (topRef.current) {
            
            topRef.current.scrollIntoView({ behavior: "auto" });
        };

        if(firstPostId) {

            if(postId === ':postId') {
                setSelectedIndex(firstPostId);
                history.push(`/classes/${classId}/posts/${firstPostId}`)
            };

        };

    }, [classId, firstPostId, history, postId, posts]);

    const onClick = (ev, index) => {
        setSelectedIndex(index);
        history.push(`/classes/${classId}/posts/${index}`)
    };


    if(classId === ':classId') {
        return (
            <div className={classes.select}>
                <Typography dir='auto' className={classes.info}>{strings.selectMessage}</Typography>
            </div>
        )
    }

    if(!isLoaded(posts)) {
        return (
            <div className={classes.loading}>
                <CircularProgress className={classes.circular} />
            </div>
        )
    }

    if(!sortedPosts) {
        return (
            <div className={classes.select}>
                <Typography dir='auto' className={classes.info}>{strings.noMessage}</Typography>
            </div>
        )
    }
    
    return(            
        <div className={classes.posts} >

            <div ref={topRef} />

            <List className={classes.list}>
            {   
                // posts && posts.sort(byTime).map(post => {
                sortedPosts.map(post => {
                    return (
                        <StyledPostListItem
                                button 
                                key={post.key}  
                                selected={selectedIndex === post.key}
                                onClick={event => onClick(event, post.key)}
                        >
                            <Post post={post} locale={locale} />
                        </StyledPostListItem>
                    )
                })
            }
            </List>
        </div>
    )
    
};

const mapStateToProps = (state, ownProps) => {

    // console.log('Posts state', state);
    
    const classId = ownProps.classId;
    const posts = state.firebase.ordered.postsByClass;
    const dePosts = posts? posts[classId] : null;
    const locale = state.localization && state.localization.locale;
    
    return {
        posts: dePosts,
        locale,
    }
};

export default withRouter(compose(
    connect(mapStateToProps),
    firebaseConnect(ownProps => [
        `postsByClass/${ownProps.classId}`
    ]),       
)(Posts));
