import React from 'react'
import { useFirebase } from 'react-redux-firebase';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Like from '../../assets/like.svg';
import LikeSelected from '../../assets/likeGreen.svg';
import Dislike from '../../assets/dislike.svg';
import DislikeSelected from '../../assets/dislikeRed.svg';

import { getCurrentUserId } from '../../firebase/user.utils';

import { onUpdateVotes } from './on-voting.handler';

import { useVotingStyles } from './voting.styles';
import { useVotingStylesR } from './voting-r.styles';

const Voting = ({ postId, results, voting,locale }) => {

    // console.log('Voting props', postId, results, voting);

    const classesL = useVotingStyles();
    const classesR = useVotingStylesR();
    const firebase = useFirebase();

    const classes = locale === 'he'? classesR : classesL;

    const currentUserId = getCurrentUserId(firebase);
    // console.log('Curren User Id', currentUserId);

    const up = results? results.up : 0;
    const down = results? results.down : 0;

    const userVoting = voting? voting[currentUserId] : null;

    const onVote = (vote) => {
        
        const data = { 
            user: currentUserId,
            vote: vote,
            postId: postId, 
        };

        onUpdateVotes(firebase, data);

    };


    return (
        <div className={classes.voting}>

            <div className={classes.dispaly}>
                <Typography className={classes.number}>{up}</Typography>
                {
                    userVoting === 1 ? (
                        <IconButton className={classes.button} onClick={ev => onVote(null)}>
                            <img className={classes.likes} src={LikeSelected} alt='1' />
                        </IconButton>
                    ):(
                        <IconButton className={classes.button} onClick={ev => onVote(1)}>
                            <img className={classes.likes} src={Like} alt='1' />
                        </IconButton>
                    )
                }
            </div>

            <div className={classes.dispaly}>
                {
                    userVoting === -1 ? (
                        <IconButton className={classes.button} onClick={ev => onVote(null)}>
                            <img className={classes.likes} src={DislikeSelected} alt='-1' />
                        </IconButton>
                    ):(
                        <IconButton className={classes.button} onClick={ev => onVote(-1)}>
                            <img className={classes.likes} src={Dislike} alt='-1' />
                        </IconButton>
                    )
                }
                <Typography className={classes.number}>{down}</Typography>
            </div>

        </div>
    )
}

export default Voting;
