import React from 'react';

import Navbar from '../../components/navbar/navbar.component';
// import Classrooms from '../../components/classes/classrooms.component';
import ClassroomsP from '../../components/classes/classroomsP.conatainer';
import ClassNavTabs from '../../components/classes/class-nav-tabs.component';
import PostDetails from '../../components/posts/post-details.component';

import { useMainPageStyles } from './main-page.style';

const MainPage = ({ currentUser, locale }) => {

    // console.log('Main Page props', currentUser, locale);

    const classes = useMainPageStyles();

    return (
        <div>

            <Navbar />

            <div className={classes.main}>

                <div className={classes.classrooms}>
                    {/* <Classrooms /> */}
                    <ClassroomsP id={currentUser.idKey} />
                </div>

                <div className={classes.tabs}>
                    <ClassNavTabs locale={locale} />
                </div>

                <div className={classes.details}>
                    <PostDetails locale={locale} />
                </div>

            </div>

        </div>

    );
};

export default MainPage;
