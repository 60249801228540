import { makeStyles } from '@material-ui/core/styles';

export const useCustomModalStyles = makeStyles(theme => ({

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        // background: '#FFFFFF 0% 0% no-repeat padding-box',
        borderRadius: '12px',
        // opacity: 1,
        // paddingLeft: '44px',
        // paddingRight: '44px',
        // paddingBottom: '64px',
        // paddingTop: '24px',
        margin: 'unset',

        maxWidth: 'unset',
        maxHeight: '100%'
    },
    
}));