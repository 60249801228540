import { No } from '../models-core';
import { empty } from '../../utils/string.utils';

export class User {

    static name() {
        
        return 'users';

    }

    static path(idKey) {
        const name = User.name();
        return empty(idKey) ? `/${name}` : `/${name}/${idKey}`;
    }


    static field(idKey, name) {
        return `${User.path(idKey)}/${name}`;
    }

    static empty() {

        return {
            // idKey: No.Id,
            //RESTRUCTURE
            idKey: No.String,
            name: No.String,
            // images: {
            //     originalUrl: No.Url,
            //     thumbnailUrl: No.Url,
            // },
            classesStudent: {},
            classesTeacher: {},
            // language: 'he',
        };

    }

    static fromEmpty(merge) {
        return { ...User.empty(), ...merge };
    }

    static fromNew(merge) {
        return User.fromEmpty(merge);
    }

    static populates() {
        return [];
    }

}

