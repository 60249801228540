import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import NewFileEdit from '../common/file-edit/new-file-edit.component';
import NewInlineEdit from '../common/inline-edit/new-inline-edit';

import { idsOf } from '../../utils/object.utils';

import { EDIT_COMMENT } from  '../../localized-strings/edit-comment';

import { onUpdateCommentText } from './on-update-comment-text.handler';
import { onUpdateCommentFile } from './on-update-comment-file.handler';
import { onUpdateDeleteCommentFile } from './on-update-delete-comment-file.handler';

import { closeEditCommentPage } from '../../redux/edit-comment/edit-comment.actions';

import { useNewEditCommentStyles } from './new-edit-comment.styles';


const NewEditComment = ({ comment, closeEditCommentPage, postId, locale }) => {

    // console.log('Edit Comment Props', comment);

    const text = comment.value.text;
    const commentId = comment.key;
    const attId = idsOf(comment.value.attachments);

    const classes = useNewEditCommentStyles();

    const firebase = useFirebase();

    const [att, setAtt] = useState(attId[0]);
    const [file, setFile] = useState(null);
    const [editValue, setEditValue] = useState(comment.value.text);
    const [deleted, setDeleted] = useState(false);

    const strings = EDIT_COMMENT[locale];

    const popUpdir = locale === 'he'? 'rtl' : 'ltr';

    const onFile = files => {
        setDeleted(false);
        setAtt('');
        const file = files && files[0];
        setFile(file);
    };

    const onDelete = () => {
        setDeleted(true);
        setAtt('');
        setFile('');
    };
    
    const onChangeText = ev => {
        const { value } = ev.target;
        setEditValue(value);
    };

    const fileForEdit = () => {
        if(file){
            return onUpdateCommentFile(firebase, file, commentId, postId);
        } else {
            return null;
        }
    };

    const fileForDelete = () => {
        if(deleted){
            onUpdateDeleteCommentFile(firebase, commentId);
        } else {
            return null;
        }
    }

    const onConfirm = () => {        
        onUpdateCommentText(firebase, editValue, commentId);
        fileForEdit();
        fileForDelete();
        closeEditCommentPage();
    };

    const onClose = () => {
        setEditValue(text);
        setAtt(attId[0]);
        setFile(null);
        closeEditCommentPage();
    };

    const caractersLength = !editValue? 0 : editValue.length;
    const haveFile = !att && !file? false : true;
    const disabled = !editValue && !haveFile? true : false;


    return (

        <div dir={popUpdir} className={classes.editComment}>

            <Typography dir='auto' className={classes.title}>{strings.title}</Typography>

            <Divider className={classes.divider} />

            <div className={classes.inputsHolder}>

                <NewFileEdit
                    attId={att}
                    file={file}
                    onDelete={onDelete}
                    onFile={onFile}
                    strings={strings}
                />

                <div className={classes.inputHolder}>

                    <NewInlineEdit
                        autoFocus={true}
                        multiline={true}
                        value={editValue}
                        onChangeText={onChangeText}
                        strings={strings}
                    />

                </div>

                <div className={classes.caractersHolder}>

                    <Typography className={classes.caracters}>{`${caractersLength}/1024`}</Typography>

                </div>


                <div className={classes.buttons}>

                    <div className={classes.anonymous}>

                        <div>
                            <Button className={classes.cancelButton} onClick={onClose}>
                                {strings.cancelButton}
                            </Button>
                        </div>

                        <div>
                            <Button disabled={disabled} className={classes.saveButton} onClick={onConfirm}>
                                {strings.saveButton}
                            </Button>
                        </div>

                    </div>

                </div>

            </div>


        </div>

    )
};

const mapDispatchToProps = dispatch => ({
    closeEditCommentPage: () => dispatch(closeEditCommentPage())
});

export default connect(null, mapDispatchToProps)(NewEditComment);
