import { makeStyles } from '@material-ui/core/styles';

const font = {
    font: 'Regular Varela Round',
    letterSpacing: 0,
    color: '#011E23',
    opacity: 1,
};

export const usePreviewAttachmentStyles = makeStyles({

    // container: {
    //     height: '300px',
    //     width: '350px',
    //     display: 'flex',
    //     // alignItems: 'center',
    //     justifyContent: 'flex-end'
    // },
    
    // previewAttachment: {
    //     display: 'flex',
    //     alignItems: 'center',
    // },

    // icon :{
    //     margin: '16px',
    // },

    previewAttachmentDisplay: {
        display: 'flex',
        alignItems: 'center',
        background: '#FCFCFC 0% 0% no-repeat padding-box',
        border: '0.5px solid #011E23',
        borderRadius: '12px',
        opacity: 1,
        padding: '12px',
        width: 'fit-content',
    },

    image: {
        width: '40px',
        height: '52px',
    },

    text: {
        marginLeft: '20px',
    },

    fileName: {
        ...font,
        fontSize: '17px',
        // lineHeight: '6px',
    },

    fileSize: {
        ...font,
        fontSize: '13px',
        // lineHeight: '6px',
    },
    
});