import { updateFields } from '../../firebase/firebase.utils';

export const onReadPost = async(firebase, userId, postId, unreadPost) => {

    // console.log('Unread Post', postId, unreadPost);
    if(!unreadPost){
        return void 0;
    }


    console.log('onReadPost() > ()', userId, postId);

    try {

        if(!userId || !postId){
            console.warn('No Ids! Aborting updating ...');
            return void 0;
        }
       
        const path = `unread/${userId}`;

        const upData = {
            [postId]: null,
        };

        await updateFields(firebase, path, upData);

    } catch (e) {

        console.error('onUpdateQuestionDeleteImage() > catch{}', e);

    }

};