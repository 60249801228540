import { COMMON } from './common';

export const PROFILE_DISPLAY = {

    en: {
        title: 'Edit Profile',
        firstName: COMMON['en'].firstName,
        lastName: COMMON['en'].lastName,
        cancelButton: COMMON['en'].cancel,
        saveButton: COMMON['en'].save,
    },

    he: {
        title: 'ערוך פרופיל',
        firstName: COMMON['he'].firstName,
        lastName: COMMON['he'].lastName,
        cancelButton: COMMON['he'].cancel,
        saveButton: COMMON['he'].save,
    },

};
