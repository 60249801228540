import { uploadPostImage } from '../../crud/upload-post-image';
import { createPostAttachment } from '../../crud/create-post-attachment';
import { updateFields } from '../../firebase/firebase.utils'; 
import { getCurrentUserId } from '../../firebase/user.utils';
import { Post, PostByClass } from '../../models/domain/post';

export const onUpdateQuestionImage = async(firebase, image, classId, postId) => {


    console.log('onUpdateQuestionImage() > ()', image, classId, postId);

    try {

        if(!image) {
            console.warn('No Image! Aborting updating ...');
            return void 0;
        }

        if(!classId || !postId){
            console.warn('No Ids! Aborting updating ...');
            return void 0;
        }

        const updata = await uploadPostImage(firebase, image);
        console.log('onUpdateQuestionImage() > try{}', 'Question image uploaded: ', updata);

        const currentUserId = getCurrentUserId(firebase);
        // console.log('Curren User Id', currentUserId);

        const dataForAttachment = {author: currentUserId, ...updata}
        console.log('Data for creating attachment: ', dataForAttachment);

        const attachment = await createPostAttachment(firebase, dataForAttachment, postId);
        console.log('onUpdateQuestionImage() > try{}', 'Question attachment created: ', attachment);

       
        const now = firebase.database.ServerValue.TIMESTAMP;
        const path = `${postId}`;

        const upData = {
            significantAttachmentId: attachment.idKey,
            lastUpdatedUTime: now
        }

        await updateFields(firebase, Post.path(path), upData);

        await updateFields(firebase, PostByClass.path(classId, postId), upData);


    } catch (e) {

        console.error('onUpdateQuestionBody() > catch{}', e);

    }

};