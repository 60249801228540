export const FileType = {
    Image:   'IMAGE',
    Other:   'OTHER',
    Pdf:     'PDF',
    Video:   'VIDEO',
    Audio:   'AUDIO',
    General: 'GENERAL',
    Voice:   'VOICE',
    Doc:     'DOC',
    Xls:     'XLS',
    Ppt:     'PPT',
};


export const ContentType = {
    Image:    'image/',
    ImageAny: 'image/*',
    Audio:    'audio/',
    Video:    'video/',
    Any:      '*',
};
