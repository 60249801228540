import { makeStyles } from '@material-ui/core/styles';

const font = {
    fontFamily: 'Varela Round, sans-serif, Regular',
    color: '#011E23',
    letterSpacing: 0,
    opacity: 1,
};

export const useLeaveClassroomStylesR = makeStyles({

    leaveClassroom: {
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center', 
    },

    title: {
        ...font,
        fontSize: '24px',
        lineHeight: '29px',
        marginBottom: '24px',
        marginTop: '24px',
    },

    divider: {
        width: '100%',
    },

    leaveClassroomHolder: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '552px',
        paddingLeft: '44px',
        paddingRight: '44px',
        paddingBottom: '64px',
    },

    leaveMessage: {
        ...font,
        display: 'flex',
        alignSelf: 'flex-start',
        fontSize: '17px',
        lineHeight: '21px',
        marginBottom: '22px',
        marginTop: '48px',
    },

    classroomHolder: {
        width: '552px',
        background: '#FCFCFC 0% 0% no-repeat padding-box',
        border: '1px solid #011E23',
        borderRadius: '12px',
        opacity: 1,
        marginBottom: '64px',
    },

    classroom: {
        marginTop: '16px',
        marginBottom: '16px',
        marginLeft: '20px',
        marginRight: '20px',
        display: 'flex',
        alignItems: 'center',
    },

    classroomImage: {
        width: '48px;',
        height: '48px',
        borderRadius: '12px',
        opacity: 1,
    },

    classroomTitle: {
        ...font,
        fontSize: '16px',
        lineHeight: '20px',
        // marginLeft: '12px',
        marginRight: '12px',
    },

    buttons: {
        display: 'flex',
        alignSelf: 'flex-end',
    },

    cancelButton: {
        ...font,
        fontSize: '16px',
        color: '#011E23',
        opacity: 1,
        borderRadius: '12px',
        width: '132px',
        textTransform: 'unset',
    },
    
    leaveButton: {
        ...font,
        fontSize: '16px',
        color: '#011E23', //'#FFFFFF',
        background: '#E8E9EB 0% 0% no-repeat padding-box', //'#DA2626 0% 0% no-repeat padding-box',
        borderRadius: '12px',
        opacity: 1,
        textTransform: 'unset',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#E8E9EB', //'#DA2626',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
        },
        width: '132px'
    },

});
