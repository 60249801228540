import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { StyledMenu, StyledMenuItem } from '../common/styled-menu/styled-menu.component';

import { getCurrentUserId } from '../../firebase/user.utils';

import { openReplyToCommentPage } from '../../redux/reply-to-comment/reply-to-comment.actions';
import { openDeleteCommentPage } from '../../redux/delete-comment/delete-comment.actions';
import { openEditCommentPage } from '../../redux/edit-comment/edit-comment.actions';


const CommentOptionsButton = ({ openDeleteCommentPage, openReplyToCommentPage, openEditCommentPage, comment, id, strings, edit }) => {

    // console.log('Comment options button', comment, id, edit);

    const firebase = useFirebase();

    const [anchorEl, setAnchorEl] = useState(null);

    const onClick = event => { setAnchorEl(event.currentTarget) };

    const onClose = () => { setAnchorEl(null) };

    const onClicReply = () => {
        openReplyToCommentPage(comment);
        setAnchorEl(null);
    };

    const onClickEdit = () => {
        openEditCommentPage(comment);
        setAnchorEl(null);
    };

    const onClickDelete = () => {
        openDeleteCommentPage(comment);
        setAnchorEl(null);
    };

    const commentAuthor = comment.value.author;
    // console.log('Comment Author', commentAuthor);

    const currentUserId = getCurrentUserId(firebase);
    // console.log('Curren User Id', currentUserId);

    const sameUserOptions = (commentAuthor === currentUserId)? true : false;
    const showOptions = edit || sameUserOptions;
    // console.log('Show options button', showOptions);

    return (
        <div>

            <IconButton 
                style={{padding: 'unset', width: '1.25vw', height: '1.25vw',}}
                aria-controls={id}
                aria-haspopup="true"
                onClick={onClick}
            >
                <MoreVertIcon style={{fontSize: '1.25vw'}} />
            </IconButton>
        
            <StyledMenu 
                id={id}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onClose}
            >
                <StyledMenuItem onClick={onClicReply}>{strings.replyOption}</StyledMenuItem>
                {
                    showOptions? (
                        <div>
                            <StyledMenuItem onClick={onClickEdit}>{strings.editOption}</StyledMenuItem>
                            <StyledMenuItem onClick={onClickDelete}>{strings.deleteOption}</StyledMenuItem>
                        </div>
                    ): null
                }
            </StyledMenu>
            
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    openDeleteCommentPage: onComment => dispatch(openDeleteCommentPage(onComment)),
    openReplyToCommentPage: onComment => dispatch(openReplyToCommentPage(onComment)),
    openEditCommentPage: onComment => dispatch(openEditCommentPage(onComment)),

});

export default connect(null,mapDispatchToProps)(CommentOptionsButton);
