import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFirebase } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

import ReplyToCommentPage from '../../pages/reply-to-comment-page/reply-to-comment-page.componet';
import EditCommentPage from '../../pages/edit-comment-page/edit-comment-page.component';
import DeleteCommentPage from '../../pages/delete-comment-page/delete-comment-page.component';
import NewCommentDisplay from './new-comment-display.component';
import AnonymousNewCommentDisplay from './anonymous-new-comment-display.comonent';

import { getCurrentUserId } from '../../firebase/user.utils';

import { NEW_COMMENT } from '../../localized-strings/new-comment';

import { toggleCommentAnonymous, commentMessage } from '../../redux/new-comment/new-comment.actions';
import { selectCommentAnonymouseState, selectCommentMessage } from '../../redux/new-comment/new-comment.selector';
import { selectLocale } from '../../redux/localization/localization.selector';

import { onCreateComment } from './on-create-new-comment.handler';

import { useNewCommentStyles } from './new-comment.styles';


const NewComment = ({ match, toggleCommentAnonymous, commentAnonymouseState, commentMessage, onCommentMessage, locale }) => {

    // console.log('NewComment props', match, toggleCommentAnonymous, commentAnonymouseState, commentMessage, onCommentMessage);
    const classes = useNewCommentStyles();

    const firebase = useFirebase();

    const strings = NEW_COMMENT[locale];

    const [comment, setComment] = useState(commentMessage);
    // console.log('Comment', comment);

    useEffect(() => {
        onCommentMessage(comment)
    }, [onCommentMessage, comment]);
 
    const onEmojiClick = (ev, emojiObject) => {

        // console.log('Emoji object', emojiObject, ev);
        setComment(comment => (comment + emojiObject.emoji));
        
    };

    const onChange = ev => {

        const { value } = ev.target;

        if(value[0] === ' '){
            setComment('');
        }else{
            setComment(value);
            onCommentMessage(value);
        }
        
    };

    const onSend = () => {

        const currentUserId = getCurrentUserId(firebase);
        // console.log('Curren User Id', currentUserId);

        const data = {
            text: commentMessage, //comment,
            author: currentUserId,
            class: match.params.classId,
            post: match.params.postId,
            createdTime: firebase.database.ServerValue.TIMESTAMP,
            lastUpdatedUTime: firebase.database.ServerValue.TIMESTAMP,
            anonymous: commentAnonymouseState,
        };

        onCreateComment(firebase, data);

        setComment('');
        onCommentMessage('')

    };

    const onKeyPress = (ev) => {
        
        if (ev.key === "Enter" && ev.shiftKey) {
            // ev.preventDefault();
            setComment(comment => (comment + '\n'));

        }
        if ('Enter' === ev.key && !ev.shiftKey) {
            ev.preventDefault();
            onSend();
        }
    };
 

    return (

        <div className={classes.newComment}>
            {/* must be placed somewhere */}
            <ReplyToCommentPage />
            <EditCommentPage />
            <DeleteCommentPage />
            {
                commentAnonymouseState ? (
                    <AnonymousNewCommentDisplay
                        onChange={onChange}
                        comment={commentMessage} // {comment}
                        onKeyPress={onKeyPress}
                        onAnonymous={toggleCommentAnonymous}
                        onSend={onSend}
                        onEmojiClick={onEmojiClick}
                        strings={strings}
                        locale={locale}
                    />
                ):(
                    <NewCommentDisplay
                        onChange={onChange}
                        comment={commentMessage} // {comment}
                        onKeyPress={onKeyPress}
                        onAnonymous={toggleCommentAnonymous}
                        onSend={onSend}
                        onEmojiClick={onEmojiClick}
                        strings={strings}
                        locale={locale}
                    />
                )

            }

        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    commentAnonymouseState: selectCommentAnonymouseState,
    commentMessage: selectCommentMessage,
    locale: selectLocale,
})

const mapDispatchToProps = dispatch => ({
    toggleCommentAnonymous: () => dispatch(toggleCommentAnonymous()),
    onCommentMessage: message => dispatch(commentMessage(message)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewComment));
