import { object } from '../utils/object.utils';
import { No } from '../models/models-core';


export const uploadUserImage = async (firebase, image, userId) => {
        
        if (!image) {

            console.warn('uploadUserImage', 'no valid image', image);

            return {
                contentType: No.ContentType,
                size:        No.Number,
                url:         No.Url,
            };
        }

        const folder = userId;
        const uresult = await firebase.uploadFile(`userImage/${folder}/origin`, image);

        const snapshot = object(uresult.uploadTaskSnapshot);
        const metadata = object(snapshot.metadata);

        console.log('uploadUserImage()', {snapshot, metadata, uresult}, '...');

        // const urrl = await snapshot.ref.getDownloadURL();


        return {
            contentType: metadata.contentType,
            size:        metadata.size,
            url:         snapshot.ref && await snapshot.ref.getDownloadURL(),
            snapshot,
            metadata,
            uresult,
        };

}
