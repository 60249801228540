export const COMMON = {

    en: {
        logout: 'Logout',
        firstName: 'First Name',
        lastName: 'Last Name',
        cancel: 'Cancel',
        save: 'Save',
        edit: 'Edit',
        add: 'Add',
        writeAnonymously: 'Write Anonymously',
        selectClassMessage: 'Please select a Channel',
        addFileButton: '+ Add New File',
        selectFile: 'Select a File...',
        addMessage: 'Add a message',
        urlString: 'external link content',
        urlHeaderString: 'click to open the link',
        urlMessage: 'Currrently only Youtube links are supported on the web. for other link types please use the mobile application',
    },

    he: {
        logout: 'התנתק',
        firstName: 'שם פרטי',
        lastName: 'שם משפחה',
        cancel: 'ביטול',
        save: 'שמור',
        edit: 'ערוך',
        add: 'הוסף',
        writeAnonymously: 'השב באופן אנונימי',
        selectClassMessage: 'אנא בחר ערוץ',
        addFileButton: '+ הוסף קובץ',
        selectFile: 'בחר קובץ...',
        addMessage: 'הוסף תגובה',
        urlString: 'הצג תוכן מעמוד אחר',
        urlHeaderString: 'לחצו כאן לפתיחת הקישור בעמוד זה',
        urlMessage: 'כרגע רק לינקים לסירטוני יוטיוב נתמכים. כדי לייצר לינק לאתר אחר אנא השתמש באפליקציית המובייל',
    },

}; 
