import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Button from '@material-ui/core/Button';
import { ExpandMore } from '@material-ui/icons';

import { StyledMenu, StyledMenuItem } from '../common/styled-menu/styled-menu.component';

import { LANGUAGE_SELECT } from '../../localized-strings/language-select';

import { selectLocale } from '../../redux/localization/localization.selector';
import { setLocaleEn, setLocaleHe } from '../../redux/localization/localization.actions';

import { useLanguageSelectStyles } from './language-select.styles'; 

const LanguageSelect = ({ locale, setLocaleEn, setLocaleHe }) => {

    const classes = useLanguageSelectStyles();

    const strings = LANGUAGE_SELECT[locale];
    const language = locale === 'he'? strings.hebrew : strings.english; 

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const onMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const onEn =() => {
        setLocaleEn();
        onClose()
    };

    const onHe =() => {
        setLocaleHe();
        onClose();
    };


    return (
        <div>

            <Button
                onClick={onMenu}
                className={classes.button}
            >
                {language}
                <ExpandMore />
            </Button>

            <StyledMenu
                    id="language"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={onClose}
                >
                    <StyledMenuItem onClick={onEn}>{strings.english}</StyledMenuItem> 
                    <StyledMenuItem onClick={onHe}>{strings.hebrew}</StyledMenuItem>
                </StyledMenu>

        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    locale: selectLocale,
});

const mapDispatchToProps = dispatch => ({
    setLocaleEn: () => dispatch(setLocaleEn()),
    setLocaleHe: () => dispatch(setLocaleHe()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelect);
