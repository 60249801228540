import { No } from '../models-core';
import { User } from './user';
import { empty } from '../../utils/string.utils';


export class Class {

    static name() {
        return 'classes';
    }


    static path(idKey) {

        const name = Class.name();
        return empty(idKey) ? `/${name}` : `/${name}/${idKey}`;
    }


    static field(idKey, name) {
        return `${Class.path(idKey)}/${name}`;
    }


    static empty() {

        return {
            idKey: No.Id,
            images: {
                originalUrl: No.Url,
                thumbnailUrl: No.Url,
            },
            imageUpdatedUTime: No.Timestamp,
            imageUrl: No.Url,
            organization: No.String,
            title: No.String,
            updatedUTime: No.Timestamp,
            users: {},
        };

    }


    static fromEmpty(merge) {

        return { ...Class.empty(), ...merge };

    }


    static fromNew(merge) {

        return Class.fromEmpty(merge);

    }


    static populates() {

        return [
            {
                child: 'users',
                root: User.path(),
                childAlias: 'userItemSet',
                populateByKey: true,
            },
        ];

    }

}
