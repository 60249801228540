import { LocalizationActionTypes } from './localization.types';

const INITIAL_STATE = {

    // DEFAULT STATE
    // locale: 'en',
    locale: 'he',
};

const localizationReducer = (state = INITIAL_STATE, action) => {

    if(action.type === LocalizationActionTypes.LOCALE_SPECIFIC_EN){
        return {
            ...state,
            locale: 'en'
        };
    };

    if(action.type === LocalizationActionTypes.LOCALE_SPECIFIC_HE){
        return {
            ...state,
            locale: 'he',
        }
    };

    return state;
};

export default localizationReducer;