import React, { useState } from 'react';
import { useFirebase } from 'react-redux-firebase';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

import camera from '../../assets/camera.svg';

import { getCurrentUserId } from '../../firebase/user.utils';
import { FilePick } from '../../components/common/files-input/files.pick.component';
import { usePickedPhoto } from '../../utils/files/picked-photo.utils';

import { SIGN_UP } from '../../localized-strings/sign-up';

import { onCreateNewUser } from './sign-up-handler';

import { useSignUpStyles, imageStyle, iconStyle } from './sign-up.styles';

const SignUp = ({ locale }) => {

    // console.log('Sign Up props', locale);

    const classes = useSignUpStyles();

    const firebase = useFirebase();

    const [file, setFile] = useState(null);
    const [fName, setFname] = useState('');
    const [lName, setLname] = useState('');

    const imagePreviewUrl = usePickedPhoto(file);

    const onFile = files => {
        const file = files && files[0];
        setFile(file)
    };


    const onChangeFname = ev => {

        const { value } = ev.target;

        if(value[0] === ' '){
            setFname('');
        }else{
            setFname(value);
        }
        
    };

    const onChangeLname = ev => {

        const { value } = ev.target;

        if(value[0] === ' '){
            setLname('');
        }else{
            setLname(value);
        }
        
    };

    const onSignUp = () => {
        console.log('On Register', file, fName, lName);

        const currentUserId = getCurrentUserId(firebase);
        // console.log('Curren User Id', currentUserId);

        const data = {file: file, firstName: fName, lastName: lName, idKey: currentUserId};

        onCreateNewUser(firebase, data);

    }
    
    const disabled = (!fName || !lName)? true : false;

    const strings = SIGN_UP[locale];
    
    const direction = locale === 'he'? 'rtl' : 'auto';


    return(
        <div className={classes.signUp}>

            <Typography dir='auto' className={classes.title}>{strings.title}</Typography>
            
            <Divider className={classes.divider} />

            <div className={classes.inputsHolder}>

                <div dir='ltr'>
                    {
                        file ?
                            (
                                <div> 
                                    <div className={classes.imageHolder}>

                                        <img style={imageStyle} src={imagePreviewUrl} alt="img preview" />
                                        <FilePick
                                            accept="image/*"
                                            onPick={onFile}
                                            content={() =>
                                                (
                                                    <div className={classes.iBbutton}>
                                                        <Button className={classes.imageButton} component="span">
                                                            <img src={camera} alt='button' style={iconStyle} />
                                                        </Button>
                                                    </div>
                                                )
                                            }
                                        />

                                    </div>

                                    

                                </div>
                            ) :
                            (
                                <div className={classes.holder}>
                                    <FilePick
                                        accept="image/*"
                                        onPick={onFile}
                                        content={() =>
                                            (
                                                <Button className={classes.imageButton} component="span">
                                                    <img src={camera} alt='button' style={iconStyle}/>
                                                </Button>
                                            )
                                        }
                                    />
                                </div>
                            )
                    }
                </div>

                <div className={classes.inputHolder}>
                    <Input
                        className={classes.input}
                        fullWidth
                        disableUnderline
                        placeholder={strings.firstName}
                        inputProps={{
                            'aria-label': 'first-name',
                            dir: {direction},
                            maxLength: 30,
                        }}
                        onChange={onChangeFname}
                        value={fName}
                        required
                    />
                </div>

                <div className={classes.inputHolder}>
                    <Input
                        label='Last Name'
                        className={classes.input}
                        fullWidth
                        disableUnderline
                        placeholder={strings.lastName}
                        inputProps={{
                            'aria-label': 'last-name',
                            dir: {direction},
                            maxLength: 30,
                        }}
                        onChange={onChangeLname}
                        value={lName}
                        required
                        error
                    />
                </div>

                <div className={classes.button}>
                    <Button className={classes.signUpButton} onClick={onSignUp} disabled={disabled}>
                        {strings.signUpButton}
                    </Button>
                </div>

            </div>

        </div>
    )
    
};

export default SignUp;
