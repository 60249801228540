import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import axios from "axios";

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { firebaseConfig } from '../../config/keys';
import { dynamicLinksData } from '../../config/dynamic-links-data';

import { INVITE_CLASSMATE } from '../../localized-strings/invite-classmate';

import { closeInviteClassmatePage } from '../../redux/invite-classmate/invite-classmate.actions';

import { useInviteClassmateStyles } from './invite-classmate.styles';
import { useInviteClassmateStylesR } from './invite-classmate-r.styles';


const InviteClassmate = ({ classroom, closeInviteClassmatePage, locale }) => {

    // console.log('Leave Classsrom props', classroom);

    const classesL = useInviteClassmateStyles();
    const classesR = useInviteClassmateStylesR();
    const classes = locale === 'he'? classesR : classesL;

    const [link, setLink] = useState({});

    const linkData = dynamicLinksData(classroom.idKey);

    useEffect(() => {

        const ourRequest = axios.CancelToken.source()

        const getDynamicLink = async () => {

            try {

                const link = await axios({

                    method: 'post',
                    url: `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseConfig.apiKey}`,
                    headers: {
                        'Content-Type': 'application/json',
                    },

                    data: linkData,

                    cancelToken: ourRequest.token,

                });

                // console.log('Link', link);
                setLink(link);

            } catch (e) {
                if (axios.isCancel(e)) {
                    console.log("cancelled");
                } else {
                    console.error('getLink() > catch{}', e);
                }
            }

        };

        if (link === undefined || Object.keys(link).length === 0) {
            getDynamicLink();
        }

        return () => {
            ourRequest.cancel();
        };

    }, [linkData, link]);


    const images = classroom.images;
    const image = images.originalUrl || images.thumbnailUrl || classroom.imageUrl || null;

    const strings = INVITE_CLASSMATE[locale];

    const popUpdir = locale === 'he'? 'rtl' : 'ltr';
    
    
    if (link === undefined || Object.keys(link).length === 0) {
        return (
            <div dir={popUpdir} className={classes.inviteClassmate}>

                <Typography dir='auto' className={classes.title}>{strings.title}</Typography>

                <Divider className={classes.divider} />

                <div className={classes.inviteClassmateHolder}>
                    <CircularProgress size={60} />
                </div>
            </div>
        )
    };

    const dynamicLinks = link.data.shortLink || null

    return (

        <div dir={popUpdir} className={classes.inviteClassmate}>

            <Typography dir='auto' className={classes.title}>{strings.title}</Typography>

            <Divider className={classes.divider} />

            <div className={classes.inviteClassmateHolder}>

                <Typography className={classes.inviteMessage}>
                    {strings.inviteMessage}
                </Typography>

                <div className={classes.classroomHolder}>
                    <div className={classes.classroom}>
                        <img className={classes.classroomImage} src={image} alt='' />
                        <Typography dir='auto' className={classes.classroomTitle}>{classroom.title}</Typography>
                    </div>
                </div>

                <Typography className={classes.linkMessage}>
                    {strings.linkMessage}
                </Typography>

                <div className={classes.linkHolder}>
                    <Typography className={classes.link}>{dynamicLinks}</Typography>
                </div>

                <div className={classes.buttons}>

                    <Button className={classes.doneButton} onClick={closeInviteClassmatePage}>
                        {strings.doneButton}
                    </Button>

                </div>

            </div>

        </div>
    )
};

const mapDispatchToProps = dispatch => ({
    closeInviteClassmatePage: () => dispatch(closeInviteClassmatePage())
});

export default connect(null, mapDispatchToProps)(InviteClassmate);
