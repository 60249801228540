import { makeStyles } from '@material-ui/core/styles';

export const useEditQuestionPageStyles = makeStyles({

    button: {
        padding: 'unset',
        width: '1.25vw',
        height: '1.25vw',
    },

    icon: {
        fontSize: '1.25vw',
    },

});
