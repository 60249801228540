import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import IconButton from '@material-ui/core/IconButton';
import AttachIcon from '../../assets/attachIcon.svg';

import CustomModal from '../../components/common/custom-modal/custom-modal.component';
import NewClassCommentAttach from '../../components/new-class-comment/new-class-comment-attach.component';

import { toggleNewClassCommentPageHidden } from '../../redux/new-class-comment/new-class-comment.actions';
import { selectNewClassCommentPageState } from '../../redux/new-class-comment/new-class-comment.selector';
import { selectLocale } from '../../redux/localization/localization.selector';

import { useNewCommentAttachPageStyles } from '../new-comment-page/new-comment-attach-page.styles';


const NewClassCommentAttachPage = ({newClassCommentHidden, toggleNewClassCommentPageHidden, locale }) => {

    const classes = useNewCommentAttachPageStyles();

    return (
        <div>

            <IconButton className={classes.iconButtons} onClick={toggleNewClassCommentPageHidden}>
                <img className={classes.icon} src={AttachIcon} alt='button' />
            </IconButton>

            <CustomModal
                open={newClassCommentHidden}
                onClose={toggleNewClassCommentPageHidden}
                content={<NewClassCommentAttach locale={locale} />}/>

        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    newClassCommentHidden: selectNewClassCommentPageState,
    locale: selectLocale,
})


const mapDispatchToProps = dispatch => ({
    toggleNewClassCommentPageHidden: () => dispatch(toggleNewClassCommentPageHidden())
});

export default connect(mapStateToProps, mapDispatchToProps)(NewClassCommentAttachPage);
