import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import { StyledTabs, StyledTab } from './class-nav-tabs.styles';

import Posts from '../posts/posts.component';
import Classmates from './classmates.component';
import ClassChat from '../class-chat/clas-chat.component';
import NewQuestionPage from '../../pages/new-question-page/new-question-page.component';
import NewClassCommentInput from '../class-chat/new-class-comment-input.component';

import { CLASS_NAV_TABS } from '../../localized-strings/class-nav-tabs';

import { useClasNavStyles } from './class-nav-tabs.styles';


const TabPanel = (props) => {

    const { children, value, index, ...other } = props;

    const classes = useClasNavStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} className={classes.box}>
                    <div className={classes.boxc}>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};


const ClassNavTabs = ({ match, locale }) => {

    // console.log('Class Nav Tabs Props', match);

    const classId = match.params.classId;

    const classes = useClasNavStyles();

    const [value, setValue] = React.useState(0);

    const strings = CLASS_NAV_TABS[locale];

    const onChange = (event, newValue) => {
        setValue(newValue);
    };
    

    return (
        <div className={classes.classNavTabs}>

            <div className={classes.tabs}>
                <StyledTabs
                    variant="fullWidth"
                    value={value}
                    onChange={onChange}
                    aria-label="simple tabs"
                >
                    <StyledTab label={strings.questions} {...a11yProps(0)} />
                    <StyledTab label={strings.classChat} {...a11yProps(1)} />
                    <StyledTab label={strings.classmates} {...a11yProps(2)} />
                </StyledTabs>
            </div>

            <div className={classes.tabPanels}>
                <TabPanel className={classes.tabPanel} value={value} index={0}>
                    <NewQuestionPage />
                    <Posts classId={classId} />
                </TabPanel>
            
                <TabPanel className={classes.tabPanel} value={value} index={1}>
                    <ClassChat classId={classId} />
                    <div className={classes.newCommentInput}>
                        {
                            classId === ':classId'?(
                                null
                            ):(
                                <NewClassCommentInput classId={classId} locale={locale} />
                            )
                        }
                    </div>
                </TabPanel>
            
                <TabPanel className={classes.tabPanel} value={value} index={2}>
                    <Classmates classId={classId} />
                </TabPanel>
            </div>

        </div>
    );

};

export default withRouter(ClassNavTabs);
