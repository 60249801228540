import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase';

import Typography from '@material-ui/core/Typography';

import PostDisplay from './post-display.component';

import { getCurrentUserId } from '../../firebase/user.utils';
import { trackCoool } from '../../utils/cooladata.utils';
import { trackFirebase } from '../../utils/firebase-analytics.utils';

import { selectCurrentUserView } from '../../redux/user/user.selector';

import { onReadPost } from './on-read-post.handler';

import { usePostStyles } from './post.styles';
import { StyledBadgeRead, StyledBadgeUnread } from './badge.styles';
import { StyledBadgeReadR, StyledBadgeUnreadR } from './badge-r.styles';


const Post = ({ post, view, locale }) => {

    // console.log('Post props', post, view, locale);

    const author = post.value.author;
    const attId = post.value.significantAttachmentId || null;
    const anonymous = post.value.anonymous || null;
    const body = post.value.body || null;
    const type = post.value.type || null;
    const url = post.value.url || null;
    
    const postId = post.key;

    const classes = usePostStyles();

    const firebase = useFirebase();

    const currentUserId = getCurrentUserId(firebase);
    // console.log('Curren User Id', currentUserId);

    useFirebaseConnect([`unread/${currentUserId}`], [currentUserId]); // sync from firebase into redux

    const unread = useSelector(state => {
        return state.firebase.data.unread && state.firebase.data.unread[currentUserId] && state.firebase.data.unread[currentUserId][postId];
    });

    const unreadPost = (unread || unread === 0)? true : false;

    const eventParametars = {
        user_id: currentUserId,
        class_Id: post.value.classId,
        post_id: post.key,
        post_body: post.value.body || null,
    };

    const onClick = () => {
        trackCoool('post_read', eventParametars);
        trackFirebase('post_read', eventParametars);
        trackCoool('comments_page_open', eventParametars);
        trackFirebase('comments_page_open', eventParametars);

        onReadPost(firebase, currentUserId, postId, unreadPost);
    };
    
    return(
    
        <div style={{width: 'inherit'}}>
            {
                locale === 'he'? (
                    <div>
                    {
                        unreadPost? (
                            <StyledBadgeUnreadR badgeContent={<Typography className={classes.postCommentsUnread}>{post.value.commentsCount}</Typography>} >
                                <PostDisplay
                                    onClick={onClick}
                                    attId={attId}
                                    body={body}
                                    anonymous={anonymous}
                                    view={view}
                                    author={author}
                                    locale={locale}
                                    type={type}
                                    url={url}
                                />
                            </StyledBadgeUnreadR>
                        ):(
                            <StyledBadgeReadR badgeContent={<Typography className={classes.postComments}>{post.value.commentsCount}</Typography>} >
                                <PostDisplay
                                    onClick={onClick}
                                    attId={attId}
                                    body={body}
                                    anonymous={anonymous}
                                    view={view}
                                    author={author}
                                    locale={locale}
                                    type={type}
                                    url={url}
                                />
                            </StyledBadgeReadR>
                        )
                    }
                    </div>
                ):(
                    <div>
                    {
                        unreadPost? (
                            <StyledBadgeUnread badgeContent={<Typography className={classes.postCommentsUnread}>{post.value.commentsCount}</Typography>} >
                                <PostDisplay
                                    onClick={onClick}
                                    attId={attId}
                                    body={body}
                                    anonymous={anonymous}
                                    view={view}
                                    author={author}
                                    locale={locale}
                                    type={type}
                                    url={url}
                                />
                            </StyledBadgeUnread>
                        ):(
                            <StyledBadgeRead badgeContent={<Typography className={classes.postComments}>{post.value.commentsCount}</Typography>} >
                                <PostDisplay
                                    onClick={onClick}
                                    attId={attId}
                                    body={body}
                                    anonymous={anonymous}
                                    view={view}
                                    author={author}
                                    locale={locale}
                                    type={type}
                                    url={url}
                                />
                            </StyledBadgeRead>
                        )
                    }
                    </div>
                )
            }            
        </div>

    )
    
};

const mapStateToProps = createStructuredSelector({
    view: selectCurrentUserView
});

export default connect(mapStateToProps)(Post);
