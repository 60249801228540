import { COMMON } from './common';

export const SIGN_UP = {

    en: {
        title: 'Sign Up',
        firstName: COMMON['en'].firstName,
        lastName: COMMON['en'].lastName,
        signUpButton: 'Sign Up',
    },

    he: {
        title: 'הרשמה',
        firstName: COMMON['he'].firstName,
        lastName: COMMON['he'].lastName,
        signUpButton: 'הירשם',
    },

};
