import { createSelector } from 'reselect';

const selectInviteClassmatePage = state => state.inviteClassmatePage;

export const selectVisible = createSelector(
    [selectInviteClassmatePage],
    inviteClassmatePage => inviteClassmatePage.visible
);

export const selectOnClass = createSelector(
    [selectInviteClassmatePage],
    inviteClassmatePage => inviteClassmatePage.onClass
);
