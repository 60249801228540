import { makeStyles } from '@material-ui/core/styles';

export const useNewCommentAttachPageStyles = makeStyles({

    iconButtons: {
        height: '2.5vw',
        width: '2.5vw',
        padding: '0.625vw',
        fontSize: '1.25vw',
    },

    icon: {
        height: '1.25vw',
        width: '1.25vw',
    },
    
});