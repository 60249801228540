import React from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import anonymousImage from '../../assets/anonymousSmall.svg';

import ImageInputAndDisplay from './image-input-and-display.component';
// import TypeSelector from './type-selector.component';
// import DatePicker from './date-picker.component';
import QuestionInput from './question-input.component';

import { useNewQuestionStyles } from './new-question-display.styles';
import { useNewQuestionStylesR } from './new-question-display-r.styles';


const NewQuestionDisplay = ({ file, imagePreviewUrl, onFile, type, onType, selectedDate, onDateChange,
    onChange, question, onAnonymous, disabled, disabledWithdate, onCreate, onClose, strings, locale }) => {

    const classesL = useNewQuestionStyles();
    const classesR = useNewQuestionStylesR();
    const classes = locale === 'he'? classesR : classesL;

    const popUpdir = locale === 'he'? 'rtl' : 'ltr';
    

    return (
        <div dir={popUpdir} className={classes.newQuestion}>

            <Typography dir='auto' className={classes.title}>{strings.title}</Typography>

            <Divider className={classes.divider} />

            <div className={classes.inputsHolder}>

                <ImageInputAndDisplay file={file} imagePreviewUrl={imagePreviewUrl} onFile={onFile} />

                {/* <TypeSelector type={type} onType={onType} strings={strings} locale={locale} />

                <DatePicker type={type} selectedDate={selectedDate} onDateChange={onDateChange} strings={strings} locale={locale} /> */}

                <QuestionInput onChange={onChange} question={question} strings={strings} locale={locale} />

                <div className={classes.caractersHolder}>

                    <div className={classes.anonymous}>

                        <Button className={classes.anonymousButton} component='span' onClick={onAnonymous}>
                            <img src={anonymousImage} alt='abutton' />
                        </Button>

                        <Typography className={classes.caracters}>{strings.writeAnonymously}</Typography>

                    </div>

                    <div>
                        <Typography className={classes.caracters}>{`${question.length}/1024`}</Typography>
                    </div>

                </div>

                <div className={classes.buttons}>

                    <div>
                        <Button className={classes.cancelButton} onClick={onClose}>
                            {strings.cancelButton}
                        </Button>
                    </div>

                    <div>
                        {
                            (type === 'QUESTION') ? (
                                    <Button disabled={disabled} className={classes.addButton} onClick={onCreate}>
                                        {strings.addButton}
                                    </Button>
                                ):(
                                    <Button disabled={disabledWithdate} className={classes.addButton} onClick={onCreate}>
                                        {strings.addButton}
                                    </Button>
                                )
                        }
                    </div>

                </div>

            </div>

        </div>
    )
};

export default NewQuestionDisplay;
