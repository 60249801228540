import firebase from '../firebase/firebase.utils'
// import { firestore } from '../firebase/firebase.utils'
import { updatePostsCollection, newIdKey } from '../firebase/firebase.utils';
import { Post } from '../models/domain/post';

// export const createTodo = (data) => {

//     console.log('Data for creating', data);

//     // const exampleTodo = { done: false, text: 'Sample' }
//     const now = Date.parse(firebase.firestore.FieldValue.serverTimestamp())

//     const dataForPost = {
//         author: data.user,
//         body: data.question,
//         classId: data.classId,
//         createdUTime: now,
//         lastUpdatedUTime: now,
//     } 
    
//     return firestore.collection('todos').add(dataForPost)

// }

// Add a new document in collection "cities"

export const createPostFirestore = (
    async (data) => {

        // if (!data.classId) {
        //     console.error('createPost()', 'No classId in', data, 'Aborting creation process');
        //     return void 0;
        // }

        // if (!data.idKey) {
        //     console.warn('createPost()', 'No postId in', data, 'Creating new...');
        //     // const newPostIdKey = newIdKey(firebase);
        //     // return newPostIdKey;
        // }

        // if (!data.body) {
        //     console.error('createPost()', 'no Question in', data, 'Aborting creation process');
        //     return void 0;
        // }
        // try {
        //     await firestore.collection('todos').doc(`${data.idKey}`).set({data}, { merge: true })
        //     console.log("Document successfully written!");
        // } catch (e) {
        //     console.error("Error writing document: ", e);
        // }

        // return firestore.collection('todos').doc(data.idKey).set({data}, { merge: true })

        if (!data.classId) {
            console.error('createPost()', 'No classId in', data, 'Aborting creation process');
            return void 0;
        }

        if (!data.idKey) {
            console.warn('createPost()', 'No postId in', data, 'Creating new...');
            // const newPostIdKey = newIdKey(firebase);
            // return newPostIdKey;
        }

        if (!data.body) {
            console.error('createPost()', 'no Question in', data, 'Aborting creation process');
            return void 0;
        }


        const postId = data.idKey || newIdKey(firebase);
        const post = Post.fromNew({ 
            ...data, 
            idKey: postId,
            isPrivate: false,
            lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            created: firebase.firestore.FieldValue.serverTimestamp(),
        });

        await updatePostsCollection(post)

        return post

    }
)
// db.collection("cities").doc("LA").set({
//     name: "Los Angeles",
//     state: "CA",
//     country: "USA"
// })
// .then(function() {
//     console.log("Document successfully written!");
// })
// .catch(function(error) {
//     console.error("Error writing document: ", error);
// });

