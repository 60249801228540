import { ClassroomSettingsActionTypes } from './classroom-settings.types';

const INITIAL_STATE = {
    visible: false,
    onClass: null
};

const classromSettingsPageReducer = (state = INITIAL_STATE, action) => {

    if(action.type === ClassroomSettingsActionTypes.OPEN_CLASSROOM_SETTINGS_PAGE){
        return {
            ...state,
            visible: true,
            onClass: action.payload,
        }
    }

    if(action.type === ClassroomSettingsActionTypes.CLOSE_CLASSROOM_SETTINGS_PAGE){
        return {
            ...state,
            visible: false,
        }
    };

    return state;
};

export default classromSettingsPageReducer;
