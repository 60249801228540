import React from 'react';
import Typography from '@material-ui/core/Typography';

import Author from '../common/author/author.component';
import Attachment from '../common/attachment/attachment.component';

import moment from 'moment';
import Linkify from 'react-linkify';

import { useClassCommentDisplayStyles,
    authorImageStyleL,
    authorNoName,
    authorNoimage,
    authorNameStyle,
    attImageStyle,
    linkText
} from './class-comment-display.styles';

import { useClassCommentDisplayRStyles, authorImageStyleR } from './class-comment-display-r.styles';


const ClassCommentDispaly = ({classComment, attId, author, locale}) => {

    // console.log('Class Dispaly props', classComment, attId, author);

    const classesL = useClassCommentDisplayStyles();
    const classesR = useClassCommentDisplayRStyles();
    const classes = locale === 'he'? classesR : classesL;

    const authorImageStyle = locale === 'he'? authorImageStyleR : authorImageStyleL;

    const componentDecorator = (href, text, key) => (
        <a href={href} key={key} target='_blank' rel='noopener noreferrer' style={linkText}>
          {text}
        </a>
    );

    const time = moment(classComment.value.createdTime).calendar();

    return (
        <div className={classes.classComment}>

            <div>
                <Author author={author} authorImageStyle={authorImageStyle} authorNameStyle={authorNoName} />    
            </div>
            
            <div className={classes.content}>

                <div className={classes.authorDate}>
                    <Author author={author} authorImageStyle={authorNoimage} authorNameStyle={authorNameStyle} />
                    <Typography className={classes.date}>{time}</Typography>
                </div>

                <div className={classes.body}>

                    <div dir='ltr'>
                        <Attachment attId={attId[0]} attImageStyle={attImageStyle} download={true} withLitebox={true} />
                    </div>
                                        
                    <Linkify componentDecorator={componentDecorator}>
                        <Typography dir='auto' className={classes.text}>{classComment.value.text}</Typography>
                    </Linkify>

                </div>

            </div>
            
        </div>
    );
}

export default ClassCommentDispaly;
