import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CustomModal from '../../components/common/custom-modal/custom-modal.component';
import Logout from '../../components/logout/logout.component';

import { selectVisible } from '../../redux/logout/logout.selector';
import { selectLocale } from '../../redux/localization/localization.selector';
import { closeLogoutPage } from '../../redux/logout/logout.actions';


const LogoutPage = ({ locale, visible, closeLogoutPage }) => {

    // console.log('Logout Page Props', locale, visible);

    return(
        <div>
            <CustomModal open={visible} onClose={closeLogoutPage} content={<Logout locale={locale} />} />
        </div>
    )
    
};

const mapStateToProps = createStructuredSelector({
    visible: selectVisible,
    locale: selectLocale,
});

const mapDispatchToProps = dispatch => ({
    closeLogoutPage: () => dispatch(closeLogoutPage())
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
