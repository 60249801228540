import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

export const StyledBadgeRead = withStyles({
    root: {
        width: '100%'
    },
    badge: {
        // right: '24px',
        right: '1.25vw',
        // top: '24px',
        top: '2.2222222222222223vh',
        backgroundColor: '#FFE708',
        border: `2px solid #26C6DA`,
        padding: '0 4px',
        minWidth: '1.0416666666666665vw',
        // height: '1.8518518518518519vh',
        // width: '1.0416666666666665vw',
        height: '1.0416666666666665vw',
    },
})(Badge);

export const StyledBadgeUnread = withStyles({
    root: {
        width: '100%'
    },
    badge: {
        // right: '24px',
        right: '1.25vw',
        // top: '24px',
        top: '2.2222222222222223vh',
        backgroundColor: '#FF2400', //'#FFE708',
        //   border: `2px solid #26C6DA`,
        padding: '0 4px',
        // minWidth: 'unset',
        minWidth: '1.0416666666666665vw',
        height: '1.0416666666666665vw',
        // width: '1.0416666666666665vw',
    },
})(Badge);