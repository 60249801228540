import React from 'react';

import Input from '@material-ui/core/Input';

import { useNewInlineEditStyles } from './new-inline-edit.styles';


const NewInlineEdit = ({ value, multiline, autoFocus, onChangeText, strings, locale }) => {

    const classes = useNewInlineEditStyles();

    const direction = locale === 'he'? 'rtl' : 'ltr';

    return (

        <Input
            multiline={multiline}
            className={classes.input}
            fullWidth
            disableUnderline
            placeholder={strings.inputPlaceholder}
            inputProps={{
                'aria-label': 'description',
                maxLength: 1024,
                dir: {direction},
            }}
            onChange={onChangeText}
            value={value}
            autoFocus={autoFocus}
        />

    );

}

export default NewInlineEdit;
