import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';

import pattern from '../../assets/pattern.svg';

const url = `url('${pattern}')`

export const usePostDetailsStyles = makeStyles({

    select: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // width: '-webkit-fill-available',
        width: '100%',
    },

    messages: {
        ...FONT,
        fontSize: '1.6666666666666667vw',
        marginTop: '2.2222222222222223vh',
    },

    circularholder:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    classTitle: {
        ...FONT,
        fontSize: '1.6666666666666667vw',
    },

    addQuestionImage: {
        marginTop: '1.1111111111111112vh',
        marginBottom: '1.1111111111111112vh',
    },

    postDetails: {
        // display: 'flex', 
        // flexDirection: 'column', 
        // width: '-webkit-fill-available',
        width: '100%',
        // width: 'inherit',
        // height: 'calc(100vh - 60px)',
        // height: '100%',
        // height: 'inherit',
        // justifyContent: 'space-between',
    },

    postDetailsHeader: {
        display: 'flex', 
        flexDirection: 'column',
        // height: '140px',
        height: '12.962962962962964vh',
        backgroundColor: '#FCFCFC',
        boxShadow: '0px 3px 6px #E3E3E329',
        opacity: 1,

        // backgroundColor: 'red',
    },

    postDetailsComments: {
        // display: 'flex',
        // flexDirection: 'column',
        // height: 'calc(100vh - 200px)',
        // height: '100%',
        height: 'calc(100% - 12.962962962962964vh)',

        // background: url,
        // backgroundColor: '#f1f5f9',
    },

    holder: {
        height: '100%',
        // width: 'inherit',
        // height: 'inherit',
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'space-between',
    },

    subHolder: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },

    comments: {
        // height: '100%',
        background: url,
        backgroundColor: '#f1f5f9',
        // display: 'flex',
        // width: 'inherit',
        height: 'inherit',
        overflowY: 'auto',
    },

});