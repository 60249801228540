import { newIdKey, updateDomain } from '../firebase/firebase.utils';
import { Attachment } from '../models/domain/attachment';
import { attachmentType } from '../utils/string.utils';
import { No } from '../models/models-core';


export const createCommentAttachment = (

    async (firebase, data, commentId) => {

        if (!data || !data.contentType || !data.size || !data.url || !data.folder || !data.author || !data.post) {
            console.warn('createCommentAttachment()', 'no valid creation data', data);
            return void 0;
        }

        // const attaId = data.folder
        const attaId = newIdKey(firebase);
        const post = data.post;
        // const comment = commentId || newIdKey(firebase);
        const comment = data.folder ||  commentId || newIdKey(firebase);

        // const now = firebase.database.ServerValue.TIMESTAMP;

        let images = {};

        (attachmentType(data.contentType) === 'IMAGE') ?
            images = { originalUrl: data.url, thumbnailUrl: data.url } :
            images = No.Images

        const attachment = Attachment.fromNew({

            idKey: attaId,

            author: data.author,
            ownerId: comment,
            post: post,
            comment: comment,
            createdTime: firebase.database.ServerValue.TIMESTAMP,
            fileType: attachmentType(data.contentType),
            fileName: data.metadata.name,
            images: images,
            lastUpdatedUTime: firebase.database.ServerValue.TIMESTAMP,
            size: data.size,
            url: data.url,

        })


        await updateDomain(firebase, Attachment, attachment);

        return attachment;

    }

);
