import React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import CommentForDelete from './comment-for-delete-display.component';

import { idsOf } from '../../utils/object.utils';

import { DELETE_COMMENT } from '../../localized-strings/delete-comment';

import { onDeleteComment } from './on-delete-comment.handler'; 
import { closeDeleteCommentPage } from '../../redux/delete-comment/delete-comment.actions';

import { useDeleteCommentStyles } from './delete-comment.styles';

const DeleteComment = ({ comment, closeDeleteCommentPage, locale }) => {

    // console.log('Delete Comment', comment);

    const classes = useDeleteCommentStyles();

    const firebase = useFirebase();

    const strings = DELETE_COMMENT[locale];

    const popUpdir = locale === 'he'? 'rtl' : 'ltr';

    const attId = idsOf(comment.value.attachments);
    const author = comment.value.author;

    const onDelete = () => {
        // console.log('Delete', comment);
        onDeleteComment(firebase, comment.key);
        closeDeleteCommentPage();
    };

    
    return (
        
        <div dir={popUpdir} className={classes.deleteComment}>
            
            <Typography dir='auto' className={classes.title}>{strings.title}</Typography>

            <Divider className={classes.divider} />

            <div className={classes.deleteCommentHolder}>

                <Typography className={classes.deleteMessage}>
                    {strings.deleteMessage}
                </Typography>

                <div className={classes.commentHolder}>
                    <CommentForDelete comment={comment.value || null} attId={attId} author={author} locale={locale} />
                </div>


                <div className={classes.buttons}>
                    <div>
                        <Button className={classes.cancelButton} onClick={closeDeleteCommentPage}>
                            {strings.cancelButton}
                        </Button>
                    </div>

                    <div>
                        <Button className={classes.deleteButton} onClick={onDelete}>
                            {strings.deleteButton}
                        </Button>
                    </div>
                </div>

            </div>

        </div>
    )

};


const mapDispatchToProps = dispatch => ({
    closeDeleteCommentPage: () => dispatch(closeDeleteCommentPage())
});

export default connect(null, mapDispatchToProps)(DeleteComment);

