import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';


export const usePostStyles = makeStyles({

    post: {
        display: 'flex', 
        alignItems: 'center', 
        // height: '104px',
        // width: '404px',
        // width: '21.041666666666668vw',
        width: 'inherit',
    },

    postText: {
        display: 'flex',
        flexDirection: 'column',
    },

    postBody: {
        ...FONT,
        // fontSize: '20px',
        fontSize: '1.0416666666666665vw',
        // maxWidth: '252px'
        maxWidth: '13.125vw'
    },

    postComments: {
        ...FONT,
        // fontSize: '14px',
        fontSize: '0.7291666666666666vw',
    },

    postCommentsUnread: {
        ...FONT,
        color: '#FFFFFF',
        // fontSize: '14px',
        fontSize: '0.7291666666666666vw',
    },

    view: {
        display: 'flex',
    },

    anonymous: {
        ...FONT,
        color: '#A9AAAB',
        // fontSize: '18px',
        fontSize: '0.9375vw',
        // marginLeft: '8px',
        marginLeft: '0.4166666666666667vw',
    },

    brackets: {
        ...FONT,
        color: '#A9AAAB',
        // fontSize: '18px',
        fontSize: '0.9375vw',
    },
    
});

export const postImageStyles = {
    // width: '64px',
    width: '3.3333333333333335vw',
    // height: '64px',
    height: '3.3333333333333335vw',
    objectFit: 'cover', 
    // margin: '16px',
    margin: '0.8333333333333334vw',
    // borderRadius: '12px',
    borderRadius: '0.625vw',
    opacity: 1,
};

export const authorImageStyle = {
    display: 'none',
};

export const authorNameStyle = {
    ...FONT,
    // fontSize: '18px',
    fontSize: '0.9375vw',
    // maxWidth: '252px',
    maxWidth: '13.125vw',
};

export const anonymousNameStyle = {
    ...FONT,
    // fontSize: '18px',
    fontSize: '0.9375vw',
    // maxWidth: '252px',
    maxWidth: '13.125vw',
    color: '#A9AAAB',
};
