import { DeleteCommentActionTypes } from './delete-comment.types';

const INITIAL_STATE = {
    visible: false,
    onComment: null
};

const deleteCommentReducer = (state = INITIAL_STATE, action) => {

    if(action.type === DeleteCommentActionTypes.TOGGLE_DELETE_COMMENT_PAGE_HIDDEN ){
        return {
            ...state,
            visible: true,
            onComment: action.payload,
        }
    };

    if(action.type === DeleteCommentActionTypes.OPEN_DELETE_COMMENT_PAGE){
        return {
            ...state,
            visible: true,
            onComment: action.payload,
        }
    }

    if(action.type === DeleteCommentActionTypes.CLOSE_DELETE_COMMENT_PAGE){
        return {
            ...state,
            visible: false,
            // onComment: null
        }
    };

    return state;
};

export default deleteCommentReducer;