import { makeStyles } from '@material-ui/core/styles';

export const useJoinPageStyles = makeStyles({
    joinPage:{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
    }
});
