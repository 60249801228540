import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import DefaultClassComment from './default-class-comment';
import ClassCommentDispaly from './class-comment-display.component';

import { idsOf } from '../../utils/object.utils';

import { CLASS_CHAT } from '../../localized-strings/class-chat';

import { useClassChatStyles } from './class-chat.styles';

const ClassChat = ({classComments, classId, locale}) => {

    // console.log('Class Chat Page', classComments, classId, locale);

    const classes = useClassChatStyles();

    const endRef = useRef();

    const strings = CLASS_CHAT[locale];

    useEffect(() => {
        if(classComments && endRef){
            // console.log(endRef);
            endRef.current.scrollIntoView({ behavior: "auto" });
        }
    }, [classComments, endRef]);

    if(classId === ':classId') {
        return (
            <div className={classes.select}>
                <Typography dir='auto' className={classes.info}>{strings.selectMessage}</Typography>
            </div>
        )
    };

    if (!isLoaded(classComments)) {
        return (
            <div className={classes.loading}>
                <CircularProgress className={classes.circular} />
            </div>
        );
    }

    // if (isEmpty(classComments)) {
    //     return (
    //         <div className={classes.select}>
    //             <Typography dir='auto' className={classes.info}>{strings.noMessage}</Typography>
    //         </div>
    //     )
    // }

    // console.log('Class Comments', classComments);


    return(
        <div className={classes.classChat}>
            <DefaultClassComment locale={locale} />
            {
                classComments && classComments.map(classComment => {
                    const attId = idsOf(classComment.value.attachments);
                    return (
                        <ClassCommentDispaly 
                            key={classComment.key}
                            classComment={classComment}
                            attId={attId}
                            author={classComment.value.author}
                            locale={locale}
                        />
                    )   
                })
            }
            <div ref={endRef} />
        </div>
    )
    
};

const mapStateToProps = (state, ownProps) => {

    // console.log('Class Chat', state);
    
    const classId = ownProps.classId;
    const classesComments = state.firebase.ordered.classChat;
    const classComments = classesComments? classesComments[classId] : null;
    const locale = state.localization && state.localization.locale;
    
    return {
        classComments: classComments,
        locale,
    };
    
};

export default compose(
    connect(mapStateToProps),
    firebaseConnect(ownProps => [
        `classChat/${ownProps.classId}`
    ]),       
)(ClassChat);
