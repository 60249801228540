export const CLASS_NAV_TABS = {

    en:{
        questions: 'Sessions',
        classChat: 'Wall',
        classmates: 'Members',
    },

    he: {
        questions: 'מפגשים',
        classChat: 'דיונים',
        classmates: 'משתתפים',
    },

};
