import { COMMON } from './common';

export const EDIT_COMMENT = {

    en: {
        title: 'Edit comment',
        addFileButton: COMMON['en'].addFileButton,
        selectFile: COMMON['en'].selectFile,
        inputPlaceholder: COMMON['en'].addMessage,
        cancelButton: COMMON['en'].cancel,
        saveButton: COMMON['en'].save,
    },

    he: {
        title: 'ערוך תגובה',
        addFileButton: COMMON['he'].addFileButton,
        selectFile: COMMON['he'].selectFile,
        inputPlaceholder: COMMON['he'].addMessage,
        cancelButton: COMMON['he'].cancel,
        saveButton: COMMON['he'].save,
    },

};
