import { makeStyles } from '@material-ui/core/styles';
import { FONT } from '../../utils/font.utils';

export const newClassCommentInputDisplayStyles = makeStyles({

    newCommentInput: {
        width: '100%',
        display: 'flex',
        paddingRight: '0.625vw',
        paddingLeft: '0.625vw',
        paddingTop: '2.5925925925925926vh',
        paddingBottom: '3.3333333333333335vh',
    },

    newCommentInputDisplay: {
        display: 'flex',
        justifyContent: 'center',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '0.5px solid #011E23',
        borderRadius: '0.625vw',
        opacity: '1',
        width: '100%',
    },

    input: {
        ...FONT,
        fontSize: '0.8854166666666666vw',
        lineHeight: '2.5vh',
        padding: 'unset',
    },

});
