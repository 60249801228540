import { updateFields } from '../../firebase/firebase.utils'; 
import { Comment } from '../../models/domain/comment';

export const onUpdateCommentText = async(firebase, data, commentId) => {


    console.log('onUpdateCommentText() > ()', data, commentId);

    try {

        if(!commentId){
            console.warn('No comment info! Aborting updating ...');
            return void 0;
        }

        const now = firebase.database.ServerValue.TIMESTAMP;
        const path = `${commentId}`;
        const dataForText = !data ? null : data

        const upData = {
            text: dataForText,
            lastUpdatedUTime: now,
            isEdited: now,
        }

        await updateFields(firebase, Comment.path(path), upData);

    } catch (e) {

        console.error('onUpdateCommentText() > catch{}', e);

    }

};
