import { makeStyles } from '@material-ui/core/styles';

const font = {
    fontFamily: 'Varela Round, sans-serif, Regular',
    color: '#011E23',
    letterSpacing: 0,
    opacity: 1,
};


export const useNewClassCommentAttachDispalyStyles = makeStyles({

    newClassCommmentAttachDisplay: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    title: {
        ...font,
        fontSize: '24px',
        lineHeight: '29px',
        marginBottom: '24px',
        marginTop: '24px',
    },

    divider: {
        width: '100%',
    },

    inputsHolder: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '552px',
        paddingLeft: '44px',
        paddingRight: '44px',
        paddingBottom: '64px',
    },

    fileHolder: {
        width: '-webkit-fill-available',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '114px',
        background: '#FCFCFC 0% 0% no-repeat padding-box',
        border: '1px solid #011E23',
        borderRadius: '12px',
        opacity: 1,
        marginTop: '64px',
        padding: '20px',
    },

    fileContent: {
        display: 'flex',
        position: 'relative',
    },

    iconButtonHolder: {
        position: 'absolute',
        top: '-10px',
        right: '-10px',
    },

    iconButton: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '0.5px solid #011E23',
        opacity: 1,
        '&:hover': {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
        }
    },

    addFile: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },

    addButton: {
        ...font,
        fontSize: '12px',
        color: '#011E23',
        opacity: 1,
        borderRadius: '12px',
        textTransform: 'unset',
        paddingBottom: '4px',
        paddingTop: '4px',
        marginTop: '8px',
        marginBottom: '8px',
    },

    inputHolder: {
        display: 'flex',
        alignItems: 'center',
        width: '552px',
        height: '196px',
        background: '#FCFCFC 0% 0% no-repeat padding-box',
        border: '1px solid #011E23',
        borderRadius: '12px',
        opacity: 1,
        marginBottom: '64px',
        overflow: 'auto',
    },

    input: {
        ...font,
        fontSize: '17px',
        lineHeight: '21px',
        height: 'inherit',
        alignItems: 'flex-start',
        marginLeft: '20px',
        marginRight: '20px',
    },

    buttons: {
        display: 'flex',
        alignSelf: 'flex-end',
    },

    cancelButton: {
        ...font,
        fontSize: '16px',
        color: '#011E23',
        opacity: 1,
        borderRadius: '12px',
        width: '132px',
        textTransform: 'unset',
    },

    uploadButton: {
        ...font,
        fontSize: '16px',
        color: '#FFFFFF',
        background: '#26C6DA 0% 0% no-repeat padding-box',
        borderRadius: '12px',
        opacity: 1,
        textTransform: 'unset',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#26C6DA',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        width: '132px'
    },
    
});