import { makeStyles } from '@material-ui/core/styles';

export const useNewShowImageStyles = makeStyles({

    fileContent: {
        display: 'flex',
        position: 'relative',
    },

    iconButtonHolder: {
        position: 'absolute',
        top: '-10px',
        right: '-10px',
    },

    iconButton: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '0.5px solid #011E23',
        opacity: 1,
        '&:hover': {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
        }
    },

    text: {
        fontFamily: 'Varela Round, sans-serif, Regular',
        color: '#011E23',
        fontSize: '17px',
        letterSpacing: 0,
        opacity: 0.45,
    },

});