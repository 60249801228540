import { newIdKey } from '../firebase/firebase.utils';
import { object } from '../utils/object.utils';
import { No } from '../models/models-core';
import { attachmentType } from '../utils/string.utils';



export const uploadCommentFile = async (firebase, file) => {

    // console.log('File', file);

    if (!file) {

        console.warn('uploadCommentFile', 'no valid file', file);

        return {
            contentType: No.ContentType,
            size: No.Number,
            url: No.Url,
        };
    }

    const ref = 'commentAttachments/file';
    const folder = newIdKey(firebase);
    let uresult = {}; 

    (attachmentType(file.type) === 'IMAGE') ?
        uresult = await firebase.uploadFile(`commentAttachments/${folder}/origin`, file) :
        // uresult = await firebase.uploadFile(`commentAttachments/${fileFolder}/${folder}`, file);
        uresult = await firebase.uploadFile(`${ref}/${folder}`, file);

    
    const snapshot = object(uresult.uploadTaskSnapshot);
    const metadata = object(snapshot.metadata);

    console.log('uploadCommentFile', { snapshot, metadata, uresult, folder }, '...');


    return {
        contentType: metadata.contentType,
        size: metadata.size,
        url: snapshot.ref && await snapshot.ref.getDownloadURL(),
        snapshot,
        metadata,
        uresult,
        folder
    };

}
